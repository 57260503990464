import React, { useState, useEffect } from "react";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Genders } from "../../common/utilities/choices";
import Select from "react-select";
import { reactSelectDropdownStyle } from "../../common/styles/dropdowns";
import { getNationalities } from "../../common/redux/actions/NationalitiesActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import {
  updateProfile,
  getProfile,
} from "../../common/redux/actions/ProfileActions";
import StepIndicator from "../Progress/StepIndicator";
import { toast } from "react-toastify";
import CountryCodesSelect from "../selects/CountryCodesSelect";
import axios from "axios";
import { countryCodeModifier } from "../../common/utilities/utilities";
import { validateInput } from "../../common/utilities/validation";

const RequiredFieldSteppersModal = (props, context) => {
  const dispatch = useDispatch();
  const totalSteps = 3;
  const [step, setStep] = useState(0);
  const [show, setShow] = useState(false);
  const countries = useSelector((state) => state.CountriesState);
  const nationalities = useSelector((state) => state.NationalitiesState);
  const [modalHeaderText, setModalHeaderText] = useState("");
  const [modalBodyText, setModalBodyText] = useState("");
  const [expiryTime, setExpiryTime] = useState(null);
  const [receivedOTP, setReceivedOTP] = useState(false);
  const [errorConfirmedOTP, setErrorConfirmedOTP] = useState(false);
  const profile = useSelector((state) => state.ProfileState.data);

  // const checkVerifiedPhone = (profile) => {
  //   if (
  //     !profile.phone_verified &&
  //     !profile.country_code &&
  //     !profile.phone_number &&
  //     profile.type !== "IS"
  //   ) {
  //     // new user
  //     setShow(true);
  //     setStep(0);
  //     setModalHeaderText(context.t("Add & Verify Phone Number!"));
  //     setModalBodyText(
  //       context.t(
  //         "To use the new version of Golden Dreams 3.0, please Add your phone number now. This will help you to verify and receive an OTP code to reset the password you used for this account in the future"
  //       )
  //     );
  //     return;
  //   } else if (!profile.gender && profile.type !== "IS") {
  //     setShow(true);
  //     setStep(1);
  //     return;
  //   } else if (
  //     profile.gender &&
  //     !profile.nationality &&
  //     profile.type !== "IS"
  //   ) {
  //     setShow(true);
  //     setStep(2);
  //     return;
  //   }
  // };

  // Disabled due to OTP issue
  // const checkVerifiedPhone = (profile) => {
  //   if (
  //     !profile.phone_verified &&
  //     profile.country_code !== "977" &&
  //     profile.country_code !== "+977" &&
  //     profile.phone_number &&
  //     profile.type !== "IS"
  //   ) {
  //     // if the user did not verify his/her phone
  //     setShow(true);
  //     setStep(0);
  //     setModalHeaderText(context.t("VERIFICATION"));
  //     setModalBodyText(
  //       context.t(
  //         "The new version of Golden Dreams 3.0 is coming up on 6.6.2024, in order to log in into your account please verify your phone number now. This will help you receive a SMS with OTP code for resetting the password you used for this account."
  //       )
  //     );
  //     return;
  //   } else if (
  //     !profile.phone_verified &&
  //     !profile.country_code &&
  //     profile.phone_number &&
  //     profile.type !== "IS"
  //   ) {
  //     // if the country code does not exist update your phone
  //     setShow(true);
  //     setStep(0);
  //     setModalHeaderText(context.t("Update & Verify Phone Number!"));
  //     setModalBodyText(
  //       context.t(
  //         "In order to log into the new version of Golden Dreams 3.0 on 6.6.2024, please CHECK your phone number by selecting the correct country code and entering the correct number now. This will help you to verify and receive an OTP code for resetting the password you used for this account."
  //       )
  //     );
  //     return;
  //   } else if (
  //     !profile.phone_verified &&
  //     profile.country_code !== "977" &&
  //     profile.country_code !== "+977" &&
  //     !profile.phone_number &&
  //     profile.type !== "IS"
  //   ) {
  //     // if the country code does not exist update your phone
  //     setShow(true);
  //     setStep(0);
  //     setModalHeaderText(context.t("Update & Verify Phone Number!"));
  //     setModalBodyText(
  //       context.t(
  //         "In order to log into the new version of Golden Dreams 3.0 on 6.6.2024, please CHECK your phone number by selecting the correct country code and entering the correct number now. This will help you to verify and receive an OTP code for resetting the password you used for this account."
  //       )
  //     );
  //     return;
  //   } else if (
  //     !profile.phone_verified &&
  //     !profile.country_code &&
  //     !profile.phone_number &&
  //     profile.type !== "IS"
  //   ) {
  //     setShow(true);
  //     setStep(0);
  //     setModalHeaderText(context.t("Add & Verify Phone Number!"));
  //     setModalBodyText(
  //       context.t(
  //         "In order to log into the new version of Golden Dreams 3.0 on 6.6.2024, please add your phone number now. This will help you to verify and receive an OTP code for resetting the password you used for this account."
  //       )
  //     );
  //     return;
  //   } else if (
  //     profile.phone_verified &&
  //     profile.country_code !== "977" &&
  //     profile.country_code !== "+977" &&
  //     profile.phone_number &&
  //     !profile.gender &&
  //     profile.nationality &&
  //     profile.country &&
  //     profile.type !== "IS"
  //   ) {
  //     setShow(true);
  //     setStep(1);
  //     return;
  //   } else if (
  //     profile.phone_verified &&
  //     profile.country_code &&
  //     profile.phone_number &&
  //     !profile.nationality &&
  //     profile.gender &&
  //     profile.gender !== "A" &&
  //     profile.country &&
  //     profile.type !== "IS"
  //   ) {
  //     setShow(true);
  //     setStep(2);
  //   }
  // };

  useEffect(() => {
    let interval;
    if (expiryTime !== null) {
      // Start the countdown
      interval = setInterval(() => {
        if (expiryTime !== 0 && expiryTime >= 0) {
          setExpiryTime((prevExpiryTime) => prevExpiryTime - 1);
        }
      }, 1000); // Update every second
    }

    // Cleanup interval when component unmounts or when expiryTime is set to null
    return () => clearInterval(interval);
  }, [expiryTime]);

  const [payload, setPayload] = useState({
    nationality: "",
    country: "",
    gender: "",
    // phone_number: "",
    // country_code: "",
    // phone_verified: "",
    // otp: "",
    // otpVerified: false,// add back when otp feature is ready
    type: "JS",
  });

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return context.t("{minutes} minute(s) and {seconds} second(s)", {
      minutes: minutes,
      seconds: seconds,
    });
  };

  const genders = Genders({}, context, true);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [error, setError] = useState({
    nationality: false,
    country: false,
    gender: false,
    // phone_number: false,
    // country_code: false,// add back when otp feature is ready
  });

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
    !nationalities.fetched && dispatch(getNationalities());
  }, []);

  const handleResendOTP = (e) => {
    e.preventDefault();
    setReceivedOTP(false);
    setErrorConfirmedOTP(false);
    setStep(0);
    setPayload({
      ...payload,
      otp: "",
    });
  };

  const sendOTPRequest = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_V2_URL}/send-otp/`,
        data,
      );

      if (response.status === 200) {
        setReceivedOTP(true);
        // setStep(2);
        setExpiryTime(120); // Set expiry time to 2 minutes (120 seconds)
        // Handle successful OTP request (e.g., display success message)
        let formData = new FormData();
        formData.append("country_code", payload.country_code);
        formData.append("phone_number", payload.phone_number);
        formData.append("country_code", payload.country_code);

        dispatch(updateProfile("JS", profile.id, formData));
      } else if (response.status === 409) {
        toast.error(
          context.t(
            "This user has already been verified with the provided phone number.",
          ),
        );
      } else {
        toast.error(context.t("Error sending OTP!"));
      }
    } catch (error) {
      toast.error(
        context.t(
          "Verification Failed! The number you entered has already been verified with another user account. Check the number again.",
        ),
      );
    }
  };

  const handleVerifyOTPNow = async (payload) => {
    const pattern = /^\d*$/;
    if (!pattern.test(payload.otp)) {
      setErrorConfirmedOTP(true);
      toast.error(context.t("Please enter valid OTP!"));
      return;
    }

    let formData = new FormData();
    formData.append("otp", payload.otp);
    formData.append("country_code", countryCodeModifier(payload.country_code));
    formData.append("phone_number", payload.phone_number);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_V2_URL}/verify-otp/`,
        formData,
      );

      if (response.status === 200) {
        setPayload({
          ...payload,
          otpVerified: true,
        });
        dispatch(getProfile("JS", profile.id));
        toast.success(
          context.t("Your phone number is successfully verified !"),
        );
      } else {
        setErrorConfirmedOTP(true);
        toast.error(context.t("OTP confirmation failed !"));
      }
    } catch (error) {
      setErrorConfirmedOTP(true);
      toast.error(context.t("OTP confirmation failed !"));
    }
  };

  const handleGetOTP = async (payload) => {
    try {
      if (payload.phone_number == "" || payload.country_code == "") {
        toast.error(context.t("Please complete the form !"));
        return;
      } else {
        let formData = new FormData();
        formData.append(
          "country_code",
          countryCodeModifier(payload.country_code),
        );
        formData.append("phone_number", payload.phone_number);
        await dispatch(updateProfile("JS", profile.id, formData));
        toast.success(
          context.t("Your phone number is successfully verified !"),
        );
        setStep(1);
        // sendOTPRequest(formData);
      }
    } catch (error) {
      toast.error(
        context.t(
          "Verification Failed! The number you entered has already been verified with another user account. Check the number again.",
        ),
      );
    }
  };

  // useEffect(() => {
  //   if (payload.otpVerified) {
  //     if (
  //       profile.gender == "" ||
  //       !profile.gender ||
  //       (profile.gender == "A" && profile.type !== "IS")
  //     ) {
  //       setShow(true);
  //       setStep(1);
  //     } else if (!profile.nationality && profile.type !== "IS") {
  //       setShow(true);
  //       setStep(2);
  //     } else if (!profile.country && profile.type !== "IS") {
  //       setShow(true);
  //       setStep(3);
  //     } else if (
  //       profile.gender == "" ||
  //       !profile.gender ||
  //       (profile.gender == "A" &&
  //         !profile.country &&
  //         profile.nationality &&
  //         profile.type !== "IS")
  //     ) {
  //       setShow(true);
  //       setStep(1);
  //     } else if (
  //       profile.gender == "" ||
  //       !profile.gender ||
  //       (profile.gender == "A" &&
  //         !profile.nationality &&
  //         profile.country &&
  //         profile.type !== "IS")
  //     ) {
  //       setShow(true);
  //       setStep(1);
  //     } else if (
  //       profile.gender == "" ||
  //       !profile.gender ||
  //       (profile.gender == "A" &&
  //         !profile.country &&
  //         !profile.nationality &&
  //         profile.type !== "IS")
  //     ) {
  //       setShow(true);
  //       setStep(1);
  //     } else {
  //       setShow(false);
  //     }
  //   }
  // }, [payload.otpVerified]); // add back when otp feature is ready

  // useEffect(() => {
  //   // logged out users
  //   if (profile && Object.keys(profile).length > 0) {
  //     // logged in users
  //     checkVerifiedPhone(profile);
  //   } else {
  //     setShow(false);
  //   }
  // }, [profile]);// add back when otp feature is ready

  useEffect(() => {
    if (profile && Object.keys(profile).length > 0 && profile.type !== "IS") {
      if (profile.gender == "" || !profile.gender || profile.gender == "A") {
        setShow(true);
        setStep(1);
      } else if (!profile.nationality && profile.type !== "IS") {
        setShow(true);
        setStep(2);
      } else if (!profile.country && profile.type !== "IS") {
        setShow(true);
        setStep(3);
      } else if (
        profile.gender == "" ||
        !profile.gender ||
        (profile.gender == "A" &&
          !profile.country &&
          profile.nationality &&
          profile.type !== "IS")
      ) {
        setShow(true);
        setStep(1);
      } else if (
        profile.gender == "" ||
        !profile.gender ||
        (profile.gender == "A" &&
          !profile.nationality &&
          profile.country &&
          profile.type !== "IS")
      ) {
        setShow(true);
        setStep(1);
      } else if (
        profile.gender == "" ||
        !profile.gender ||
        (profile.gender == "A" &&
          !profile.country &&
          !profile.nationality &&
          profile.type !== "IS")
      ) {
        setShow(true);
        setStep(1);
      } else {
        setShow(false);
      }
    }
  }, [profile]); // remove this block of hook codes when otp feature is ready

  useEffect(() => {
    const nationalityOptions = Object.keys(nationalities.items)
      .sort((a, b) =>
        nationalities.items[a].name > nationalities.items[b].name ? 1 : -1,
      )
      .map((key) => {
        return {
          value: nationalities.items[key].nationality,
          label: nationalities.items[key].name,
        };
      });
    setNationalityOptions(nationalityOptions);
  }, [nationalities]);

  useEffect(() => {
    const countryOptions = Object.keys(countries.items)
      .sort((a, b) =>
        countries.items[a].name > countries.items[b].name ? 1 : -1,
      )
      .map((key) => {
        return {
          value: countries.items[key].country,
          label: countries.items[key].name,
        };
      });
    setCountryOptions(countryOptions);
  }, [countries]);

  useEffect(() => {
    switch (step) {
      case 1:
        setModalHeaderText(context.t("Please Complete These Steps!"));
        setModalBodyText(context.t("Gender"));
        break;
      case 2:
        setModalHeaderText(context.t("Please Complete These Steps!"));
        setModalBodyText(context.t("Nationality"));
        break;
      case 3:
        setModalHeaderText(context.t("Please Complete These Steps!"));
        setModalBodyText(context.t("Country"));
        break;
    }
  }, [step]);

  const handleNext = () => {
    if ((step == 1 && payload.gender == "") || payload.gender == "A") {
      setError({
        ...error,
        gender: true,
      });
      return;
    }

    if (step == 2 && payload.nationality == "") {
      setError({
        ...error,
        nationality: true,
      });
      return;
    }

    if (step == 3 && payload.country == "") {
      setError({
        ...error,
        country: true,
      });
      return;
    }

    if (step < 4) {
      setStep(step + 1);
    }

    dispatch(
      updateProfile("JS", profile.id, {
        gender: payload.gender,
        nationality: payload.nationality,
        country: payload.country,
      }),
    );
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = () => {
    if (
      !payload.country ||
      // !payload.phone_number || // add back when otp feature is ready
      // !payload.country_code ||
      !payload.nationality ||
      !payload.gender
    ) {
      toast.error(context.t("Please complete the form !"));
      return;
    } else {
      if (profile.phone_verified) {
        dispatch(
          updateProfile("JS", profile.id, {
            gender: payload.gender,
            nationality: payload.nationality,
            country: payload.country,
          }),
        );
      } else {
        dispatch(updateProfile("JS", profile.id, payload));
      }
      setStep(1);
      handleClose();
      toast.success(context.t("Congratulations your profile is updated!"));
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSelectChange = (data, event) => {
    const name = event.name;
    const value = data.value;
    if (data.value && data.value != "") {
      setError({
        ...error,
        [name]: false,
      });
    }
    setPayload({ ...payload, [name]: value });
  };

  useEffect(() => {
    if (profile) {
      setPayload({
        ...payload,
        nationality: profile.nationality || "",
        country: profile.country || "",
        gender: profile.gender || "",
        // phone_number: profile.phone_number || "", // add back when otp feature is ready
        // country_code: profile.country_code || "",
        // phone_verified: profile.phone_verified || false,
        // otpVerified: profile.phone_verified || false,
        type: profile.type || "JS",
      });
    }
  }, []);

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="justify-content-center" closeButton={false}>
        <Modal.Title>{modalHeaderText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="attention">{modalBodyText}</p>

        {step == 0 && (
          <React.Fragment>
            {!receivedOTP && (
              <Form>
                <Form.Group>
                  <Form.Label style={{ fontSize: "smaller" }}>
                    *{context.t("Phone number")} ({context.t("required")})
                  </Form.Label>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                      <CountryCodesSelect
                        placeholder={context.t("Select")}
                        initialValue={payload.country_code}
                        onChange={(country_code) => {
                          setPayload({
                            ...payload,
                            phone_number: "",
                            country_code: country_code,
                          });
                        }}
                      />
                      {/* <Form.Text muted style={{
                          marginBottom: !payload.country_code && "block",
                        }}>
                        {context.t("Select the country code")}
                      </Form.Text> */}
                      <Form.Control.Feedback
                        type="invalid"
                        style={{
                          display: !payload.country_code && "block",
                          marginBottom: "25px",
                        }}
                      >
                        {context.t("Please select a country code")}
                      </Form.Control.Feedback>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            {context.t(
                              "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736.",
                            )}
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          id="phone_number"
                          type="tel"
                          value={payload.phone_number}
                          className="button-modified"
                          onChange={(event) => {
                            let phone_number = event.target.value;
                            let validate_key = "phone_number";
                            let validation = validateInput(
                              validate_key,
                              phone_number,
                              payload.country_code,
                            );
                            if (validation) {
                              return validation;
                            }
                            setPayload({
                              ...payload,
                              phone_number: event.target.value,
                            });
                          }}
                          placeholder={context.t("Enter your phone number")}
                          style={{ height: 30 }}
                          required
                        />
                      </OverlayTrigger>
                      <Form.Text muted>
                        {context.t(
                          "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again.",
                        )}
                      </Form.Text>
                      <Form.Control.Feedback
                        type="invalid"
                        style={{ display: !payload.phone_number && "block" }}
                      >
                        {context.t("Please enter valid phone number")}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            )}
            {receivedOTP && (
              <Form>
                <Form.Group controlId="formOTP">
                  <Form.Label>{context.t("Enter OTP:")}</Form.Label>

                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        {context.t("Enter 6 digits OTP")}
                      </Tooltip>
                    }
                  >
                    <Form.Control
                      type="number"
                      className="button-modified"
                      placeholder={context.t("Enter OTP")}
                      value={payload.otp}
                      onChange={(e) =>
                        setPayload({ ...payload, otp: e.target.value })
                      }
                    />
                  </OverlayTrigger>
                </Form.Group>
                {expiryTime !== null && (
                  <p>
                    {context.t("Your OTP will expire in {expiryTime}", {
                      expiryTime: formatTime(expiryTime),
                    })}
                  </p>
                )}
              </Form>
            )}
          </React.Fragment>
        )}

        {step !== 0 && <StepIndicator steps={totalSteps} currentStep={step} />}

        {step === 1 && (
          <Form>
            <Form.Group controlId="gender">
              {error.gender ? (
                <Form.Label style={{ color: "brown" }}>
                  {" "}
                  {context.t("Please select gender")}
                </Form.Label>
              ) : (
                <Form.Label> {context.t("Gender")}</Form.Label>
              )}
              <Select
                name="gender"
                options={genders}
                value={genders.filter((o) => o.value === payload.gender)[0]}
                placeholder={context.t("Gender")}
                onChange={handleSelectChange}
                styles={reactSelectDropdownStyle}
                isSearchable={false}
              />
            </Form.Group>
          </Form>
        )}
        {step === 2 && (
          <Form>
            <Form.Group controlId="nationality">
              {error.nationality ? (
                <Form.Label style={{ color: "brown" }}>
                  {" "}
                  {context.t("Please select nationality")}
                </Form.Label>
              ) : (
                <Form.Label> {context.t("Nationality")}</Form.Label>
              )}
              <Select
                name="nationality"
                options={nationalityOptions}
                value={
                  nationalityOptions.filter(
                    (o) => o.value === payload.nationality,
                  )[0]
                }
                placeholder={context.t("Nationality")}
                style={{ textAlign: "center" }}
                onChange={handleSelectChange}
                styles={reactSelectDropdownStyle}
                isSearchable={false}
              />
            </Form.Group>
          </Form>
        )}
        {step === 3 && (
          <Form>
            <Form.Group controlId="country">
              {error.country ? (
                <Form.Label style={{ color: "brown" }}>
                  {" "}
                  {context.t("Please select Country of residence")}
                </Form.Label>
              ) : (
                <Form.Label> {context.t("Country of residence")} </Form.Label>
              )}
              <Select
                name="country"
                options={countryOptions}
                value={
                  countryOptions.filter((o) => o.value === payload.country)[0]
                }
                placeholder={context.t("Country")}
                style={{ textAlign: "center" }}
                onChange={handleSelectChange}
                styles={reactSelectDropdownStyle}
                isSearchable={false}
              />
            </Form.Group>
          </Form>
        )}
        {step === 4 && (
          <Form>
            <Form.Group>
              <Form.Label style={{ fontSize: "smaller" }}>
                *{context.t("Phone number")} ({context.t("required")})
              </Form.Label>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <CountryCodesSelect
                    placeholder={context.t("Select")}
                    initialValue={payload.country_code}
                    onChange={(country_code) =>
                      setPayload({ ...payload, country_code: country_code })
                    }
                  />
                  <Form.Text muted>
                    {context.t("Select the country code")}
                  </Form.Text>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: !payload.country_code && "block" }}
                  >
                    {context.t("Please select a country code")}
                  </Form.Control.Feedback>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {context.t(
                          "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736.",
                        )}
                      </Tooltip>
                    }
                  >
                    <Form.Control
                      type="number"
                      value={payload.phone_number}
                      className="button-modified"
                      onChange={(event) =>
                        setPayload({
                          ...payload,
                          phone_number: event.target.value,
                        })
                      }
                      placeholder={context.t("Enter your phone number")}
                      style={{ height: 30 }}
                      required
                    />
                  </OverlayTrigger>
                  <Form.Text muted>
                    {context.t(
                      "Enter the phone number you always use. Do not include the country code, such as (+95, +66, +977, +855, +60), here again.",
                    )}
                  </Form.Text>
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: !payload.phone_number && "block" }}
                  >
                    {context.t("Please enter valid phone number")}
                  </Form.Control.Feedback>
                </div>
              </div>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {step == 0 && !receivedOTP && (
          <Button
            variant="primary"
            onClick={handleGetOTP.bind(this, payload)}
            width="100%"
            className="button-modified full-width sweet-green-bg"
          >
            <strong>
              {!profile.phone_verified &&
              profile.country_code &&
              profile.phone_number
                ? context.t("Get OTP")
                : !profile.phone_verified &&
                    !profile.country_code &&
                    profile.phone_number
                  ? context.t("Update & Get OTP")
                  : !profile.phone_verified &&
                      !profile.country_code &&
                      !profile.phone_number
                    ? // ? context.t("Add & Get OTP")
                      context.t("Add Phone Number")
                    : context.t("Get OTP")}
            </strong>
          </Button>
        )}

        {step == 0 && receivedOTP && (
          <Button
            variant="primary"
            onClick={handleVerifyOTPNow.bind(this, payload)}
            width="100%"
            className="button-modified full-width sweet-green-bg"
          >
            <strong>{context.t("Confirm OTP")}</strong>
          </Button>
        )}
        {step == 0 && errorConfirmedOTP && (
          <Button
            variant="warning"
            onClick={handleResendOTP.bind(this)}
            width="100%"
            className="button-modified full-width"
          >
            <i class="fas fa-redo"></i> &nbsp;
            <strong>{context.t("Resend OTP")}</strong>
          </Button>
        )}
        {step !== 0 && (
          <React.Fragment>
            {step !== 1 && (
              <Button
                variant="secondary"
                className="button-modified"
                onClick={handlePrevious.bind(this)}
              >
                {context.t("Previous")}
              </Button>
            )}
            {step !== 3 ? (
              <Button
                variant="primary"
                className="button-modified"
                loading={profile.updating}
                onClick={handleNext}
              >
                {context.t("Next")}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="button-modified"
                loading={profile.updating}
                onClick={handleSubmit}
              >
                {context.t("Submit")}
              </Button>
            )}
          </React.Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default RequiredFieldSteppersModal;

RequiredFieldSteppersModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
