import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { FaAward, FaUserTie, FaIndustry } from "react-icons/fa";

import moment from "moment";
import { Link } from "react-router-dom";
import { Row, Col, Image, Badge, Alert } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import { JobApplicationStatuses } from "../common/utilities/choices";
import {
  addDefaultImageSrc,
  getJobStatusClassName,
} from "../common/utilities/utilities";
import { setScrollToIndex } from "../common/redux/actions/FiltersSortersActions";

export default function JobListItem(props, context) {
  const dispatch = useDispatch();

  const cities = useSelector((state) => state.CitiesState);
  const countries = useSelector((state) => state.CountriesState);
  const currencies = useSelector((state) => state.CurrenciesState);
  const industries = useSelector((state) => state.IndustriesState);
  const jobApplicationStatuses = JobApplicationStatuses({}, context, false);
  const subindustries = useSelector((state) => state.SubIndustriesState);
  const states = useSelector((state) => state.StatesState);
  const profile = useSelector((state) => state.ProfileState.data);

  const [applicationStatus, setApplicationStatus] = useState(null);

  useEffect(() => {
    let application =
      profile.applications &&
      profile.applications.filter(
        (application) => application.job == props.job.id,
      )[0];
    application &&
      profile.id == application.jobseeker &&
      setApplicationStatus(application.status);
  }, [profile]);

  const unread = () => {
    if (profile.notifications) {
      let notifications = profile.notifications.filter(
        (notification) =>
          notification.unread &&
          notification.type == "NEW_JOB_POSTED" &&
          notification.action_object_id == props.job.translation.id,
      ).length;

      return notifications > 0;
    }
  };

  const jobStatusRenderer = (job) => {
    if (job.is_closed) {
      return (
        <Row style={{ textAlign: "center", color: "brown" }}>
          <Col xs={12}>
            <p>
              <i className="fas fa-exclamation-circle fa-md"></i>{" "}
              {context.t("Recruitment closed!")}
            </p>
          </Col>
        </Row>
      );
    }

    if (
      (!job.is_closed && moment().isAfter(props.job.expired_at)) ||
      moment().isAfter(props.job.closed_at)
    ) {
      return (
        <Row style={{ textAlign: "center", color: "brown" }}>
          <Col xs={12}>
            <p>
              <i className="fas fa-exclamation-circle fa-md"></i>{" "}
              {context.t("Recruitment Expired!")}
            </p>
          </Col>
        </Row>
      );
    }
  };

  return (
    <Link
      to={
        props.fromApplication
          ? `/jobs/${props.job.id}?prev=/job-applications`
          : `/jobs/${props.job.id}`
      }
      onClick={() =>
        dispatch(
          setScrollToIndex(
            props.fromApplication ? "applications" : "jobs",
            props.scrollToIndex,
          ),
        )
      }
    >
      <ListGroup.Item
        className={`Card mt-3 ${getJobStatusClassName(props.job)}`}
        style={{
          minHeight: 110,
          boxShadow: "3px 3px 5px rgba(234, 224, 200, 0.7)",
          borderRadius: "12px",
          transition: "box-shadow 0.3s, transform 0.3s",
        }}
      >
        {jobStatusRenderer(props.job)}
        <Row>
          <Col xs={8}>
            {props.job.recruitment_agency == null ? (
              <p style={{ marginBottom: 0 }}> {props.job.translation.title} </p>
            ) : (
              <p style={{ marginBottom: 0 }}> {props.job.job_title} </p>
            )}
            <p style={{ marginBottom: 0 }}>
              {" "}
              {props.job.employer_workers_name || props.job.employer_name}{" "}
            </p>
            <p style={{ marginBottom: 0 }}>
              {props.job.employer_industry &&
                subindustries.items[props.job.employer_industry] &&
                `${industries.items[subindustries.items[props.job.employer_industry].industry_id].name}:
                  ${subindustries.items[props.job.employer_industry].name}
                 `}
            </p>
            {props.job.employer_country &&
              props.job.employer_state &&
              props.job.employer_city && (
                <p style={{ marginBottom: 0 }}>
                  {" "}
                  {countries.items[props.job.employer_country] &&
                    countries.items[props.job.employer_country].name}
                  ,
                  {states.items[props.job.employer_state] &&
                    states.items[props.job.employer_state].name}
                  ,
                  {cities.items[props.job.employer_city] &&
                    cities.items[props.job.employer_city].name}{" "}
                </p>
              )}
            <p style={{ marginBottom: 0 }}>
              {props.job.terms_of_payment == "Monthly"
                ? context.t("{salary} {currency}/month", {
                    salary: props.job.salary,
                    currency:
                      currencies.items[props.job.currency] &&
                      currencies.items[props.job.currency].code,
                  })
                : context.t("{daily_wage_rate} {currency}/day", {
                    daily_wage_rate: props.job.daily_wage_rate,
                    currency:
                      currencies.items[props.job.currency] &&
                      currencies.items[props.job.currency].code,
                  })}

              {props.job.have_employer_pays_policy && (
                <span
                  style={{
                    display: "block",
                    paddingRight: 5,
                    paddingTop: 5,
                    color: "darkblue",
                  }}
                >
                  <FaAward />
                  &nbsp;{context.t("Have Employer Pays Policy")}
                </span>
              )}
              {props.job.recruitment_agency ? (
                <span
                  style={{
                    display: "block",
                    paddingRight: 5,
                    paddingTop: 5,
                    color: "#198474",
                  }}
                >
                  <FaUserTie />
                  &nbsp;
                  {context.t(
                    "Only job seekers in Myanmar/Cambodia/Nepal can apply",
                  )}
                </span>
              ) : (
                <span
                  style={{
                    display: "block",
                    paddingRight: 5,
                    paddingTop: 5,
                    color: "#198474",
                  }}
                >
                  <FaIndustry />
                  &nbsp;{context.t("Only job seekers in Thailand can apply")}
                </span>
              )}
            </p>
          </Col>

          <Col xs={4} style={{ textAlign: "right" }}>
            {unread() && (
              <Badge className="newContentBadge">{context.t("new")}</Badge>
            )}
            <Image
              src={
                props.job.business_logo
                  ? props.job.business_logo
                  : "/static/media/ImagePlaceholder.png"
              }
              crossOrigin="anonymous"
              style={{
                width: 90,
                height: 90,
                boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
                objectFit: "cover",
              }}
              onError={addDefaultImageSrc}
              roundedCircle
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={12}>
            {applicationStatus && (
              <Alert
                className="hover-effect"
                style={{ textAlign: "center" }}
                variant={
                  applicationStatus == "VIEWED"
                    ? "info"
                    : ["ACCEPTED", "OFFERED"].includes(applicationStatus)
                      ? "success"
                      : [
                            "REJECTED_BY_APPLICANT",
                            "REJECTED_BY_BUSINESS",
                            "DOCUMENTS_REQUESTED",
                          ].includes(applicationStatus)
                        ? "danger"
                        : "primary"
                }
              >
                <strong>{jobApplicationStatuses[applicationStatus]}</strong>
              </Alert>
            )}
          </Col>
        </Row>
      </ListGroup.Item>
    </Link>
  );
}

JobListItem.contextTypes = {
  t: PropTypes.func.isRequired,
};
