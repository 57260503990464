import axios from "axios";

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const NEWS_GET_START = "NEWS_GET_START";
export const NEWS_GET_SUCCESS = "NEWS_GET_SUCCESS";
export const NEWS_GET_FAIL = "NEWS_GET_FAIL";

export const NEWS_ADD_COMMENT = "NEWS_ADD_COMMENT";
export const NEWS_ADD_CHILD_COMMENT = "NEWS_ADD_CHILD_COMMENT";
export const NEWS_DELETE_COMMENT = "NEWS_DELETE_COMMENT";

export const ADD_NEWS_FROM_NOTIFICATION = "ADD_NEWS_FROM_NOTIFICATION";
// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getNewsStart = () => {
  return {
    type: NEWS_GET_START,
  };
};

export const getNewsSuccess = (news) => {
  return {
    type: NEWS_GET_SUCCESS,
    news: news,
  };
};

export const getNewsFail = (error) => {
  return {
    type: NEWS_GET_FAIL,
    error: error,
  };
};

export const getNews = () => {
  return (dispatch, getState) => {
    dispatch(getNewsStart());
    const language = getState().i18nState.lang;

    axios
      .get(`${process.env.REACT_APP_API_URL}/news?lang=${language}`)
      .then((res) => {
        let news = res.data;

        // add unicode names to be used in the search later
        if (language === "mm-z") {
          news = news.map((item) => {
            return { ...item, title_mm_uni: Rabbit.zg2uni(item.title) };
          });
        }

        dispatch(getNewsSuccess(news));
        return getState().NewsState.items;
      })
      .catch((err) => {
        dispatch(getNewsFail(err));
        throw err;
      });
  };
};

// ADD:
export const addNewsFromNotification = (news) => {
  return {
    type: ADD_NEWS_FROM_NOTIFICATION,
    news: news,
  };
};

export const addNewsComment = (comment) => {
  return {
    type: NEWS_ADD_COMMENT,
    comment: comment,
  };
};

export const addNewsChildComment = (comment) => {
  return {
    type: NEWS_ADD_CHILD_COMMENT,
    comment: comment,
  };
};

// DELETE:
export const deleteNewsComment = (news_id, comment_id) => {
  console.log(`RECEIVED NEWS ID: ${news_id}, NEWS ID: ${comment_id}`);
  return {
    type: NEWS_DELETE_COMMENT,
    news_id: news_id,
    comment_id: comment_id,
  };
};
