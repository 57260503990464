import * as firebase from "firebase/app";
import "firebase/messaging";
import axios from "axios";

let messaging;
let apiV2Url = process.env.REACT_APP_API_V2_URL;

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_PUSH_CERTIFICATE);

  // Handle token refresh automatically
  messaging.onTokenRefresh(async () => {
    try {
      const refreshedToken = await messaging.getToken();
      console.log("FCM Token Refreshed:", refreshedToken);

      // Send the new token to the backend using Axios
      await axios.post(`${apiV2Url}/update-firebase-token`, {
        token: refreshedToken,
      });
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  });
}

/**
 * Function to request notification permission and retrieve token
 */
export const requestFCMToken = async () => {
  try {
    if (!messaging) {
      console.warn("FCM is not supported on this browser.");
      return null;
    }

    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await messaging.getToken();
      console.log("FCM Token:", token);
      return token;
    } else {
      console.warn("Notification permission denied.");
      return null;
    }
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return null;
  }
};

/**
 * Function to delete token when user logs out
 */
export const removeFCMToken = async () => {
  try {
    if (!messaging) return;
    await messaging.deleteToken();
    console.log("FCM Token deleted");
    const authData = JSON.parse(localStorage.getItem("auth"));
    const token = authData?.token;
    await axios.post(
      `${apiV2Url}/remove-firebase-token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.error("Error deleting FCM token:", error);
  }
};

export const updateFCMToken = async (token) => {
  try {
    const response = await axios.post(`${apiV2Url}/update-firebase-token`, {
      token,
    });

    console.log("FCM Token updated successfully:", response.data);

    // No need to dispatch any action
  } catch (error) {
    console.error(
      "Error updating FCM token:",
      error.response?.data || error.message
    );
  }
};

export { messaging };
