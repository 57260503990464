import axios from "axios";

import {
  verifiyNewRecruitmentAgency,
  rejectNewRecruitmentAgency,
  mergeNewRecruitmentAgency,
} from "./RecruitmentAgenciesActions";

import {
  verifiyNewEmployer,
  rejectNewEmployer,
  mergeNewEmployer,
} from "./EmployersActions";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const VERIFY_BUSINESS_START = "VERIFY_BUSINESS_START";
export const VERIFY_BUSINESS_SUCCESS = "VERIFY_BUSINESS_SUCCESS";
export const VERIFY_BUSINESS_FAIL = "VERIFY_BUSINESS_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

export const verifyBusinessStart = (id) => {
  return {
    type: VERIFY_BUSINESS_START,
    id: id,
  };
};

export const verifyBusinessSuccess = () => {
  return {
    type: VERIFY_BUSINESS_SUCCESS,
  };
};

export const verifyBusinessFail = (error) => {
  return {
    type: VERIFY_BUSINESS_FAIL,
    error: error,
  };
};

export const verifyBusiness = (id) => {
  return (dispatch) => {
    dispatch(verifyBusinessStart(id));
    return new Promise((resolve, reject) => {
      return axios
        .post(`${process.env.REACT_APP_API_URL}/users/new-business-verify`, {
          id: id,
        })
        .then((res) => {
          dispatch(verifyBusinessSuccess());
          if (res.type === "RA") {
            dispatch(verifiyNewRecruitmentAgency(id));
          } else if (res.type === "EM") {
            dispatch(verifiyNewEmployer(id));
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch(verifyBusinessFail(err));
          reject(err);
        });
    });
  };
};

export const rejectBusiness = (id) => {
  return (dispatch) => {
    dispatch(verifyBusinessStart(id));
    return new Promise((resolve, reject) => {
      return axios
        .post(`${process.env.REACT_APP_API_URL}/users/new-business-reject`, {
          id: id,
        })
        .then((res) => {
          dispatch(verifyBusinessSuccess());
          if (res.type === "RA") {
            dispatch(rejectNewRecruitmentAgency(id));
          } else if (res.type === "EM") {
            dispatch(rejectNewEmployer(id));
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch(verifyBusinessFail(err));
          reject(err);
        });
    });
  };
};

export const mergeBusiness = (primaryId, secondaryId) => {
  return (dispatch) => {
    dispatch(verifyBusinessStart(secondaryId));
    return new Promise((resolve, reject) => {
      return axios
        .post(`${process.env.REACT_APP_API_URL}/users/new-business-merge`, {
          primaryId: primaryId,
          secondaryId: secondaryId,
        })
        .then((res) => {
          dispatch(verifyBusinessSuccess(primaryId, secondaryId));
          if (res.type === "RA") {
            dispatch(mergeNewRecruitmentAgency(primaryId, secondaryId));
          } else if (res.data.type === "EM") {
            dispatch(mergeNewEmployer(primaryId, secondaryId));
          }
          resolve(res);
        })
        .catch((err) => {
          dispatch(verifyBusinessFail(err));
          reject(err);
        });
    });
  };
};
