import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import BaseView from "../BaseView";
import NotificationsListItemV2 from "../../components/NotificationsListItemV2";
import { getProfile } from "../../common/redux/actions/ProfileActions";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";
import InfiniteScroll from "react-infinite-scroller";

export default function NotificationsView(props, context) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileState.data);
  const profileState = useSelector((state) => state.ProfileState);
  const userId = useSelector((state) => state.AuthenticationState.id);

  const [notifications, setNotifications] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!profileState.fetched && !profileState.loading && userId) {
      dispatch(getProfile("JS", userId));
    }
  }, [dispatch, userId, profileState.fetched, profileState.loading]);

  const fetchNotifications = useCallback(
    async (page) => {
      if (loading || !hasMore) return;

      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/notifications/?page=${page}`,
        );
        const { results, next } = response.data;

        if (results) {
          setNotifications((prevNotifications) => [
            ...prevNotifications,
            ...results,
          ]);
        }
        setHasMore(!!next);
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, hasMore],
  );

  useEffect(() => {
    if (profile.id) {
      fetchNotifications(pageNumber);
    }
  }, [profile, pageNumber]);

  const loadMoreNotifications = () => {
    if (!loading && hasMore) {
      fetchNotifications(pageNumber + 1);
      setPageNumber((prevPage) => prevPage + 1);
    }
  };

  return (
    <BaseView title={context.t("Notifications")} backurl="/">
      <hr className="afterTopNavigationBarWithoutFilters"></hr>
      {profileState.loading && notifications.length === 0 ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt="Loading"
            className="LoadingSpinner"
          />
        </div>
      ) : notifications.length > 0 ? (
        <InfiniteScroll
          loadMore={loadMoreNotifications}
          hasMore={hasMore && !loading}
          loader={
            <div className="loader" key={0}>
              {context.t("Loading ...")}
            </div>
          }
        >
          {notifications.map((notification) => (
            <NotificationsListItemV2
              key={notification.id}
              notification={notification}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <FilterResultsNotFound text={context.t("No notifications")} />
      )}
    </BaseView>
  );
}

NotificationsView.contextTypes = {
  t: PropTypes.func.isRequired,
};
