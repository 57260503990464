import { updateObject } from "../Utility";
import {
  RATING_CRITERIES_GET_START,
  RATING_CRITERIES_GET_SUCCESS,
  RATING_CRITERIES_GET_FAIL,
} from "../actions/RatingCriteriesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getRatingCriteriesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getRatingCriteriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.ratingCriteries.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getRatingCriteriesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const RatingCriteriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case RATING_CRITERIES_GET_START:
      return getRatingCriteriesStart(state, action);
    case RATING_CRITERIES_GET_SUCCESS:
      return getRatingCriteriesSuccess(state, action);
    case RATING_CRITERIES_GET_FAIL:
      return getRatingCriteriesFail(state, action);

    default:
      return state;
  }
};

export default RatingCriteriesReducer;
