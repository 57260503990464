import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";

import { addDefaultImageSrc } from "../common/utilities/utilities";

const ServiceProvidersListItem = (props, context) => {
  console.log(props.lang === "en");
  return (
    <Link to={`/services/${props.id}`}>
      <div className="Card mt-3" style={{ textAlign: "center" }}>
        <div style={{ position: "relative" }}>
          <Image
            src={props.image ? props.image : "/static/media/service.png"}
            crossOrigin="anonymous"
            style={{
              width: "100%",
              height: "120px",
              objectFit: "contain",
              border: "solid 1px grey",
            }}
            onError={addDefaultImageSrc}
          />
          <div className="comments-counter">
            <i className="far fa-comment-alt"></i>
            <span> {props.comments_count} </span>
          </div>
        </div>
        <Rater total={5} rating={props.rating} interactive={false} />
        <span>{props.rating && props.rating.toFixed(1)}</span>
        <span
          style={{ position: "relative", bottom: "7px", fontSize: "0.7em" }}
        >
          {" "}
          ({props.rating_count})
        </span>
        <h3 className="ListItemName"> {props.name} </h3>
        {props.lang == "ne" || props.lang == "en" ? null : (
          <h3 className="ListItemName"> {props.name_en} </h3>
        )}
        <div>
          <p style={{ marginBottom: 0 }} className="mt-1">
            {" "}
            {props.countries}{" "}
          </p>
          <div style={{ fontWeight: 600 }}>
            {props.categories.length > 2 ? (
              <div>
                <p style={{ marginBottom: 0 }}>
                  {" "}
                  {props.categories.slice(0, 2).join(", ")}{" "}
                </p>
                <p>
                  {" "}
                  {`+ ${props.categories.length - 2} ${context.t("more")}`}{" "}
                </p>
              </div>
            ) : (
              <p> {props.categories.join(", ")} </p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

ServiceProvidersListItem.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
  };
};

export default connect(mapStateToProps)(ServiceProvidersListItem);
