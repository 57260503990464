import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import {
  Form,
  Modal,
  OverlayTrigger,
  Tooltip,
  Image,
  Container,
} from "react-bootstrap";
import axios from "axios";
import "./PostEditorModal.css";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";

export default function PostEditorModal(props, context) {
  const post_id = props.post_id;
  const languageId = props.languageId;
  const [post, setPost] = useState({});
  const lang = useSelector((state) => state.i18nState.lang);
  const [showModal, setShowModal] = useState(false);
  const articleTextareaRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const postListSetter = props.postListSetter;

  const handleImageChange = (event) => {
    event.stopPropagation();
    const file = event.target.files[0];
    setSelectedImage(URL.createObjectURL(file));
  };

  const onEditorInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setPost((prevPost) => ({
      ...prevPost,
      [name]: value,
    }));
  };

  const handleUpdateClick = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append("article", post.article);
    formData.append("caption", post.caption);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/?lang=${lang}`,
        formData,
      )
      .then((response) => {
        if (props.type == "list") {
          postListSetter(response.data);
        } else if (props.type == "detail") {
          props.updatedStatus(true);
        }
        toast.success(context.t("Successfully updated the post !"));
      })
      .catch((error) => {
        toast.error(error);
        console.error("Error:", error);
      });
    setShowModal(false);
  };

  const handleModalClose = (e) => {
    e.preventDefault();
    setPost("");
    setShowModal(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="my-tooltip" {...props}>
      {context.t("Edit post")}
    </Tooltip>
  );

  const fetchPostDetail = async (post_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}?lang=${lang}`,
      )
      .then(function (response) {
        setPost(response.data);
      })
      .then(() => {
        setShowModal(true);
      })
      .catch(function (error) {
        props.history.push("/not-found");
      });
  };

  useEffect(() => {
    // Calculate the number of lines in the article and resize the textarea
    if (showModal == true) {
      const lineHeight = parseInt(
        window.getComputedStyle(articleTextareaRef.current).lineHeight,
        10,
      );
      const lines = Math.floor(
        articleTextareaRef.current.scrollHeight / lineHeight,
      );
      articleTextareaRef.current.rows = lines;
    }
  });

  return (
    <>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <Button
          className="btn-block"
          size="sm"
          variant="outline-primary"
          onClick={(e) => {
            e.preventDefault();
            fetchPostDetail(post_id);
          }}
        >
          <AiOutlineEdit />
          &nbsp; {context.t("Edit Post ")}
        </Button>
      </OverlayTrigger>
      <div onKeyDown={(e) => e.stopPropagation()}>
        <Modal
          onClick={(e) => e.stopPropagation()}
          centered
          size="lg"
          show={showModal}
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>{context.t("Edit Post")}&nbsp;</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="article">
                <Form.Label>{context.t("Article:")}</Form.Label>
                <Form.Control
                  ref={articleTextareaRef}
                  placeholder={context.t("Please write an article ...")}
                  name="article"
                  as="textarea"
                  value={post.article}
                  onChange={onEditorInputChange.bind(this)}
                  onClick={(e) => e.preventDefault()}
                />
              </Form.Group>
              {selectedImage && (
                <Form.Group>
                  <Image src={selectedImage} alt="Preview" thumbnail />
                </Form.Group>
              )}
            </Form>
            {post.file && (
              <Container fluid className="post-image">
                <Image
                  style={{ maxHeight: "30vh" }}
                  alt={post.caption}
                  src={post.file}
                  fluid
                />
              </Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose.bind(this)}>
              {context.t("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={handleUpdateClick.bind(this)}
              disabled={post.article != "" ? false : true}
            >
              {context.t("Update")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

PostEditorModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
