export const websiteRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
export const facebookRegex =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?facebook.com(\/.*)?$/gim;

export const phoneRegex =
  /^\+((?:9[679]|8[035789]\d|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:(-|\s)*\d){0,13}\d$/;
export const workerPhoneRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const viberRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const lineRegex = /^\@?\w*[a-zA-Z]+[a-zA-Z0-9_.-]*$/;

export const dayRegex = /^(0?[1-9]|[12]\d|3[01])$/;
export const monthRegex = /^(0?[1-9]|1[0-2])$/;

export const passportYearRegex = /^20([2-3]\d|40)$/;
export const certificateOfIdentityYearRegex = /^20([2-3]\d|40)$/;
export const birthYearRegex = /^19[4-9]\d{1}|200[0-5]$/;
