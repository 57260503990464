import { omit } from "lodash";
import { updateObject } from "../Utility";
import {
  GROUPS_TOPICS_GET_START,
  GROUPS_TOPICS_GET_SUCCESS,
  GROUPS_TOPICS_GET_FAIL,
  GROUPS_QUESTION_POST_START,
  GROUPS_QUESTION_POST_SUCCESS,
  GROUPS_QUESTION_POST_FAIL,
  GROUPS_QUESTION_ADD_COMMENT,
  GROUPS_QUESTION_ADD_CHILD_COMMENT,
  GROUPS_QUESTION_DELETE_COMMENT,
  GROUPS_QUESTION_VERIFICATION_SUCCESS,
  GROUPS_QUESTION_REJECTION_SUCCESS,
} from "../actions/GroupsActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getGroupsTopicsStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
};

const getGroupsTopicsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.topics.reduce((obj, topic) => {
      obj[topic.id] = topic;
      obj[topic.id].questions = topic.questions.reduce((obj, question) => {
        obj[question.id] = question;
        obj[question.id].comments = question.comments.reduce((obj, comment) => {
          obj[comment.id] = comment;
          return obj;
        }, {});
        return obj;
      }, {});
      return obj;
    }, {}),
    loading: false,
    fetched: true,
  });
};

const getGroupsTopicsFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
};

// POST:
const postGroupsQuestionSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.question.topic_id]: {
        ...state.items[action.question.topic_id],
        questions_count:
          state.items[action.question.topic_id].questions_count + 1,
        questions: {
          ...state.items[action.question.topic_id].questions,
          [action.question.id]: action.question,
        },
      },
    },
  });
};

// ADD:
const addTopicQuestionsComment = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.comment.topic_id]: {
        ...state.items[action.comment.topic_id],
        comments_count: state.items[action.comment.topic_id].comments_count + 1,
        questions: {
          ...state.items[action.comment.topic_id].questions,
          [action.comment.object_id]: {
            ...state.items[action.comment.topic_id].questions[
              action.comment.object_id
            ],
            comments_count:
              state.items[action.comment.topic_id].questions[
                action.comment.object_id
              ].comments_count + 1,
            comments: {
              ...state.items[action.comment.topic_id].questions[
                action.comment.object_id
              ].comments,
              [action.comment.id]: action.comment,
            },
          },
        },
      },
    },
  });
};

const addTopicQuestionsChildComment = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.comment.topic_id]: {
        ...state.items[action.comment.topic_id],
        comments_count: state.items[action.comment.topic_id].comments_count + 1,
        questions: {
          ...state.items[action.comment.topic_id].questions,
          [action.comment.object_id]: {
            ...state.items[action.comment.topic_id].questions[
              action.comment.object_id
            ],
            comments_count:
              state.items[action.comment.topic_id].questions[
                action.comment.object_id
              ].comments_count + 1,
            comments: {
              ...state.items[action.comment.topic_id].questions[
                action.comment.object_id
              ].comments,
              [action.comment.base_parent]: {
                ...state.items[action.comment.topic_id].questions[
                  action.comment.object_id
                ].comments[action.comment.base_parent],
                children: [
                  ...state.items[action.comment.topic_id].questions[
                    action.comment.object_id
                  ].comments[action.comment.base_parent].children,
                  action.comment,
                ],
              },
            },
          },
        },
      },
    },
  });
};

// VERIFY QUESTION:
const verifyQuestionSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.question.topic_id]: {
        ...state.items[action.question.topic_id],
        questions_count:
          state.items[action.question.topic_id].questions_count + 1,
        questions: {
          ...state.items[action.question.topic_id].questions,
          [action.question.id]: {
            ...state.items[action.question.topic_id].questions[
              action.question.id
            ],
            ...action.question,
          },
        },
      },
    },
  });
};

// REJECT QUESTION:
const rejectQuestionSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.topic_id]: {
        ...state.items[action.topic_id],
        questions: omit(
          state.items[action.topic_id].questions,
          action.question_id,
        ),
      },
    },
  });
};

const GroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    // TOPICS GET
    case GROUPS_TOPICS_GET_START:
      return getGroupsTopicsStart(state, action);
    case GROUPS_TOPICS_GET_SUCCESS:
      return getGroupsTopicsSuccess(state, action);
    case GROUPS_TOPICS_GET_FAIL:
      return getGroupsTopicsFail(state, action);

    // QUESTIONS POST
    case GROUPS_QUESTION_POST_SUCCESS:
      return postGroupsQuestionSuccess(state, action);

    // QUESTIONS VERIFY
    case GROUPS_QUESTION_VERIFICATION_SUCCESS:
      return verifyQuestionSuccess(state, action);

    // QUESTIONS REJECT
    case GROUPS_QUESTION_REJECTION_SUCCESS:
      return rejectQuestionSuccess(state, action);

    // COMMENTS ADD
    case GROUPS_QUESTION_ADD_COMMENT:
      return addTopicQuestionsComment(state, action);
    case GROUPS_QUESTION_ADD_CHILD_COMMENT:
      return addTopicQuestionsChildComment(state, action);

    default:
      return state;
  }
};

export default GroupsReducer;
