import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";

const DeleteConfirmModal = (props, context) => {
  const postId = props.post_id;
  const postListRemover = props.postListRemover;
  const lang = useSelector((state) => state.i18nState.lang);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useHistory();

  const handleCloseModal = (e) => {
    e.preventDefault();
    setShowConfirmation(false);
  };

  const renderTooltip = (props) => (
    <Tooltip id="my-tooltip" {...props}>
      {context.t("Delete post")}
    </Tooltip>
  );

  const handleConfirmPostDelete = (post_id, e) => {
    e.preventDefault();
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/?lang=${lang}`,
      )
      .then((response) => {
        if (props.type == "list") {
          postListRemover(post_id);
        } else if (props.type == "detail") {
          history.push("/groups");
        }
      })
      .catch((error) => {
        console.error("Error deleting post:", error);
      });
    setShowConfirmation(false);
  };

  const handleConfirmModalClick = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const closeConfirmationModal = (e) => {
    e.preventDefault();
    setShowConfirmation(false);
  };

  return (
    <>
      <OverlayTrigger placement="top" overlay={renderTooltip}>
        <Button
          className="btn-block"
          size="sm"
          variant="outline-danger"
          onClick={handleConfirmModalClick.bind(this)}
        >
          <AiOutlineDelete />
          &nbsp; {context.t("Delete Post")}
        </Button>
      </OverlayTrigger>
      <Modal
        dialogClassName="modal-position"
        centered
        show={showConfirmation}
        onHide={closeConfirmationModal.bind(this)}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>{context.t("Delete your post?")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {context.t("Are you sure you want to delete this post?")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal.bind(this)}>
            {context.t("Cancel")}
          </Button>
          <Button
            variant="danger"
            onClick={handleConfirmPostDelete.bind(this, postId)}
          >
            {context.t("Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteConfirmModal;

DeleteConfirmModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
