import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroller";
import Autosuggest from "react-autosuggest";

import BaseView from "../BaseView";
import ServiceProvidersListItem from "../../components/ServiceProvidersListItem";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getCities } from "../../common/redux/actions/CitiesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import { getServiceCategories } from "../../common/redux/actions/ServiceCategoriesActions";
import { getServiceProviders } from "../../common/redux/actions/ServiceProvidersActions";
import {
  setFilter,
  setSort,
  setSearch,
} from "../../common/redux/actions/FiltersSortersActions";

import {
  reactSelectDropdownStyle,
  reactSelectDropdownStyleLeft,
  reactSelectDropdownStyleRight,
} from "../../common/styles/dropdowns";

const fuzzysort = require("fuzzysort");
const Rabbit = require("rabbit-node");

export default function ServiceProvidersListView(props, context) {
  const dispatch = useDispatch();

  const cities = useSelector((state) => state.CitiesState);
  const states = useSelector((state) => state.StatesState);
  const countries = useSelector((state) => state.CountriesState);
  const language = useSelector((state) => state.i18nState.lang);

  const serviceCategories = useSelector(
    (state) => state.ServiceCategoriesState,
  );
  const serviceProviders = useSelector((state) => state.ServiceProvidersState);

  const profile = useSelector((state) => state.ProfileState);

  // Filters and sorters
  const filters = useSelector(
    (state) => state.FiltersSortersState.filters.serviceProviders,
  );
  const sort = useSelector(
    (state) => state.FiltersSortersState.sort.serviceProviders,
  );
  const search = useSelector(
    (state) => state.FiltersSortersState.search.serviceProviders,
  );

  // React select dropdown options
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);

  // state
  const [componentState, setComponentState] = useState({
    filteredServiceProviders: [],
    infiniteServiceProviders: [],
    currentPage: 1,
    delayedSearch: search,
  });
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [filteringResults, setFilteringResults] = useState(true);

  // references
  const sortRef = useRef();

  // call necessary API
  useEffect(() => {
    !cities.fetched && dispatch(getCities());
    !states.fetched && dispatch(getStates());
    !countries.fetched && dispatch(getCountries());
    !serviceCategories.fetched && dispatch(getServiceCategories());
    !serviceProviders.fetched && dispatch(getServiceProviders());
  }, []);

  // populate react select countries options
  useEffect(() => {
    if (countries.fetched && serviceProviders.fetched) {
      let availableCountries = new Set();
      serviceProviders.items.map((serviceProvider) => {
        for (let key in serviceProvider.addresses) {
          availableCountries.add(serviceProvider.addresses[key].country_id);
        }
      });

      let countriesArray = [];
      for (let key in countries.items) {
        if (
          [...availableCountries].indexOf(countries.items[key].country) >= 0
        ) {
          countriesArray.push(countries.items[key]);
        }
      }

      countriesArray = [
        { value: 0, label: context.t("All countries") },
        ...countriesArray
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((item) => {
            return { value: item.country, label: item.name };
          }),
      ];
      setCountriesOptions(countriesArray);
    }
  }, [countries.items, serviceProviders.items]);

  // populate react select categories options
  useEffect(() => {
    if (serviceCategories.fetched && serviceProviders.fetched) {
      let availableCategories = new Set();
      serviceProviders.items.map((serviceProvider) => {
        availableCategories = new Set([
          ...availableCategories,
          ...serviceProvider.service_categories,
        ]);
      });

      let categoriesArray = [];
      for (let key in serviceCategories.items) {
        if (
          [...availableCategories].indexOf(
            serviceCategories.items[key].service_category,
          ) >= 0
        ) {
          categoriesArray.push(serviceCategories.items[key]);
        }
      }

      categoriesArray = [
        { value: 0, label: context.t("All categories") },
        ...categoriesArray
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((item) => {
            return { value: item.service_category, label: item.name };
          }),
      ];
      setCategoriesOptions(categoriesArray);
    }
  }, [serviceCategories.items, serviceProviders.items]);

  useEffect(() => {
    if (countries.items[profile.data.country_id] && filters.useDefaultCountry) {
      if (
        countriesOptions.filter(
          (country) => country.value === profile.data.country_id,
        )[0]
      ) {
        dispatch(
          setFilter("serviceProviders", "country", profile.data.country_id),
        );
      }
    }
  }, [countries.items, profile, countriesOptions]);

  // filter and sort service providers
  useEffect(() => {
    if (serviceProviders.fetched) {
      filterAndSortServiceProviders(serviceProviders.items);
    }
  }, [serviceProviders.items, sort, filters, search]);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        dispatch(setSearch("serviceProviders", componentState.delayedSearch));
      }, 450),
    );
  }, [componentState.delayedSearch]);

  const handleSort = (data, event) => {
    const value = data.value;
    dispatch(setSort("serviceProviders", value));
  };

  const handleSearch = (event) => {
    setComponentState({
      ...componentState,
      delayedSearch: event.target.value,
    });
  };

  const handleFilter = (data, event) => {
    const name = event.name;
    const value = data.value;
    dispatch(setFilter("serviceProviders", name, value));

    if (filters.useDefaultCountry && name === "country") {
      dispatch(setFilter("serviceProviders", "useDefaultCountry", false));
    }
  };

  const filterBySearch = (serviceProviders, search) => {
    if (language === "mm-z") {
      search = Rabbit.zg2uni(search);
    }

    const results = fuzzysort.go(search, serviceProviders, {
      keys: ["name", "name_en", "name_mm_uni"],
      allowTypo: true,
      threshold: -30000, // don't return really bad results
    });
    return results.map((result) => result.obj);
  };

  const filterByCategory = (serviceProviders, category) => {
    return serviceProviders.filter((serviceProvider) => {
      return serviceProvider.service_categories.indexOf(category) >= 0;
    });
  };

  const filterByCountry = (serviceProviders, country) => {
    return serviceProviders.filter((serviceProvider) => {
      for (var address of serviceProvider.addresses) {
        if (address.country_id === country) {
          return true;
        }
      }
    });
  };

  const filterAndSortServiceProviders = (serviceProviders) => {
    setFilteringResults(true);

    var result = [...serviceProviders];

    // Filtering:
    if (search) {
      const searchString = search.toLowerCase();
      result = filterBySearch(result, searchString);
    }

    if (filters.category) {
      result = filterByCategory(result, filters.category);
    }

    if (filters.country) {
      result = filterByCountry(result, filters.country);
    }

    // Sorting:
    if (sort === "Rating: Highest") {
      result.sort((a, b) => {
        if (a.rating_score || b.rating_score) {
          if (b.rating_score - a.rating_score === 0) {
            return b.rating_count - a.rating_count;
          }
          return b.rating_score - a.rating_score;
        }
        return a.name > b.name ? 1 : -1;
      });
    } else if (sort === "Rating: Lowest") {
      result.sort((a, b) => {
        if (a.rating_score || b.rating_score) {
          return a.rating_score - b.rating_score;
        }
        return a.name > b.name ? 1 : -1;
      });
    } else if (sort === "Popularity") {
      result.sort((a, b) => {
        if (
          a.comments_count ||
          b.comments_count ||
          a.rating_count ||
          b.rating_count
        ) {
          return (
            b.comments_count +
            b.rating_count -
            (a.comments_count + a.rating_count)
          );
        }
        return a.name > b.name ? 1 : -1;
      });
    }

    if (
      JSON.stringify(componentState.filteredServiceProviders) !==
      JSON.stringify(result)
    ) {
      setComponentState({
        ...componentState,
        filteredServiceProviders: result,
        infiniteServiceProviders: result.slice(0, 10),
      });
    }

    setFilteringResults(false);
  };

  const loadMoreServiceProviders = () => {
    const page = componentState.currentPage + 1;
    setComponentState({
      ...componentState,
      currentPage: page,
      infiniteServiceProviders: componentState.filteredServiceProviders.slice(
        0,
        page * 10,
      ),
    });
  };

  const unfocusInput = (e, ref) => {
    e.preventDefault();
    ref.current.focus();
    ref.current.blur();
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.name;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    props.history.push(`/services/${suggestion.id}`);
  };

  return (
    <BaseView title={context.t("Service Providers")} backurl="/">
      <hr className="afterTopNavigationBar"></hr>
      {countries.loading ||
      serviceCategories.loading ||
      serviceProviders.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container>
          <Row>
            <Col style={{ textAlign: "left" }}>
              <Select
                name="category"
                placeholder={context.t("Category")}
                defaultValue={{
                  value: filters.category,
                  label:
                    filters.category === 0
                      ? context.t("All categories")
                      : serviceCategories.items[filters.category].name,
                }}
                options={categoriesOptions}
                onChange={handleFilter}
                isSearchable={false}
                styles={reactSelectDropdownStyleLeft}
                maxMenuHeight={500}
              />
            </Col>
            <Col style={{ textAlign: "left" }}>
              <Select
                name="country"
                placeholder={context.t("Country")}
                value={{
                  value: filters.country,
                  label:
                    filters.country === 0
                      ? context.t("All countries")
                      : countries.items[filters.country].name,
                }}
                options={countriesOptions}
                onChange={handleFilter}
                isSearchable={false}
                styles={reactSelectDropdownStyleRight}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <p className="found-counter">
              {" "}
              {context.t("{number} found", {
                number: componentState.filteredServiceProviders.length,
              })}{" "}
            </p>
            <Col xs={6}>
              {/*<form onSubmit={(e) => unfocusInput(e, sortRef)}>
                <input className='searchBar' placeholder={context.t('Search')} onChange={handleSearch} style={{marginLeft: 0}} defaultValue={search}/>
              </form>*/}
              <form onSubmit={(e) => unfocusInput(e, sortRef)}>
                <Autosuggest
                  suggestions={componentState.filteredServiceProviders.slice(
                    0,
                    5,
                  )}
                  onSuggestionSelected={onSuggestionSelected}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    placeholder: context.t("Search"),
                    value: componentState.delayedSearch,
                    onChange: handleSearch,
                  }}
                />
              </form>
            </Col>
            <Col xs={6} style={{ textAlign: "left" }}>
              <Select
                name="sort"
                ref={sortRef}
                placeholder={context.t("Sort")}
                defaultValue={{ value: sort, label: context.t(sort) }}
                options={[
                  {
                    value: "Rating: Highest",
                    label: context.t("Rating: Highest"),
                  },
                  {
                    value: "Rating: Lowest",
                    label: context.t("Rating: Lowest"),
                  },
                  { value: "Popularity", label: context.t("Popularity") },
                ]}
                onChange={handleSort}
                isSearchable={false}
                styles={reactSelectDropdownStyleRight}
              />
            </Col>
          </Row>
          <InfiniteScroll
            loadMore={loadMoreServiceProviders}
            hasMore={
              componentState.infiniteServiceProviders.length <
              componentState.filteredServiceProviders.length
            }
            loader={
              <div className="loader" key={0}>
                {" "}
                {context.t("Loading ...")}{" "}
              </div>
            }
          >
            <Row className="mt-3">
              {componentState.infiniteServiceProviders.map((item, i) => {
                var serviceProviderCountries = new Set();
                for (var address of item.addresses) {
                  serviceProviderCountries.add(
                    countries.items[address.country_id] &&
                      countries.items[address.country_id].name,
                  );
                }

                var serviceProviderCategories = new Set();
                for (var serviceCategory of item.service_categories) {
                  serviceCategories.items[serviceCategory] &&
                    serviceProviderCategories.add(
                      serviceCategories.items[serviceCategory].name,
                    );
                }

                return (
                  <Col xs={6} sm={4} md={3} lg={2} key={i}>
                    <ServiceProvidersListItem
                      id={item.id}
                      name={item.name}
                      name_en={item.name_en}
                      description={item.description}
                      image={item.image}
                      rating={item.rating_score}
                      rating_count={item.rating_count}
                      countries={[...serviceProviderCountries].join(", ")}
                      categories={[...serviceProviderCategories]}
                      comments_count={item.comments_count}
                    />
                  </Col>
                );
              })}
            </Row>
          </InfiniteScroll>
          {!filteringResults &&
            componentState.filteredServiceProviders.length === 0 && (
              <FilterResultsNotFound
                text={context.t(
                  "Sorry, no service providers matched filter parameters, please try again.",
                )}
              />
            )}
        </Container>
      )}
    </BaseView>
  );
}

ServiceProvidersListView.contextTypes = {
  t: PropTypes.func.isRequired,
};
