import axios from "axios";

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const JOBS_GET_START = "JOBS_GET_START";
export const JOBS_GET_SUCCESS = "JOBS_GET_SUCCESS";
export const JOBS_GET_FAIL = "JOBS_GET_FAIL";

export const FETCH_JOB_FROM_NOTIFICATION_START =
  "FETCH_JOB_FROM_NOTIFICATION_START";
export const FETCH_JOB_FROM_NOTIFICATION_SUCCESS =
  "FETCH_JOB_FROM_NOTIFICATION_SUCCESS";
export const FETCH_JOB_FROM_NOTIFICATION_FAIL =
  "FETCH_JOB_FROM_NOTIFICATION_FAIL";
// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getJobsStart = () => {
  return {
    type: JOBS_GET_START,
  };
};

export const getJobsSuccess = (jobs) => {
  return {
    type: JOBS_GET_SUCCESS,
    jobs: jobs,
  };
};

export const getJobsFail = (error) => {
  return {
    type: JOBS_GET_FAIL,
    error: error,
  };
};

export const getJobs = (country) => {
  return (dispatch, getState) => {
    dispatch(getJobsStart());

    const language = getState().i18nState.lang;

    let url = null;

    if (country) {
      url = `${process.env.REACT_APP_API_V2_URL}/jobs/?lang=${language}&country=${country}`;
    } else {
      url = `${process.env.REACT_APP_API_V2_URL}/jobs/?lang=${language}`;
    }

    axios
      .get(url)
      .then((res) => {
        let jobs = res.data.results;

        // add unicode names to be used in the search later
        if (language === "mm-z") {
          jobs = jobs.map((job) => {
            return {
              ...job,
              translation: {
                ...job.translation,
                title_mm_uni:
                  job.translation.title && Rabbit.zg2uni(job.translation.title),
              },
            };
          });
        }
        dispatch(getJobsSuccess(jobs));
        return getState().JobsState.items;
      })
      .catch((err) => {
        dispatch(getJobsFail(err));
        throw err;
      });
  };
};

export const fetchJobFromNotificationStart = () => {
  return {
    type: FETCH_JOB_FROM_NOTIFICATION_START,
  };
};

export const fetchJobFromNotificationSuccess = (job) => {
  return {
    type: FETCH_JOB_FROM_NOTIFICATION_SUCCESS,
    job: job,
  };
};

export const fetchJobFromNotificationFail = () => {
  return {
    type: FETCH_JOB_FROM_NOTIFICATION_FAIL,
  };
};

export const fetchJobFromNotification = (jobId) => (dispatch, getState) => {
  const language = getState().i18nState.lang;

  dispatch(fetchJobFromNotificationStart);

  axios
    .get(`${process.env.REACT_APP_API_URL}/jobs/${jobId}/?lang=${language}`)
    .then((res) => dispatch(fetchJobFromNotificationSuccess(res.data)))
    .catch((err) => {
      dispatch(fetchJobFromNotificationFail(err));
      console.log(err);
    });
};
