import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Offline } from "react-detect-offline";
import ReactGA from "react-ga4";
import { Modal, Button } from "react-bootstrap";

import TopNavigationBar from "../components/TopNavigationBar";
import BottomNavigationBar from "../components/BottomNavigationBar";
import UnderConstruction from "../components/UnderConstruction";

import PushNotificationToast from "../components/PushNotificationToast";

export default function BaseView(props, context) {
  const language = useSelector((state) => state.i18nState.lang);

  const serviceWorker = useSelector((state) => state.ServiceWorkerState);
  const [updatingApp, setUpdatingApp] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `${window.location.pathname}${window.location.search}?language=${language}`,
      title: document.title,
    });
  }, []);

  const updateServiceWorker = () => {
    setUpdatingApp(true);
    window.location.reload();
  };

  return (
    <div
      style={{ height: "100%", wordBreak: language === "en" && "break-word" }}
    >
      <TopNavigationBar
        title={props.title}
        backurl={props.backurl}
        avatar={props.avatar}
      />
      <Offline>
        <div className="OfflineBar">
          <i className="fas fa-exclamation-triangle"></i>
          <span>
            {" "}
            {context.t("Some features might not work in offline mode")}{" "}
          </span>
        </div>
      </Offline>
      {props.underConstruction ? (
        <UnderConstruction />
      ) : (
        <div style={{ paddingBottom: 60 }}>{props.children}</div>
      )}
      <PushNotificationToast />
      <BottomNavigationBar />

      <Modal
        show={serviceWorker.updated}
        onHide={() => console.log("hide")}
        centered
      >
        <Modal.Body>
          <h5 style={{ fontSize: "1.2rem", textAlign: "center" }}>
            {" "}
            {context.t("New version is available!")}{" "}
          </h5>
          <p>
            {" "}
            {context.t(
              "Please click refresh the app button to switch to a newer version.",
            )}{" "}
          </p>
          <Button
            onClick={updateServiceWorker}
            style={{ width: "100%" }}
            disabled={updatingApp}
          >
            {updatingApp
              ? context.t("Updating..")
              : context.t("Refresh the app")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

BaseView.contextTypes = {
  t: PropTypes.func.isRequired,
};
