import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { speakSkillLevels } from "../../common/utilities/utilities";

export default function SpeakLevelSelect(props, context) {
  const options = speakSkillLevels(context);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeSpeakLevel = (option) => {
    setSelectedOption(option);
    if (props.onChange) {
      props.onChange(option.value);
    }
  };

  useEffect(() => {
    setSelectedOption(props.initialValue);
  }, [props.initialValue]);

  return (
    <div>
      <Select
        name="speak_level"
        value={options.filter((option) => option.value == selectedOption)[0]}
        onChange={handleChangeSpeakLevel}
        options={options}
      />
    </div>
  );
}

SpeakLevelSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};
