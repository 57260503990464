import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  // FacebookMessengerShareButton,
  LineShareButton,
  // TwitterShareButton,
  ViberShareButton,
  FacebookIcon,
  // FacebookMessengerIcon,
  LineIcon,
  // TwitterIcon,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { Modal, ModalBody } from "react-bootstrap";

const ShareAppButtons = (props, context) => {
  const [showModal, setShowModal] = useState(false);
  const [platform, setPlatform] = useState(null);

  useEffect(() => {
    const platformMatch = navigator.userAgent.match(/Android|Mac|Win/);
    setPlatform(platformMatch ? platformMatch[0].toLowerCase() : "web");
  }, []);

  const getPlatformUrl = () => {
    switch (platform) {
      case "android":
        return process.env.REACT_APP_ANDROID_URL;
      case "mac":
        return process.env.REACT_APP_IOS_URL;
      default:
        return process.env.REACT_APP_WEB_URL;
    }
  };

  const toggleShareApp = () => {
    setShowModal(!showModal);
  };

  const renderSocialButton = ({
    url,
    title,
    shareButton: ShareButton,
    ShareIcon,
  }) => (
    <div style={{ marginBottom: "5px" }}>
      <ShareButton url={url} title={title}>
        <ShareIcon size={25} round onClick={toggleShareApp} />
      </ShareButton>
    </div>
  );

  const renderSocialButtons = () => (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {renderSocialButton({
        url: getPlatformUrl(),
        title: props.title,
        shareButton: FacebookShareButton,
        ShareIcon: FacebookIcon,
      })}
      {renderSocialButton({
        url: getPlatformUrl(),
        title: props.title,
        shareButton: WhatsappShareButton,
        ShareIcon: WhatsappIcon,
      })}
      {renderSocialButton({
        url: getPlatformUrl(),
        title: props.title.slice(0, 198 - window.location.href.length),
        shareButton: ViberShareButton,
        ShareIcon: ViberIcon,
      })}
      {renderSocialButton({
        url: getPlatformUrl(),
        title: props.title,
        shareButton: LineShareButton,
        ShareIcon: LineIcon,
      })}
      {/* {renderSocialButton({
        url: getPlatformUrl(),
        title: props.title,
        shareButton: TwitterShareButton,
        ShareIcon: TwitterIcon,
      })} */}
      {/* {renderSocialButton({
        url: getPlatformUrl(),
        shareButton: FacebookMessengerShareButton,
        ShareIcon: FacebookMessengerIcon,
      })} */}
    </div>
  );

  return (
    <div>
      <i
        className="fa fa-share-alt fa-2x"
        onClick={toggleShareApp}
        style={{ color: "#148696", cursor: "pointer" }}
      />

      {showModal && (
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{context.t("Share App")}</Modal.Title>
          </Modal.Header>
          <ModalBody>{renderSocialButtons()}</ModalBody>
        </Modal>
      )}
    </div>
  );
};

ShareAppButtons.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default ShareAppButtons;
