import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import BaseView from "../BaseView";
import { getStaticPages } from "../../common/redux/actions/StaticPagesActions";

export default function MemberAgreementModal(props, context) {
  const dispatch = useDispatch();

  const staticPages = useSelector((state) => state.StaticPagesState);
  const [memberAgreement, setMemberAgreement] = useState(null);

  useEffect(() => {
    if (!staticPages.fetched) {
      dispatch(getStaticPages());
    }
  }, []);

  useEffect(() => {
    if (staticPages.fetched) {
      const agreement = staticPages.items["member_agreement"];
      setMemberAgreement(agreement);
    }
  }, [staticPages.items]);

  return (
    <Modal centered show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "1rem" }}>
          {" "}
          <b>{context.t("Member Agreement")}</b>{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
        {memberAgreement && (
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: memberAgreement.content }}
          />
        )}
      </Modal.Body>
      <Modal.Footer style={{ flexDirection: "column" }}>
        <Button variant="outline-secondary" onClick={props.onHide}>
          {" "}
          {context.t("Close")}{" "}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

MemberAgreementModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
