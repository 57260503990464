import { updateObject } from "../Utility";
import { SERVICE_WORKER_UPDATE } from "../actions/ServiceWorkerActions";

const initialState = {
  initialized: false,
  updated: false,
  registration: null,
};

const updateServiceWorker = (state, action) => {
  return updateObject(state, {
    updated: true,
    registration: action.registration,
  });
};

const ServiceWorkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_WORKER_UPDATE:
      return updateServiceWorker(state, action);
    default:
      return state;
  }
};

export default ServiceWorkerReducer;
