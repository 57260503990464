import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const JOB_BENEFIT_CATEGORIES_GET_START =
  "JOB_BENEFIT_CATEGORIES_GET_START";
export const JOB_BENEFIT_CATEGORIES_GET_SUCCESS =
  "JOB_BENEFIT_CATEGORIES_GET_SUCCESS";
export const JOB_BENEFIT_CATEGORIES_GET_FAIL =
  "JOB_BENEFIT_CATEGORIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getJobBenefitCategoriesStart = () => {
  return {
    type: JOB_BENEFIT_CATEGORIES_GET_START,
  };
};

export const getJobBenefitCategoriesSuccess = (jobBenefitCategories) => {
  return {
    type: JOB_BENEFIT_CATEGORIES_GET_SUCCESS,
    jobBenefitCategories: jobBenefitCategories,
  };
};

export const getJobBenefitCategoriesFail = (error) => {
  return {
    type: JOB_BENEFIT_CATEGORIES_GET_FAIL,
    error: error,
  };
};

export const getJobBenefitCategories = () => {
  return (dispatch, getState) => {
    dispatch(getJobBenefitCategoriesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/benefit-categories?lang=${language}`,
      )
      .then((res) => {
        dispatch(getJobBenefitCategoriesSuccess(res.data));
        return getState().JobBenefitCategoriesState.items;
      })
      .catch((err) => {
        dispatch(getJobBenefitCategoriesFail(err));
        throw err;
      });
  };
};
