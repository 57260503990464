import { updateObject } from "../Utility";
import {
  JOB_BENEFIT_CATEGORIES_GET_START,
  JOB_BENEFIT_CATEGORIES_GET_SUCCESS,
  JOB_BENEFIT_CATEGORIES_GET_FAIL,
} from "../actions/JobBenefitCategoriesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getJobBenefitCategoriesStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
};

const getJobBenefitCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.jobBenefitCategories.reduce((obj, item) => {
      obj[item.benefit_category] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getJobBenefitCategoriesFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
};

const JobBenefitCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case JOB_BENEFIT_CATEGORIES_GET_START:
      return getJobBenefitCategoriesStart(state, action);
    case JOB_BENEFIT_CATEGORIES_GET_SUCCESS:
      return getJobBenefitCategoriesSuccess(state, action);
    case JOB_BENEFIT_CATEGORIES_GET_FAIL:
      return getJobBenefitCategoriesFail(state, action);

    default:
      return state;
  }
};

export default JobBenefitCategoriesReducer;
