import React, { Component } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { setLanguage } from "redux-i18n";

import BaseView from "./BaseView";
import JobseekersHomeView from "./Jobseekers/JobseekersHomeView";
import { authLogout } from "../common/redux/actions/AuthenticationActions";
import { getProfile } from "../common/redux/actions/ProfileActions";
import { resetAll } from "../common/redux/actions/FiltersSortersActions";
import RequiredFieldSteppersModal from "../components/Modal/RequiredFieldSteppersModal";
import { requestFCMToken, updateFCMToken } from "../firebase";

class HomeView extends Component {
  handleLogout = () => {
    this.props.authLogout();
  };

  componentDidMount = () => {
    this.props.resetAllSortersFilters();

    let params = new URLSearchParams(this.props.history.location.search);

    if (params.get("next")) {
      this.props.history.push(params.get("next"));
    }

    const authData = localStorage.getItem("auth");

    if (this.props.isAuthenticated) {
      try {
        requestFCMToken().then((fcmToken) => {
          if (fcmToken) {
            updateFCMToken(fcmToken);
          }
        });
      } catch (error) {
        console.error("Error handling FCM token:", error);
      }
    }
  };

  render() {
    return (
      <BaseView
        title={this.context.t("Welcome, {name}", {
          name: this.props.profile.id
            ? this.props.profile.full_name || this.context.t("new user")
            : this.context.t("guest"),
        })}
      >
        <RequiredFieldSteppersModal profile={this.props.profile} />
        <JobseekersHomeView />
      </BaseView>
    );
  }
}

HomeView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
    isAuthenticated: state.AuthenticationState.token !== null,
    user_type: state.AuthenticationState.type,
    user_id: state.AuthenticationState.id,
    profile: state.ProfileState.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (type, user_id) => dispatch(getProfile(type, user_id)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    authLogout: () => dispatch(authLogout()),
    resetAllSortersFilters: () => dispatch(resetAll()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
