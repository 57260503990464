import axios from "axios";

import { getProfile, updateProfile } from "./ProfileActions.js";
import { getNotifications } from "./NotificationsActions.js";

var _ = require("lodash");

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const AUTHENTICATION_SIGNUP_START = "AUTHENTICATION_SIGNUP_START";
export const AUTHENTICATION_SIGNUP_SUCCESS = "AUTHENTICATION_SIGNUP_SUCCESS";
export const AUTHENTICATION_SIGNUP_FAIL = "AUTHENTICATION_SIGNUP_FAIL";
export const AUTHENTICATION_SIGNUP_CLEAR_ERRORS =
  "AUTHENTICATION_SIGNUP_CLEAR_ERRORS";

export const AUTHENTICATION_LOGIN_START = "AUTHENTICATION_LOGIN_START";
export const AUTHENTICATION_LOGIN_SUCCESS = "AUTHENTICATION_LOGIN_SUCCESS";
export const AUTHENTICATION_LOGIN_FAIL = "AUTHENTICATION_LOGIN_FAIL";
export const AUTHENTICATION_LOGIN_CLEAR_ERRORS =
  "AUTHENTICATION_LOGIN_CLEAR_ERRORS";

export const AUTHENTICATION_LOGOUT = "AUTHENTICATION_LOGOUT";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// SIGNUP:
export const authSignupStart = () => {
  return {
    type: AUTHENTICATION_SIGNUP_START,
  };
};

export const authSignupSuccess = (token) => {
  return {
    type: AUTHENTICATION_SIGNUP_SUCCESS,
    token: token,
  };
};

export const authSignupFail = (error) => {
  return {
    type: AUTHENTICATION_SIGNUP_FAIL,
    error: error,
  };
};

export const authSignupClearErrors = () => {
  return {
    type: AUTHENTICATION_SIGNUP_CLEAR_ERRORS,
  };
};

export const authSignup = (type, payload) => {
  return (dispatch, getState) => {
    const preferred_language = parseInt(
      _.findKey(getState().LanguagesState.items, {
        code: localStorage.getItem("language"),
      })
    );

    dispatch(authSignupStart());
    axios
      .post(`${process.env.REACT_APP_API_URL}/authentication/register/`, {
        type: type,
        username: payload["username"],
        password: payload["password"],
        turnstile_token: payload["turnstileToken"],
        preferred_language: preferred_language,
      })
      .then((res) => {
        const user_id = res.data.id;
        const token = res.data.token;
        // Include Authorization Header in all future requests
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;
          setAuthLocalStorage(user_id, type, token);
          // localStorage.setItem('token', token);
          dispatch(authSignupSuccess(token));
          dispatch(updateProfile(type, user_id, payload));
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch(authSignupFail({ message: "turnstile_failed" }));
        } else if (
          err.response &&
          err.response.data &&
          err.response.data.message ===
            "Turnstile token is required to validate user"
        ) {
          dispatch(authSignupFail({ message: "missing_token" }));
        } else if (err.message === "Network Error") {
          dispatch(authSignupFail({ message: "network_error" }));
        } else {
          dispatch(authSignupFail({ message: "user_already_existed" }));
        }
      });
  };
};

export const authBusinessSignup = (payload) => {
  return (dispatch, getState) => {
    const preferred_language = parseInt(
      _.findKey(getState().LanguagesState.items, {
        code: getState().i18nState.lang,
      })
    );

    dispatch(authSignupStart());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/authentication/register-business/`,
        { ...payload, preferred_language: preferred_language }
      )
      .then((res) => {
        const user_id = res.data.id;
        const type = res.data.type;
        const token = res.data.token;
        // Include Authorization Header in all future requests
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;
          setAuthLocalStorage(user_id, type, token);
          // localStorage.setItem('token', token);
          dispatch(authSignupSuccess(token));
          dispatch(updateProfile(type, user_id, payload));
        }
      })
      .catch((err) => {
        dispatch(
          authSignupFail({ message: "User with this email already exists" })
        );
      });
  };
};

export const authGoogle = (type, access_token) => {
  return (dispatch, getState) => {
    const preferred_language = parseInt(
      _.findKey(getState().LanguagesState.items, {
        code: localStorage.getItem("language"),
      })
    );
    dispatch(authSignupStart());
    axios
      .post(`${process.env.REACT_APP_API_URL}/authentication/google/`, {
        type: type,
        access_token: access_token,
        preferred_language: preferred_language,
      })
      .then((res) => {
        const user_id = res.data.id;
        const token = res.data.token;
        const user_type = res.data.type;
        // Include Authorization Header in all future requests
        if (token && user_type !== "EM" && user_type !== "RA") {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;
          setAuthLocalStorage(user_id, type, token);

          dispatch(authSignupSuccess(token));
          dispatch(updateProfile(type, user_id, {}));
        } else {
          dispatch(authSignupFail({ message: "invalid_user" }));
        }
      })
      .catch((err) => {
        dispatch(authSignupFail(err.response ? err.response.data : err));
      });
  };
};

export const authFacebook = (type, access_token) => {
  return (dispatch, getState) => {
    const preferred_language = parseInt(
      _.findKey(getState().LanguagesState.items, {
        code: localStorage.getItem("language"),
      })
    );
    dispatch(authLoginStart());
    axios
      .post(`${process.env.REACT_APP_API_URL}/authentication/facebook/`, {
        type: type,
        access_token: access_token,
        preferred_language: preferred_language,
      })
      .then((res) => {
        const user_id = res.data.id;
        const token = res.data.token;
        // Include Authorization Header in all future requests
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;
          setAuthLocalStorage(user_id, type, token);

          dispatch(authLoginSuccess(token));
          dispatch(updateProfile(type, user_id, {}));
        }
      })
      .catch((err) => {
        dispatch(authLoginFail(err.response ? err.response.data : err));
      });
  };
};

// LOGIN:
export const authLoginStart = () => {
  return {
    type: AUTHENTICATION_LOGIN_START,
  };
};

export const authLoginSuccess = (token) => {
  return {
    type: AUTHENTICATION_LOGIN_SUCCESS,
    token: token,
  };
};

export const authLoginFail = (error) => {
  return {
    type: AUTHENTICATION_LOGIN_FAIL,
    error: error,
  };
};

export const authLoginClearErrors = () => {
  return {
    type: AUTHENTICATION_LOGIN_CLEAR_ERRORS,
  };
};

export const authLogin = (payload) => {
  return (dispatch) => {
    dispatch(authLoginStart());
    axios
      .post(`${process.env.REACT_APP_API_URL}/authentication/login/`, payload)
      .then((res) => {
        const token = res.data.token;
        const user_id = res.data.id;
        const user_type = res.data.type;

        axios.defaults.headers.common["Authorization"] = `Token ${token}`;
        setAuthLocalStorage(user_id, user_type, token);
        dispatch(authLoginSuccess(token));
        dispatch(getProfile(user_type, user_id));
        dispatch(getNotifications());
      })
      .catch((err) => {
        dispatch(authLoginFail(err.response ? err.response.data : err));
      });
  };
};

export const authGoogleLogin = (access_token) => {
  return (dispatch) => {
    dispatch(authLoginStart());
    axios
      .post(`${process.env.REACT_APP_API_URL}/authentication/google/login/`, {
        access_token: access_token,
      })
      .then((res) => {
        const token = res.data.token;
        const user_id = res.data.id;
        const type = res.data.type;
        // Include Authorization Header in all future requests
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;
          setAuthLocalStorage(user_id, type, token);
          // localStorage.setItem('token', token);
          dispatch(authLoginSuccess(token));
          dispatch(getProfile(type, user_id));
          dispatch(getNotifications());
        }
      })
      .catch((err) => {
        dispatch(authLoginFail(err.response ? err.response.data : err));
      });
  };
};

export const authFacebookLogin = (access_token) => {
  return (dispatch, getState) => {
    dispatch(authLoginStart());
    axios
      .post(`${process.env.REACT_APP_API_URL}/authentication/facebook/login/`, {
        access_token: access_token,
      })
      .then((res) => {
        const token = res.data.token;
        const user_id = res.data.id;
        const type = res.data.type;
        // Include Authorization Header in all future requests
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;
          setAuthLocalStorage(user_id, type, token);

          // localStorage.setItem('token', token);
          // localStorage.setItem('token', token);
          dispatch(authLoginSuccess(token));
          dispatch(getProfile(type, user_id));
          dispatch(getNotifications());
        }
      })
      .catch((err) => {
        dispatch(authLoginFail(err.response ? err.response.data : err));
      });
  };
};

export const authAutoLogin = () => {
  return (dispatch) => {
    dispatch(authLoginStart());
    return new Promise((resolve, reject) => {
      const auth = JSON.parse(localStorage.getItem("auth"));
      if (auth !== null) {
        const token = auth.token;
        const user_id = auth.user_id;
        const type = auth.type;
        // Include Authorization Header in all future requests
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Token ${token}`;

          dispatch(getProfile(type, user_id))
            .then((res) => {
              dispatch(authLoginSuccess(token));
              dispatch(getNotifications());
              resolve(user_id);
            })
            .catch((e) => {
              dispatch(authLoginFail());
              reject(e);
            });
        }
      } else {
        dispatch(authLoginFail());
        reject("no token in local storage");
      }
    });
  };
};

// LOGOUT:
export const authLogout = () => {
  clearAuthLocalStorage();
  return {
    type: AUTHENTICATION_LOGOUT,
  };
};

// HELPERS:
const setAuthLocalStorage = (user_id, type, token) => {
  const auth = { user_id: user_id, type: type, token: token };
  localStorage.setItem("auth", JSON.stringify(auth));
};

export const clearAuthLocalStorage = () => {
  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("auth");
  // removing temporary saved employer value for input assistant
  localStorage.removeItem("employer");
};
