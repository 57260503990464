import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import BaseView from "../BaseView";
import { getStaticPages } from "../../common/redux/actions/StaticPagesActions";

export default function MemberAgreementView(props, context) {
  const history = useHistory();
  const dispatch = useDispatch();

  const staticPages = useSelector((state) => state.StaticPagesState);
  const [memberAgreement, setMemberAgreement] = useState(null);

  useEffect(() => {
    !staticPages.fetched && !staticPages.loading && dispatch(getStaticPages());
  }, []);

  useEffect(() => {
    if (staticPages.fetched) {
      let memberAgreement = staticPages.items["member_agreement"];
      memberAgreement
        ? setMemberAgreement(memberAgreement)
        : history.push("/404");
    }
  }, [staticPages.items]);

  return (
    <BaseView title={context.t("Member Agreement")} backurl="/">
      {staticPages.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        memberAgreement && (
          <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
            <Container
              className="pb-6"
              style={{ textAlign: "left", overflow: "hidden" }}
            >
              <h5 className="mt-3" style={{ textAlign: "center" }}>
                {" "}
                {context.t("Member Agreement")}{" "}
              </h5>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: memberAgreement.content }}
                />
              </div>
            </Container>
          </Container>
        )
      )}
    </BaseView>
  );
}

MemberAgreementView.contextTypes = {
  t: PropTypes.func.isRequired,
};
