import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Link, NavLink } from "react-router-dom";

import { Container, Row, Col, Image, Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import BaseView from "../BaseView";

import { resolveProblem } from "../../common/redux/actions/ProblemsActions";

const workerStatusColors = {
  WAITING_FOR_ISSARA: "green",
  WAITING_FOR_WORKER: "orange",
  RESOLVED: "grey",
};

const adminStatusColors = {
  WAITING_FOR_ISSARA: "orange",
  WAITING_FOR_WORKER: "green",
  RESOLVED: "grey",
};

export default function ProblemListItem(props, context) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileState.data);
  const language = useSelector((state) => state.i18nState.lang);

  const statusMessage = (status) => {
    if (status === "WAITING_FOR_ISSARA") {
      return context.t("Waiting for Issara's reply");
    } else if (status === "WAITING_FOR_WORKER") {
      if (profile.type === "IS") {
        return context.t("Waiting for worker's reply");
      }
      return context.t(
        "Issara is waiting for your reply. Click the message to see the reply.",
      );
    } else if (status === "RESOLVED") {
      return context.t("Resolved");
    }
  };

  return (
    <Link
      to={`/problems/${props.problem.id}`}
      style={{ pointerEvents: props.pointerDisabled && "none" }}
    >
      <ListGroup.Item className="Card mt-2" style={{ padding: "0.5rem" }}>
        <Row>
          <Col>
            <span
              style={{
                padding: "2px 4px",
                borderRadius: 4,
                fontSize: "0.8rem",
                backgroundColor:
                  profile.type === "IS"
                    ? adminStatusColors[props.problem.status]
                    : workerStatusColors[props.problem.status],
                color: "white",
              }}
            >
              {statusMessage(props.problem.status)}
            </span>
            <span
              style={{
                display: "block",
                fontSize: "0.8rem",
                fontWeight: 500,
                marginTop: 4,
              }}
            >
              {" "}
              {context.t("Last update: {date}", {
                date: moment(props.problem.updated_at).format("LLL"),
              })}{" "}
            </span>
          </Col>
          {profile.type === "IS" && props.problem.status !== "RESOLVED" && (
            <Button
              variant="link"
              size="sm"
              style={{
                position: "absolute",
                top: 6,
                right: 0,
                lineHeight: "initial",
                fontSize: "0.8rem",
                pointerEvents: "auto",
              }}
              onClick={() => dispatch(resolveProblem(props.problem.id))}
            >
              {context.t("Mark as resolved")}
            </Button>
          )}
        </Row>
        <Row className="mt-2">
          <Col>
            <p style={{ marginBottom: 0 }}> {props.problem.description} </p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col style={{ color: "#3f505f" }}>
            <p style={{ marginBottom: 0, fontSize: "0.75rem" }}>
              {" "}
              {context.t("Created: {date}", {
                date: moment(props.problem.created_at).format("LLL"),
              })}
            </p>
          </Col>
        </Row>
      </ListGroup.Item>
    </Link>
  );
}

ProblemListItem.contextTypes = {
  t: PropTypes.func.isRequired,
};
