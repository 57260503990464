import { updateObject } from "../Utility";
import {
  GUIDES_GET_START,
  GUIDES_GET_SUCCESS,
  GUIDES_GET_FAIL,
  GUIDES_ADD_COMMENT,
  GUIDES_ADD_CHILD_COMMENT,
  GUIDES_DELETE_COMMENT,
} from "../actions/GuidesActions";

const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getGuidesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getGuidesSuccess = (state, action) => {
  return updateObject(state, {
    items: action.guides,
    loading: false,
    fetched: true,
    error: null,
  });
};

const getGuidesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

// ADD:
const addGuidesComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((guide) =>
      guide.id === action.comment.object_id
        ? // transform the one with a matching id
          {
            ...guide,
            comments_count: guide.comments_count + 1,
            comments: [...guide.comments, action.comment],
          }
        : // otherwise return original guide
          guide,
    ),
    loading: false,
    error: null,
  });
};

const addGuidesChildComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((guide) =>
      guide.id === action.comment.object_id
        ? // transform the one with a matching id
          {
            ...guide,
            comments_count: guide.comments_count + 1,
            comments: guide.comments.map((comment) =>
              comment.id === action.comment.base_parent
                ? {
                    ...comment,
                    children: [...comment.children, action.comment],
                  }
                : comment,
            ),
          }
        : // otherwise return original guide
          guide,
    ),
    loading: false,
    error: null,
  });
};

// DELETE:
const deleteGuidesComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((guide) =>
      guide.id === action.guide_id
        ? {
            ...guide,
            comments: guide.comments.filter(
              (comment) => comment !== action.comment_id,
            ),
          }
        : guide,
    ),
    error: null,
    loading: false,
  });
};

const GuidesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case GUIDES_GET_START:
      return getGuidesStart(state, action);
    case GUIDES_GET_SUCCESS:
      return getGuidesSuccess(state, action);
    case GUIDES_GET_FAIL:
      return getGuidesFail(state, action);

    // ADD:
    case GUIDES_ADD_COMMENT:
      return addGuidesComment(state, action);
    case GUIDES_ADD_CHILD_COMMENT:
      return addGuidesChildComment(state, action);

    // DELETE:
    case GUIDES_DELETE_COMMENT:
      return deleteGuidesComment(state, action);

    default:
      return state;
  }
};

export default GuidesReducer;
