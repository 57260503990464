import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const SHOW_PUSH_NOTIFICATION_TOAST = "SHOW_PUSH_NOTIFICATION_TOAST";
export const HIDE_PUSH_NOTIFICATION_TOAST = "HIDE_PUSH_NOTIFICATION_TOAST";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

export const showPushNotificationToastSuccess = (data, notificationID) => {
  return {
    type: SHOW_PUSH_NOTIFICATION_TOAST,
    data: data,
    notificationID: notificationID,
  };
};

export const hidePushNotificationToastSuccess = () => {
  return {
    type: HIDE_PUSH_NOTIFICATION_TOAST,
  };
};

export const showPushNotificationToast = (data) => {
  return (dispatch) => {
    dispatch(showPushNotificationToastSuccess(data));
  };
};

export const hidePushNotificationToast = () => {
  return (dispatch) => {
    dispatch(hidePushNotificationToastSuccess());
  };
};
