import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import Contact from "./Contact";

const Address = (props, context) => {
  return (
    <Card className="mt-2" style={{ borderRadius: "8px" }}>
      <Card.Header style={{ padding: 6, textAlign: "left" }}>
        <p style={{ margin: 0 }}>
          {props.street && `${props.street}, `}
          {props.cities.items[props.city] &&
            `${props.cities.items[props.city].name}, `}
          {props.states.items[props.state] &&
            `${props.states.items[props.state].name}, `}
          {props.countries.items[props.country] &&
            `${props.countries.items[props.country].name}, `}
          {props.zip}
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${props.name_en}`}
            target="_blank"
            rel="noopener noreferrer"
            className="location-icon"
          >
            <i className="fa fa-location-arrow fa-sm"></i>
          </a>
        </p>
      </Card.Header>
      <Card.Body>
        {props.addressContacts.filter((contact) => contact.translation).length >
        0 ? (
          props.addressContacts.map((contact, key) => {
            if (contact.translation) {
              return <Contact key={key} contact={contact} />;
            }
          })
        ) : (
          <div></div>
          // <p>{context.t("No Focal Person contact provided")}</p>
        )}
      </Card.Body>
    </Card>
  );
};

Address.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    contacts: state.ContactsState,
    countries: state.CountriesState,
    cities: state.CitiesState,
    states: state.StatesState,
  };
};

export default connect(mapStateToProps)(Address);
