import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const MARITAL_STATUSES_GET_START = "MARITAL_STATUSES_GET_START";
export const MARITAL_STATUSES_GET_SUCCESS = "MARITAL_STATUSES_GET_SUCCESS";
export const MARITAL_STATUSES_GET_FAIL = "MARITAL_STATUSES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getMaritalStatusesStart = () => {
  return {
    type: MARITAL_STATUSES_GET_START,
  };
};

export const getMaritalStatusesSuccess = (maritalStatuses) => {
  return {
    type: MARITAL_STATUSES_GET_SUCCESS,
    maritalStatuses: maritalStatuses,
  };
};

export const getMaritalStatusesFail = (error) => {
  return {
    type: MARITAL_STATUSES_GET_FAIL,
    error: error,
  };
};

export const getMaritalStatuses = () => {
  return (dispatch, getState) => {
    dispatch(getMaritalStatusesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/jobseekers/marital-statuses?lang=${language}`,
      )
      .then((res) => {
        dispatch(getMaritalStatusesSuccess(res.data));
        return getState().MaritalStatusesState.items;
      })
      .catch((err) => {
        dispatch(getMaritalStatusesFail(err));
        throw err;
      });
  };
};
