import React from "react";

import { connect } from "react-redux";
import { Row, Col, Image } from "react-bootstrap";

import { addDefaultImageSrc } from "../common/utilities/utilities";

const Contact = (props) => {
  return (
    <Row className="ContactCard mt-1">
      <Col xs={4}>
        <Image
          src={props.contact.image || "/static/media/astronaut.png"}
          crossOrigin="anonymous"
          onError={addDefaultImageSrc}
        />
      </Col>
      <Col xs={8} style={{ paddingLeft: 0 }}>
        <h6 style={{ fontWeight: 600 }}>
          {props.contact.translation.first_name}
          {props.contact.translation.last_name}
        </h6>
        <p> {props.contact.translation.position} </p>
        <a href={`mailto: ${props.contact.email}`} style={{ display: "block" }}>
          {props.contact.email}
        </a>
        <a
          href={`tel: ${props.contact.phone_number}`}
          style={{ display: "block" }}
        >
          {props.contact.phone_number}
        </a>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.ContactsState,
  };
};

export default connect(mapStateToProps)(Contact);
