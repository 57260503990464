import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const REJECTION_REASONS_GET_START = "REJECTION_REASONS_GET_START";
export const REJECTION_REASONS_GET_SUCCESS = "REJECTION_REASONS_GET_SUCCESS";
export const REJECTION_REASONS_GET_FAIL = "REJECTION_REASONS_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getRejectionReasonsStart = () => {
  return {
    type: REJECTION_REASONS_GET_START,
  };
};

export const getRejectionReasonsSuccess = (rejectionReasons) => {
  return {
    type: REJECTION_REASONS_GET_SUCCESS,
    rejectionReasons: rejectionReasons,
  };
};

export const getRejectionReasonsFail = (error) => {
  return {
    type: REJECTION_REASONS_GET_FAIL,
    error: error,
  };
};

export const getRejectionReasons = () => {
  return (dispatch, getState) => {
    dispatch(getRejectionReasonsStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/rejection-reasons?lang=${language}`,
      )
      .then((res) => {
        dispatch(getRejectionReasonsSuccess(res.data));
        return getState().RejectionReasonsState.items;
      })
      .catch((err) => {
        dispatch(getRejectionReasonsFail(err));
        throw err;
      });
  };
};
