import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";

import { Online } from "react-detect-offline";

import BaseView from "../BaseView";
import Comment from "../../components/Comment";
import CommentInput from "../../components/CommentInput";
import ShareButtons from "../../components/ShareButtons";
import { getNews } from "../../common/redux/actions/NewsActions";
import { postComment } from "../../common/redux/actions/CommentsActions";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import axios from "axios";

export default function NewsDetailedView(props, context) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileState);
  const news = useSelector((state) => state.NewsState);

  const [newsItem, setNewsItem] = useState(null);
  const [commentReply, setCommentReply] = useState({
    show: false,
    parent: null,
    base_parent: null,
    username: "",
    isModerator: false,
  });

  useEffect(() => {
    !news.fetched && dispatch(getNews());
  }, []);

  // mark notification as read (if any)
  useEffect(() => {
    if (newsItem && newsItem.has_news_notifications === true) {
      axios
        .patch(`${process.env.REACT_APP_API_URL}/notifications/news/`, {
          action_id: newsItem.translation_id,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("Notification marked as read");
          }
        })
        .catch((error) => {
          console.error("Failed to mark notification as read", error);
        });
    }
  }, [newsItem]);

  useEffect(() => {
    if (news.fetched) {
      const newsId = parseInt(props.match.params.id);

      const matchedNews = news.items.filter((news) => news.id === newsId)[0];
      if (matchedNews) {
        setNewsItem(matchedNews);
      } else {
        props.history.push("/not-found");
      }
    }
  }, [news.items]);

  const handleSubmitComment = (payload) => {
    dispatch(postComment("news", payload));
  };

  const handleClickReplyButton = (replyPayload) => {
    setCommentReply({
      ...replyPayload,
      show: true,
    });
  };

  const handleCloseReply = () => {
    setCommentReply({
      show: false,
      parent: null,
      base_parent: null,
      username: "",
      isModerator: false,
    });
  };

  return (
    <BaseView title={context.t("News")} backurl="/news">
      <hr className="afterTopNavigationBar"></hr>
      {news.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        newsItem && (
          <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
            <Image
              src={
                newsItem.image ? newsItem.image : "/static/media/guide_news.jpg"
              }
              crossOrigin="anonymous"
              style={{ width: "inherit" }}
              onError={addDefaultImageSrc}
            />
            <Container
              className="pb-6"
              style={{ textAligh: "left !important", overflow: "hidden" }}
            >
              {profile.data && profile.data.type === "IS" && (
                <Button
                  href={`${process.env.REACT_APP_SERVER_URL}/admin/news/news/${newsItem.id}/change`}
                  target="_blank"
                  className="mt-3"
                  style={{ backgroundColor: "teal", border: "none" }}
                >
                  {" "}
                  Change in CMS
                </Button>
              )}
              <h3 className="mt-4">{newsItem.title}</h3>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: newsItem.description }}
              />
              <hr />
              {newsItem.audio && (
                <audio src={newsItem.audio} controls preload={"auto"}></audio>
              )}
              {newsItem.video && (
                <video
                  src={newsItem.video}
                  width="320"
                  height="240"
                  controls
                  preload="auto"
                ></video>
              )}
              {newsItem.comments && (
                <div className="CommentThread">
                  {newsItem.comments
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at),
                    )
                    .map((comment, index) => {
                      return (
                        <Comment
                          key={index}
                          comment={comment}
                          onClickReply={handleClickReplyButton}
                          onSubmitReply={handleSubmitComment}
                        />
                      );
                    })}
                </div>
              )}
              <Online>
                {profile.data && profile.data.id ? (
                  <CommentInput
                    user_id={profile.data.id}
                    object_id={newsItem.id}
                    parent={commentReply.parent}
                    base_parent={commentReply.base_parent}
                    isReply={commentReply.show}
                    onSubmit={handleSubmitComment}
                    onCloseReply={handleCloseReply}
                    replyUsername={commentReply.username}
                    replyIsModerator={commentReply.isModerator}
                  />
                ) : (
                  <div className="mt-3">
                    <NavLink to="/login">
                      {context.t("Please login to write a comment..")}
                    </NavLink>
                  </div>
                )}
              </Online>
              <div className="mt-3">
                <ShareButtons title={newsItem.title} />
              </div>
            </Container>
          </Container>
        )
      )}
    </BaseView>
  );
}

NewsDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
