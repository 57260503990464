import { updateObject } from "../Utility";
import {
  NATIONALITIES_GET_START,
  NATIONALITIES_GET_SUCCESS,
  NATIONALITIES_GET_FAIL,
} from "../actions/NationalitiesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getNationalitiesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getNationalitiesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.nationalities.reduce((obj, item) => {
      obj[item.nationality] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getNationalitiesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const NationalitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case NATIONALITIES_GET_START:
      return getNationalitiesStart(state, action);
    case NATIONALITIES_GET_SUCCESS:
      return getNationalitiesSuccess(state, action);
    case NATIONALITIES_GET_FAIL:
      return getNationalitiesFail(state, action);

    default:
      return state;
  }
};

export default NationalitiesReducer;
