import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Container, Card, Button, Image } from "react-bootstrap";
import BaseView from "../BaseView";
import moment from "moment";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineSmile, AiOutlineArrowUp } from "react-icons/ai";
import ReactionIcons from "../../components/ReactionIcons";
import CommentInput from "../../components/CommentInput";
import CommentThread from "./CommentThread";

import {
  addDefaultImageSrc,
  deletePermissionLogic,
} from "../../common/utilities/utilities";
import PostEditorModal from "./PostEditorModal";
import DeleteConfirmModal from "./DeleteConfirmModal";
import "./PostDetailedView.css";

export default function PostDetailedView(props, context) {
  const lang = useSelector((state) => state.i18nState.lang);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState("");
  const postId = parseInt(props.match.params.id);
  const languageId = parseInt(props.match.params.langId);
  const profile = useSelector((state) => state.ProfileState.data);

  const [reacted, setReacted] = useState(false);
  const isMountedRef = useRef(false);

  const [reaction, setReaction] = useState({});

  const [commentReply, setCommentReply] = useState({
    show: false,
    parent: null,
    username: "",
  });

  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);
    callDetailPost(postId);
    getReactions(postId);
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const getReactions = (post_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}/list-likes/?lang=${lang}`,
      )
      .then((res) => {
        setReaction(res.data);
      })
      .catch((err) => console.log("viewed recently"));
  };

  const onLikeClick = (e, type) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${postId}/react/?lang=${lang}`,
        {
          reaction_type: type.toUpperCase(),
        },
      )
      .then((response) => {
        setReaction(response.data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        // Handle any errors that occurred during the request
      });
  };
  const callDetailPost = (postId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${postId}?lang=${lang}`,
      )
      .then(function (response) {
        if (isMountedRef.current) {
          setLoading(false);
          setPost(response.data);
        }
      })
      .catch(function (error) {
        props.history.push("/not-found");
      });
  };

  const updatePostComments = (post_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${post_id}?lang=${lang}`,
      )
      .then(function (response) {
        setPost(response.data);
      });
  };

  const updatedStatus = (status) => {
    if (status) {
      setLoading(true);
      callDetailPost(postId);
    }
  };

  const postComment = (data) => {
    const formData = new FormData();
    formData.append("comment_body", data.text);
    if (data.parent !== null) {
      formData.append("parent_comment", data.parent);
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${postId}/comment/?lang=${lang}`,
        formData,
      )
      .then((response) => {
        updatePostComments(postId);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };

  const handleClickReplyButton = (replyPayload) => {
    setCommentReply({
      ...replyPayload,
      show: true,
    });
  };

  const handleCloseReply = () => {
    setCommentReply({
      show: false,
      parent: null,
      username: "",
    });
  };

  return (
    <BaseView title={context.t("Post Detail")} backurl="/groups">
      <hr className="afterTopNavigationBar"></hr>
      {!loading && post ? (
        <Container>
          <Card className="pd_card">
            <Card.Header>
              <Card.Title className="pd_title">
                <Image
                  className="post_profileImg"
                  onError={addDefaultImageSrc}
                  height={30}
                  width={30}
                  src={post.avatar ? post.avatar : "/static/media/l60Hf.png"}
                />
                &nbsp;{post.username}
                <span className="pd_delete_edit">
                  {deletePermissionLogic(post, profile) && (
                    <DropdownButton
                      id="dropdown-option"
                      variant={"outline-dark"}
                      title="..."
                    >
                      <Dropdown.Item>
                        <PostEditorModal
                          type="detail"
                          post_id={post.id}
                          updatedStatus={(status) => updatedStatus(status)}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <DeleteConfirmModal type="detail" post_id={post.id} />
                      </Dropdown.Item>
                    </DropdownButton>
                  )}
                </span>
                <Card.Subtitle className="pd_subtitle">
                  {moment(post.posted).fromNow()}
                </Card.Subtitle>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <span>{post.article}</span>
                {post.file && (
                  <Container fluid className="post-image">
                    <Image width={902} height={960} src={post.file} fluid />
                  </Container>
                )}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <ReactionIcons
                type="comment"
                makeReaction={onLikeClick}
                reactionSet={reaction}
              />
            </Card.Footer>
          </Card>
          {post.comments.length > 0 ? (
            post.comments.map((item, index) => {
              return (
                <CommentThread
                  key={index}
                  postId={postId}
                  comment={item}
                  lang={lang}
                  onClickReply={handleClickReplyButton}
                />
              );
            })
          ) : (
            <div className="no_comments">
              <p>
                {context.t("Be a first person to comment on this post")}
                &nbsp;&nbsp;
                <AiOutlineSmile />
              </p>
            </div>
          )}
          <CommentInput
            replyToText={commentReply.text}
            user_id={profile}
            object_id={postId}
            parent={commentReply.parent}
            base_parent={post}
            isReply={commentReply.show}
            onSubmit={postComment}
            onCloseReply={handleCloseReply}
            replyUsername={commentReply.username}
            replyIsModerator={false}
          />
          {post.comments_count > 5 && (
            <>
              <hr />
              <Button
                style={{ border: "1px solid black" }}
                className="mt-1 mb-3"
                variant="warning"
                onClick={scrollToTop}
              >
                <AiOutlineArrowUp />
                &nbsp;
                {context.t("Go back Top")}
              </Button>
            </>
          )}
        </Container>
      ) : (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      )}
    </BaseView>
  );
}

PostDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
