import React from "react";
import moment from "moment";

export const JOB_SEEKER_LANGUAGES = Object.freeze({
  BURMESE_UNICODE: "mm-u",
  BURMESE_ZAWGYI: "mm-z",
  ENGLISH: "en",
  KHMER: "kh",
  NEPALI: "ne",
});

export const addDefaultImageSrc = (ev) => {
  ev.target.src = "/static/media/ImagePlaceholder.png";
};

const heroImages = {
  "mm-z": "/static/media/burmese-bg.jpg",
  "mm-u": "/static/media/burmese-bg.jpg",
  kh: "/static/media/khmer-bg.jpg",
  ne: "/static/media/nepal-bg.jpeg",
  default: "/static/media/burmese-bg.jpg",
};

export const getHeroImage = (lang) => heroImages[lang] || heroImages.default;

const titleImages = {
  "mm-z": "/static/media/burmese-title.png",
  "mm-u": "/static/media/burmese-title.png",
  kh: "/static/media/khmer-title.png",
  ne: "/static/media/nepal-title.png",
  default: "/static/media/eng-title.png",
};

export const getTitleImage = (lang) => titleImages[lang] || titleImages.default;

const employerSizeMap = {
  "<500": "XS",
  "500-1000": "S",
  "1000-2000": "M",
  "2000-5000": "L",
  ">5000": "XL",
};

export const translateEmployerSize = (empSize) =>
  employerSizeMap[empSize] || empSize;

const reverseEmployerSizeMap = {
  XS: "<500",
  S: "500-1000",
  M: "1000-2000",
  L: "2000-5000",
  XL: ">5000",
};

export const reverseTranslateEmployerSize = (empSize) =>
  reverseEmployerSizeMap[empSize] || empSize;

export const anonymizeComment = (comment) => {
  delete comment.sender_avatar;
  delete comment.sender_username;
  delete comment.recipient_username;
};

export const isMetric = (language) =>
  !["en", "mm-u", "mm-z"].includes(language);

export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) => `<a target="_blank" href="${url}">${url}</a>`
  );
};

export const appLangId = (languages) => {
  const languageCode = localStorage.getItem("language") || "en";
  const foundLanguage = languages.itemsArray.find(
    (language) => language.code === languageCode
  );
  return foundLanguage ? foundLanguage.id : null;
};

export const langaugeSkills = (context, languages) => [
  { label: context.t("English"), value: getLanguageId(languages, "en") },
  { label: context.t("Thai"), value: getLanguageId(languages, "th") },
  {
    label: context.t("Bahasa Malaysia"),
    value: getLanguageId(languages, "ms"),
  },
  { label: context.t("Hindi"), value: getLanguageId(languages, "hi") },
];

const getLanguageId = (languages, code) => {
  const languageItem = languages.itemsArray.find((item) => item.code === code);
  return languageItem ? languageItem.id : null;
};

export const readSkillLevels = (context) => [
  { label: context.t("Cannot read"), value: "NONE" },
  { label: context.t("A Little Bit"), value: "A LITTLE BIT" },
  { label: context.t("Moderately"), value: "MODERATELY" },
  { label: context.t("Very Well"), value: "VERY WELL" },
];

const rosterStatusMap = {
  APPLICABLE: "APPLICABLE",
  APPLIED: "APPLIED",
  VIEWED: "VIEWED",
  DOWNLOADED: "DOWNLOADED",
  SHORTLISTED: "SHORTLISTED",
  OFFERED: "OFFERED",
  ACCEPTED: "ACCEPTED",
  REJECTED_BY_BUSINESS: "REJECTED_BY_BUSINESS",
  REJECTED_BY_APPLICANT: "REJECTED_BY_APPLICANT",
  CONTRACT_SIGNED: "CONTRACT_SIGNED",
  APPLICANT_NA: "APPLICANT_NA",
  WAITLISTED: "WAITLISTED",
  MOVED_GENERAL_WAITLIST: "MOVED_GENERAL_WAITLIST",
};

export const rosterStatus = (context, status) =>
  context.t(rosterStatusMap[status] || "STATUS N/A");

export const speakSkillLevels = (context) => [
  { label: context.t("Cannot speak"), value: "NONE" },
  { label: context.t("A Little Bit"), value: "A LITTLE BIT" },
  { label: context.t("Moderately"), value: "MODERATELY" },
  { label: context.t("Very Well"), value: "VERY WELL" },
];

const permissionLogic = (post, profile, isComment = false) => {
  if (post.user === profile.id && profile.type !== "IS") {
    return true;
  } else if (post.user !== profile.id && profile.type !== "IS") {
    return false;
  } else if (post.user !== profile.id && profile.type === "IS") {
    return true;
  } else if (post.user === profile.id && profile.type === "IS") {
    return true;
  }
  return false;
};

export const deletePermissionLogic = (post, profile) =>
  permissionLogic(post, profile);
export const editPermissionLogic = (post, profile) =>
  permissionLogic(post, profile);
export const commentDeletePermissionLogic = (comment, profile) =>
  permissionLogic(comment, profile, true);
export const commentEditPermissionLogic = (comment, profile) =>
  permissionLogic(comment, profile, true);

const statusColorMap = {
  APPLICABLE: "info",
  APPLIED: "warning",
  VIEWED: "info",
  DOWNLOADED: "info",
  SHORTLISTED: "success",
  OFFERED: "success",
  ACCEPTED: "success",
  REJECTED_BY_BUSINESS: "danger",
  REJECTED_BY_APPLICANT: "danger",
  CONTRACT_SIGNED: "success",
  APPLICANT_NA: "info",
  WAITLISTED: "warning",
  MOVED_GENERAL_WAITLIST: "warning",
};

export const statusColorizor = (status) => statusColorMap[status] || "primary";

const findCurrencyIdByCode = (code, currencies) => {
  const foundCurrency = currencies.itemsArray.find(
    (currency) => currency.code === code
  );
  return foundCurrency ? foundCurrency.id : 1;
};

export const getCurrencyIdByCountryCode = (countryCode, currencies) => {
  const currencyCodeMap = {
    kh: "KHR",
    mm: "MMK",
    my: "MYR",
    ne: "NPR",
    bn: "BDT",
  };
  const currencyCode = currencyCodeMap[countryCode] || "USD";
  return findCurrencyIdByCode(currencyCode, currencies);
};

export const objectToQueryParams = (obj) => {
  const queryParams = Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (value === null || value === undefined || value === "") {
        return "";
      }
      if (Array.isArray(value) && value.length > 0) {
        return value
          .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join("&");
      } else {
        const stringValue = String(value);
        const encodedValue = stringValue.includes(",")
          ? stringValue
          : encodeURIComponent(value);
        return `${encodeURIComponent(key)}=${encodedValue}`;
      }
    })
    .filter((param) => param !== "")
    .join("&");
  return `?${queryParams}`;
};

export const disabledDate = (current) =>
  current && current < moment().endOf("day");

export const getJobStatusClassName = (job) => {
  if (job.is_closed) {
    return "closed-job";
  }
  if (moment().isAfter(job.expired_at) || moment().isAfter(job.closed_at)) {
    return "expired-job";
  }
  return "";
};

export const countryCodeModifier = (countryCode) => {
  const code = String(countryCode);
  if (!code || code.trim() === "") {
    return "";
  }
  return code.startsWith("+") ? code : `+${code}`;
};

export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;

export const convertPhoneNumberLinks = (phoneNumberString) => {
  if (!phoneNumberString) return null;
  const linkStyle = { display: "block" };
  const iconStyle = { marginRight: "0.5rem" };
  return phoneNumberString.split(",").map((rawPhoneNumber, index) => {
    const phoneNumber = rawPhoneNumber.trim();
    return (
      <a
        key={`${phoneNumber}-${index}`}
        href={`tel:${phoneNumber}`}
        style={linkStyle}
      >
        <i className="fas fa-phone fa-xs" style={iconStyle}></i>
        {phoneNumber}
      </a>
    );
  });
};

export const getErrorMessage = (message, context) => {
  const errorMessages = {
    network_error: context.t("Network Error!"),
    user_already_existed: context.t("User with this username already exists"),
    invalid_user: context.t(
      "You are trying to login with Business User Account!"
    ),
  };
  return (
    errorMessages[message] || context.t("There was an authentication problem")
  );
};

export const defaultSelectedGenders = ["M", "F", "X", "N"];

export const getRosterStatusMessage = (status, context) => {
  const statusMessages = {
    APPLIED: context.t("Applied"),
    APPLICABLE: context.t("Applicable"),
    NOT_APPLICABLE: context.t("Not Applicable"),
  };
  return statusMessages[status] || context.t("N/A");
};

export const ROSTER_STATUS_VARIANTS = {
  GREEN: "green",
  PURPLE: "purple",
  RED: "red",
};

export const checkShortListButton = (status) => {
  const nonShortlistStatuses = [
    "CONTRACT_SIGNED",
    "ACCEPTED",
    "OFFERED",
    "SHORTLISTED",
    "REJECTED_BY_BUSINESS",
    "REJECTED_BY_APPLICANT",
  ];
  return !nonShortlistStatuses.includes(status);
};

const statusMap = {
  ACCEPTED: { color: "green", text: "Offered" },
  APPLICANT_NA: { color: "brown", text: "N/A" },
  WAITLISTED: { text: "Waitlisted" },
  OFFERED: { color: "green", text: "Offered" },
  SHORTLISTED: { color: "green", text: "Shortlisted" },
  CONTRACT_SIGNED: { color: "green", text: "Contract Signed" },
  MOVED_GENERAL_WAITLIST: { color: "brown", text: "In general waitlist" },
};

export const designStatus = (context, status) => {
  const { color, text } = statusMap[status] || statusMap.APPLICANT_NA;
  return text ? (
    <span style={color ? { color } : undefined}>{context.t(text)}</span>
  ) : (
    status
  );
};
