import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Button, Modal } from "react-bootstrap";

export default function JobseekersCreateResumeHelpModal(props, context) {
  const language = useSelector((state) => state.i18nState.lang);

  const [bodyText, setBodyText] = useState(null);

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 30000);

    return () => clearTimeout(timer);
  }, []);

  const contentForMyanmar = (hotlineNumber) => {
    return (
      <>
        {context.t(
          "If you have any questions, for those who are in Thailand, please call {phoneLink}, and for those in Myanmar, please call or send message to Viber No. {viberLink} at anytime, 24 hours a day, 7 days a week.",
          {
            phoneLink: <a href={`tel: ${hotlineNumber}`}>{hotlineNumber}</a>,
            viberLink: <a href="tel: 09765000150">09765000150</a>,
          },
        )}
      </>
    );
  };

  const contentForKhmer = (hotlineNumber) => {
    return (
      <>
        {context.t(
          "If you have any questions, please call {phoneLink} (For those who are in Thailand) or direct message to Facebook page {facebookLink} at anytime, 24 hours a day, 7 days a week.",
          {
            phoneLink: <a href={`tel: ${hotlineNumber}`}>{hotlineNumber}</a>,
            facebookLink: (
              <a href="https://www.facebook.com/IssaraCambodia">
                ឥស្សរា កម្ពុជា Issara Cambodia
              </a>
            ),
          },
        )}
      </>
    );
  };

  const defaultBodyText = (hotlineNumber) => {
    return (
      <>
        {context.t(
          "If you have any questions, please call {phoneLink}.  The call is free of charge and you can call at anytime, 24 hours a day, 7 days a week.",
          { phoneLink: <a href={`tel: ${hotlineNumber}`}>{hotlineNumber}</a> },
        )}
      </>
    );
  };

  useEffect(() => {
    if (props.currentCountry != null) {
      switch (props.currentCountry) {
        case 2:
          setBodyText(contentForMyanmar(hotlineNumber(language)));
          break;
        case 3:
          setBodyText(contentForKhmer(hotlineNumber(language)));
          break;
        default:
          setBodyText(defaultBodyText(hotlineNumber(language)));
      }
    } else {
      if (language == "kh") {
        setBodyText(contentForKhmer(hotlineNumber(language)));
      } else {
        setBodyText(contentForMyanmar(hotlineNumber(language)));
      }
    }
  }, [props.currentCountry, language]);

  const handleClose = () => {
    setShow(false);
  };

  const hotlineNumber = (language) => {
    if (language == "kh") {
      return "18000 10 181";
    } else if (language == "ne") {
      return "977 9765415706 ";
    } else {
      return "18000 10 180";
    }
  };

  return (
    <div>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1rem" }}>
            {" "}
            <b>
              {context.t("Do you need help in completing this resume?")}
            </b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {context.t("Close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

JobseekersCreateResumeHelpModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
