import { updateObject } from "../Utility";
import {
  CONTACTS_GET_START,
  CONTACTS_GET_SUCCESS,
  CONTACTS_GET_FAIL,
} from "../actions/ContactsActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getContactsStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getContactsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.contacts.reduce((obj, item) => {
      obj[item.contact.id] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getContactsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const ContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case CONTACTS_GET_START:
      return getContactsStart(state, action);
    case CONTACTS_GET_SUCCESS:
      return getContactsSuccess(state, action);
    case CONTACTS_GET_FAIL:
      return getContactsFail(state, action);

    default:
      return state;
  }
};

export default ContactsReducer;
