import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment";
import { Online } from "react-detect-offline";

import { Container, Modal } from "react-bootstrap";

import HeroImage from "../../components/HeroImage";
import PollsQuestionModal from "../Polls/QuestionModal";
import JobseekersMenu from "./JobseekersMenu";
import AccountNavButton from "../../components/AccountNavButton";
import NotificationsModal from "./NotificationsModal";
import SurveyModal from "../Surveys/SurveyModal";
import { messaging } from "../../firebase";
import { getSurveys } from "../../common/redux/actions/SurveysActions";
import { getNotifications } from "../../common/redux/actions/NotificationsActions";

export default function JobseekersHomeView(props, context) {
  const dispatch = useDispatch();

  const language = useSelector((state) => state.i18nState.lang);
  const profile = useSelector((state) => state.ProfileState.data);
  const latestPoll = useSelector((state) => state.PollsState.latest);
  const surveys = useSelector((state) => state.SurveysState);
  const [userSurveys, setUserSurveys] = useState([]);

  const [showNotificationsModal, setShowNotificationsModal] = useState(false);
  const [surveyModal, setSurveyModal] = useState({
    survey: null,
    show: false,
  });

  const [showUpdateApp, setShowUpdateApp] = useState(false);

  const fetchSurveysByJobseekerId = async (jobseekerId, lang) => {
    const url = `${process.env.REACT_APP_API_V2_URL}/jobseekers/${jobseekerId}/surveys?lang=${lang}`;

    try {
      const response = await axios.get(url);
      if (response.data?.surveys?.length > 0) {
        setUserSurveys(response.data.surveys);
      }
      return response.data;
    } catch (error) {
      console.error(
        `Error fetching surveys for jobseeker ${jobseekerId}:`,
        error
      );
      throw error;
    }
  };

  const getBackgroundImage = () => {
    const images = {
      "mm-z": "/static/media/burmese-bg.jpg",
      "mm-u": "/static/media/burmese-bg.jpg",
      kh: "/static/media/khmer-bg.jpg",
      ne: "/static/media/nepal-bg.jpeg",
    };
    return images[language] || "/static/media/burmese-bg.jpg";
  };

  const getTitleImage = () => {
    const images = {
      "mm-z": "/static/media/burmese-title.png",
      "mm-u": "/static/media/burmese-title.png",
      kh: "/static/media/khmer-title.png",
      ne: "/static/media/nepal-title.png",
    };
    return images[language] || "/static/media/eng-title.png";
  };

  const rejectedLastPollQuestions = () => {
    const ask_poll = JSON.parse(
      localStorage.getItem(`ask_poll_user_${profile.id}`)
    );
    return (
      ask_poll?.latest_poll_id === latestPoll?.id && ask_poll?.dismiss === true
    );
  };

  useEffect(() => {
    if (messaging && profile.id && !profile.firebase_enabled) {
      const notifications = JSON.parse(localStorage.getItem("notifications"));
      if (
        notifications?.date &&
        moment().diff(notifications.date, "days") >= 7
      ) {
        setShowNotificationsModal(true);
      } else {
        setShowNotificationsModal(true);
      }
    }

    if (profile?.id && profile.type === "JS") {
      fetchSurveysByJobseekerId(profile.id, language);
      dispatch(getNotifications());
    }

    if (window.location.host === "goldendreams-production.appspot.com") {
      setShowUpdateApp(true);
    }

    if (!surveys.fetched) {
      dispatch(getSurveys());
    }
  }, [profile, language, surveys.fetched, dispatch]);

  useEffect(() => {
    const muted_surveys = JSON.parse(localStorage.getItem("muted_surveys"));
    const lastSurvey = surveys.items[surveys.items.length - 1];

    if (
      lastSurvey &&
      profile.type === "JS" &&
      !userSurveys.includes(lastSurvey.identifier)
    ) {
      const daysDifference = moment().diff(
        muted_surveys?.[lastSurvey.identifier],
        "days"
      );
      if (!muted_surveys?.[lastSurvey.identifier] || daysDifference >= 7) {
        setSurveyModal({ survey: lastSurvey, show: true });
      }
    }
  }, [surveys.items, profile.type, userSurveys]);

  return (
    <Container fluid={true} style={{ padding: 0 }}>
      <HeroImage height="180px" imageURL={getBackgroundImage()}>
        <img
          src={getTitleImage()}
          style={{ width: 200, marginTop: "-12px" }}
          alt=""
        />
      </HeroImage>
      <AccountNavButton />
      <Online>
        {profile.id &&
          latestPoll &&
          !profile?.voted_last_poll &&
          !rejectedLastPollQuestions() && <PollsQuestionModal />}
      </Online>

      {showUpdateApp && (
        <Modal show centered onHide={() => {}}>
          <Modal.Header style={{ justifyContent: "center" }}>
            <Modal.Title style={{ fontSize: "1rem" }}>
              <b>{context.t("Update required!")}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ margin: 0 }}>
              {context.t(
                "Please {clickHere} to update your app in Google Play Store. Close and reopen the app once completed.",
                {
                  clickHere: (
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=app_golden_dreams.org"
                    >
                      {context.t("click here")}
                    </a>
                  ),
                }
              )}
            </p>
          </Modal.Body>
        </Modal>
      )}

      <NotificationsModal show={showNotificationsModal} />
      <JobseekersMenu />
      <SurveyModal
        show={surveyModal.show}
        survey={surveyModal.survey}
        onClose={() => setSurveyModal({ identifier: null, show: false })}
      />
    </Container>
  );
}

JobseekersHomeView.contextTypes = {
  t: PropTypes.func.isRequired,
};
