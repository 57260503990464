import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import I18n from "redux-i18n";

import { FormControl, Button, Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

import { postPollsVote } from "../../common/redux/actions/PollsActions";

export default function QuestionModal(props, context) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.ProfileState.data);
  const polls = useSelector((state) => state.PollsState);
  const latestPoll = useSelector((state) => state.PollsState.latest);
  const [show, setShow] = useState(true);
  const [showPollQuestions, setShowPollQuestions] = useState(false);

  const handleClickVoteButton = (e) => {
    const payload = {
      question_id: polls.latest.id,
      choice_id: parseInt(e.target.value),
    };
    dispatch(postPollsVote(payload, true));
    setShowPollQuestions(false);
  };

  const handleRejectPollQuestions = (e) => {
    setShow(false);
    let ask_poll = { dismiss: true, latest_poll_id: latestPoll.id };
    localStorage.setItem(
      "ask_poll_user_" + profile.id,
      JSON.stringify(ask_poll),
    );
  };

  const handleAcceptPollQuestions = (e) => {
    setShow(false);
    setShowPollQuestions(true);
    localStorage.removeItem("ask_poll_user_" + profile.id);
  };

  return (
    <>
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title style={{ fontSize: "1rem" }}>
            {" "}
            <b>
              {context.t(
                "Would you like to participate in the poll with the topic?",
              )}
            </b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ margin: 0 }}> {polls.latest.description} </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleRejectPollQuestions()}
          >
            {context.t("I don't want to answer")}
          </Button>
          <Button variant="success" onClick={handleAcceptPollQuestions}>
            {context.t("I want to answer")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showPollQuestions}
        centered
        onHide={() => setShowPollQuestions(false)}
      >
        <Modal.Header style={{ justifyContent: "center" }}>
          <Modal.Title style={{ fontSize: "1rem" }}>
            {" "}
            <b>{context.t("Today's community poll")}</b>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <p style={{ margin: 0 }}> {polls.latest.description} </p>
        </Modal.Body>
        <Modal.Footer style={{ flexDirection: "column" }}>
          <div
            style={{
              display: "grid",
              gridGap: "5px",
              width: "-webkit-fill-available",
            }}
          >
            {Object.keys(polls.latest.choices).map((key, index) => {
              return (
                <Button
                  variant="outline-secondary"
                  key={index}
                  value={polls.latest.choices[key].id}
                  onClick={handleClickVoteButton}
                >
                  {" "}
                  {polls.latest.choices[key].description}{" "}
                </Button>
              );
            })}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

QuestionModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
