import React from "react";
import PropTypes from "prop-types";

// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, Col } from "react-bootstrap";

import BaseView from "./BaseView";

export default function LoginRequiredView(props, context) {
  const location = useLocation();

  return (
    <BaseView title={context.t("Protected view")}>
      <Col className="pt-5 afterTopNavigationBar">
        <h5> {context.t("Please login or register to continue")} </h5>
      </Col>
      <Col className="mt-4">
        <Link
          to={`/login${location.search}`}
          className="btn btn-info button-modified"
        >
          Login
        </Link>
      </Col>
      <Col className="mt-3">
        <Link to="/forgot-password">{context.t("Forgot Password?")}</Link>
        <br />
        <hr />
        <br />
        <Link to={`/registration/jobseeker${location.search}`}>
          <div>
            <h5> {context.t("New to golden dreams?")} </h5>
            <br />
            <Button className="btn btn-primary button-modified gd-button">
              {context.t("Click here to register?")}
            </Button>
          </div>
        </Link>
      </Col>
    </BaseView>
  );
}

LoginRequiredView.contextTypes = {
  t: PropTypes.func.isRequired,
};
