import { updateObject } from "../Utility";
import {
  SERVICE_PROVIDERS_GET_START,
  SERVICE_PROVIDERS_GET_SUCCESS,
  SERVICE_PROVIDERS_GET_FAIL,
  SERVICE_PROVIDERS_ADD_COMMENT,
  SERVICE_PROVIDERS_ADD_CHILD_COMMENT,
  SERVICE_PROVIDERS_RECALCULATE_SCORE,
} from "../actions/ServiceProvidersActions";

const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getServiceProvidersStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getServiceProvidersSuccess = (state, action) => {
  return updateObject(state, {
    items: action.serviceProviders,
    loading: false,
    fetched: true,
    error: null,
  });
};

const getServiceProvidersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

// INTERNAL:
const addServiceProvidersComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((serviceProvider) =>
      serviceProvider.id === action.comment.object_id
        ? // transform the one with a matching id
          {
            ...serviceProvider,
            comments_count: serviceProvider.comments_count + 1,
            comments: [...serviceProvider.comments, action.comment],
          }
        : // otherwise return original service provider
          serviceProvider,
    ),
    loading: false,
    error: null,
  });
};

const addServiceProvidersChildComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((serviceProvider) =>
      serviceProvider.id === action.comment.object_id
        ? // transform the one with a matching id
          {
            ...serviceProvider,
            comments_count: serviceProvider.comments_count + 1,
            comments: serviceProvider.comments.map((comment) =>
              comment.id === action.comment.base_parent
                ? {
                    ...comment,
                    children: [...comment.children, action.comment],
                  }
                : comment,
            ),
          }
        : // otherwise return original service provider
          serviceProvider,
    ),
    loading: false,
    error: null,
  });
};

const recalculateServiceProviderScore = (state, action) => {
  return updateObject(state, {
    items: state.items.map((serviceProvider) =>
      serviceProvider.id === action.service_provider_id
        ? // transform the one with a matching id
          {
            ...serviceProvider,
            rating_count:
              serviceProvider.rating_count + (action.update ? 0 : 1),
            rating_score_count:
              serviceProvider.rating_score_count + action.offsetCount,
            rating_score:
              (serviceProvider.rating_score *
                serviceProvider.rating_score_count +
                action.offsetScore) /
              (serviceProvider.rating_score_count + action.offsetCount),
            scores_for_each_criteria:
              serviceProvider.scores_for_each_criteria.map((item) => {
                if (action.detailed_scores[item.rating_criteria_id]) {
                  const newScore =
                    action.detailed_scores[item.rating_criteria_id];
                  if (newScore.updated) {
                    return {
                      ...item,
                      score:
                        (item.score * item.count + newScore.offset) /
                        item.count,
                    };
                  } else {
                    return {
                      ...item,
                      score:
                        (item.score * item.count + newScore.score) /
                        (item.count + 1),
                      count: item.count + 1,
                    };
                  }
                } else return item;
              }),
          }
        : // otherwise return original todo
          serviceProvider,
    ),
    loading: false,
    error: null,
  });
};

const ServiceProvidersReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case SERVICE_PROVIDERS_GET_START:
      return getServiceProvidersStart(state, action);
    case SERVICE_PROVIDERS_GET_SUCCESS:
      return getServiceProvidersSuccess(state, action);
    case SERVICE_PROVIDERS_GET_FAIL:
      return getServiceProvidersFail(state, action);

    // OTHER:
    case SERVICE_PROVIDERS_ADD_COMMENT:
      return addServiceProvidersComment(state, action);
    case SERVICE_PROVIDERS_ADD_CHILD_COMMENT:
      return addServiceProvidersChildComment(state, action);
    case SERVICE_PROVIDERS_RECALCULATE_SCORE:
      return recalculateServiceProviderScore(state, action);

    default:
      return state;
  }
};

export default ServiceProvidersReducer;
