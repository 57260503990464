import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// import { connect } from 'react-redux';
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroller";
import Autosuggest from "react-autosuggest";

import moment from "moment";

import BaseView from "../BaseView";
import CommonContentListItem from "../../components/CommonContentListItem";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getGuides } from "../../common/redux/actions/GuidesActions";
import { getGuideCategories } from "../../common/redux/actions/GuideCategoriesActions";
import { getGuideSubcategories } from "../../common/redux/actions/GuideSubcategoriesActions";
import {
  setFilter,
  setSort,
  setSearch,
} from "../../common/redux/actions/FiltersSortersActions";

import {
  reactSelectDropdownStyleLeft,
  reactSelectDropdownStyleRight,
  groupedStyle,
  groupedBadgeStyle,
} from "../../common/styles/dropdowns";

const fuzzysort = require("fuzzysort");
const Rabbit = require("rabbit-node");

const formatGroupLabel = (data) => (
  <div style={groupedStyle}>
    <span>{data.label}</span>
  </div>
);

export default function GuidesListView(props, context) {
  const dispatch = useDispatch();

  const countries = useSelector((state) => state.CountriesState);
  const guides = useSelector((state) => state.GuidesState);
  const guideCategories = useSelector((state) => state.GuideCategoriesState);
  const guideSubcategories = useSelector(
    (state) => state.GuideSubcategoriesState,
  );
  const profile = useSelector((state) => state.ProfileState);
  const language = useSelector((state) => state.i18nState.lang);

  // Filters and sorters
  const filters = useSelector(
    (state) => state.FiltersSortersState.filters.guides,
  );
  const sort = useSelector((state) => state.FiltersSortersState.sort.guides);
  const search = useSelector(
    (state) => state.FiltersSortersState.search.guides,
  );

  // React select dropdown options
  const [groupedSubcategoriesOptions, setGroupedSubcategoriesOptions] =
    useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);

  // state
  const [componentState, setComponentState] = useState({
    filteredGuides: [],
    infiniteGuides: [],
    currentPage: 1,
    delayedSearch: search,
  });
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [filteringResults, setFilteringResults] = useState(true);

  // references
  const categoryRef = useRef();

  // call necessary API
  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
    !guides.fetched && dispatch(getGuides());
    !guideCategories.fetched && dispatch(getGuideCategories());
    !guideSubcategories.fetched && dispatch(getGuideSubcategories());
  }, []);

  // populate react select countries options
  useEffect(() => {
    if (countries.fetched && guides.fetched) {
      let availableCountries = new Set();
      guides.items.map((guide) => {
        availableCountries = new Set([
          ...availableCountries,
          ...guide.countries,
        ]);
      });

      let countriesArray = [];
      for (let key in countries.items) {
        if (
          [...availableCountries].indexOf(countries.items[key].country) >= 0
        ) {
          countriesArray.push(countries.items[key]);
        }
      }

      countriesArray = [
        { value: 0, label: context.t("All countries") },
        ...countriesArray
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((item) => {
            return { value: item.country, label: item.name };
          }),
      ];
      setCountriesOptions(countriesArray);
    }
  }, [countries.items, guides.items]);

  // pre-select country filter based on country of residence in the profile
  useEffect(() => {
    if (countries.items[profile.data.country] && filters.useDefaultCountry) {
      if (
        countriesOptions.filter(
          (country) => country.value === profile.data.country,
        )[0]
      ) {
        // dispatch(setFilter('guides', 'country', profile.data.country))
        // this dirty should consider in the future
        dispatch(setFilter("guides", "country", 0));
      }
    }
  }, [countries.items, profile, countriesOptions]);

  // populate react select categories options
  useEffect(() => {
    if (
      guideCategories.fetched &&
      guideSubcategories.fetched &&
      guides.fetched
    ) {
      let availableSubCategories = new Set();
      guides.items.map((guide) => {
        availableSubCategories = new Set([
          ...availableSubCategories,
          ...guide.categories,
        ]);
      });

      let groupedSubcategories = {
        0: { value: 0, label: context.t("All categories") },
      };

      Object.keys(guideCategories.items)
        .sort((a, b) =>
          guideCategories.items[a].name > guideCategories.items[b].name
            ? 1
            : -1,
        )
        .map(
          (key) =>
            (groupedSubcategories[guideCategories.items[key].id] = {
              label: guideCategories.items[key].name,
              options: [],
            }),
        );

      Object.keys(guideSubcategories.items)
        .sort((a, b) =>
          guideSubcategories.items[a].name > guideSubcategories.items[b].name
            ? 1
            : -1,
        )
        .map((key) => {
          groupedSubcategories[guideSubcategories.items[key].category_id] &&
            [...availableSubCategories].indexOf(
              guideSubcategories.items[key].id,
            ) >= 0 &&
            groupedSubcategories[
              guideSubcategories.items[key].category_id
            ].options.push({
              value: guideSubcategories.items[key].id,
              label: guideSubcategories.items[key].name,
            });
        });

      let groupedSubcategoriesArray = [];
      for (let key in groupedSubcategories) {
        groupedSubcategoriesArray.push(groupedSubcategories[key]);
      }
      setGroupedSubcategoriesOptions(groupedSubcategoriesArray);
    }
  }, [guideCategories.items, guideSubcategories.items, guides.items]);

  // filter and sort guides
  useEffect(() => {
    if (guides.fetched) {
      filterAndSortGuides(guides.items);
    }
  }, [guides.items, sort, filters, search]);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        dispatch(setSearch("guides", componentState.delayedSearch));
      }, 450),
    );
  }, [componentState.delayedSearch]);

  const handleSort = (data, event) => {
    const value = data.value;
    dispatch(setSort("guides", value));
  };

  const handleSearch = (event) => {
    setComponentState({
      ...componentState,
      delayedSearch: event.target.value,
    });
  };

  const handleFilter = (data, event) => {
    const name = event.name;
    const value = data.value;
    dispatch(setFilter("guides", name, value));

    if (filters.useDefaultCountry && name === "country") {
      dispatch(setFilter("guides", "useDefaultCountry", false));
    }
  };

  const filterBySearch = (guides, search) => {
    if (language === "mm-z") {
      search = Rabbit.zg2uni(search);
    }

    const results = fuzzysort.go(search, guides, {
      keys: ["title", "title_mm_uni"],
      allowTypo: true,
    });
    return results.map((result) => result.obj);
  };

  const filterByCategory = (guides, category) => {
    return guides.filter((guide) => guide.categories.indexOf(category) >= 0);
  };

  const filterByCountry = (guides, country) => {
    return guides.filter((guide) => guide.countries.indexOf(country) >= 0);
  };

  const filterAndSortGuides = (guides) => {
    setFilteringResults(true);

    var result = [...guides];

    // Filtering:
    if (search) {
      const searchString = search.toLowerCase();
      result = filterBySearch(result, searchString);
    }

    if (filters.category) {
      result = filterByCategory(result, filters.category);
    }

    if (filters.country) {
      result = filterByCountry(result, filters.country);
    }

    // Sorting:
    if (sort === "Date: Newest") {
      result.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
    } else if (sort === "Date: Oldest") {
      result.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    } else if (sort === "Popularity") {
      result.sort((a, b) => {
        if (a.comments_count || b.comments_count) {
          return b.comments_count - a.comments_count;
        }
        return a.title > b.title ? 1 : -1;
      });
    }

    if (
      JSON.stringify(componentState.filteredGuides) !== JSON.stringify(result)
    ) {
      setComponentState({
        ...componentState,
        filteredGuides: result,
        infiniteGuides: result.slice(0, 10),
      });
    }

    setFilteringResults(false);
  };

  const loadMoreGuides = () => {
    const page = componentState.currentPage + 1;
    setComponentState({
      ...componentState,
      currentPage: page,
      infiniteGuides: componentState.filteredGuides.slice(0, page * 10),
    });
  };

  const unfocusInput = (e, ref) => {
    e.preventDefault();
    ref.current.focus();
    ref.current.blur();
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.title;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <div>{suggestion.title}</div>;

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method },
  ) => {
    props.history.push(`/guides/${suggestion.id}`);
  };

  return (
    <BaseView title={context.t("Guides")} backurl="/">
      <hr className="afterTopNavigationBar"></hr>
      {guides.loading ||
      guideCategories.loading ||
      guideSubcategories.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container>
          <Row>
            <Col xs={6} style={{ textAlign: "left" }}>
              <Select
                name="category"
                ref={categoryRef}
                placeholder={context.t("Category")}
                defaultValue={{
                  value: filters.category,
                  label:
                    filters.category === 0
                      ? context.t("All categories")
                      : guideSubcategories.items[filters.category].name,
                }}
                options={groupedSubcategoriesOptions}
                formatGroupLabel={formatGroupLabel}
                onChange={handleFilter}
                isSearchable={false}
                styles={reactSelectDropdownStyleLeft}
                maxMenuHeight={500}
              />
            </Col>
            <Col xs={6} style={{ textAlign: "left" }}>
              <Select
                name="country"
                placeholder={context.t("Country")}
                value={{
                  value: filters.country,
                  label:
                    filters.country === 0
                      ? context.t("All countries")
                      : countries.items[filters.country].name,
                }}
                options={countriesOptions}
                onChange={handleFilter}
                isSearchable={false}
                styles={reactSelectDropdownStyleRight}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <p className="found-counter">
              {" "}
              {context.t("{number} found", {
                number: componentState.filteredGuides.length,
              })}{" "}
            </p>
            <Col>
              <form onSubmit={(e) => unfocusInput(e, categoryRef)}>
                <Autosuggest
                  suggestions={componentState.filteredGuides.slice(0, 5)}
                  onSuggestionSelected={onSuggestionSelected}
                  onSuggestionsFetchRequested={() => {}}
                  onSuggestionsClearRequested={() => {}}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={{
                    placeholder: context.t("Search"),
                    value: componentState.delayedSearch,
                    onChange: handleSearch,
                  }}
                />
              </form>
            </Col>
            <Col xs={6} style={{ textAlign: "left" }}>
              <Select
                name="sort"
                placeholder={context.t("Sort")}
                defaultValue={{ value: sort, label: context.t(sort) }}
                options={[
                  { value: "Date: Newest", label: context.t("Date: Newest") },
                  { value: "Date: Oldest", label: context.t("Date: Oldest") },
                  { value: "Popularity", label: context.t("Popularity") },
                ]}
                onChange={handleSort}
                isSearchable={false}
                styles={reactSelectDropdownStyleRight}
              />
            </Col>
          </Row>
          <InfiniteScroll
            loadMore={loadMoreGuides}
            hasMore={
              componentState.infiniteGuides.length <
              componentState.filteredGuides.length
            }
            loader={
              <div className="loader" key={0}>
                {" "}
                {context.t("Loading ...")}{" "}
              </div>
            }
          >
            <div className="mt-5">
              {componentState.infiniteGuides.map((item, i) => {
                return (
                  <CommonContentListItem
                    key={i}
                    id={item.id}
                    translation_id={item.translation_id}
                    type="guides"
                    title={item.title}
                    image={item.image}
                    video={item.video}
                    audio={item.audio}
                    created_at={moment(item.created_at).format("LL")}
                    comments_count={item.comments_count}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
          {!filteringResults && componentState.filteredGuides.length === 0 && (
            <FilterResultsNotFound
              text={context.t(
                "Sorry, no guides matched filter parameters, please try again.",
              )}
            />
          )}
        </Container>
      )}
    </BaseView>
  );
}

GuidesListView.contextTypes = {
  t: PropTypes.func.isRequired,
};
