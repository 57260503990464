import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-mobile-datepicker";

const CustomDatePicker = (props, context) => {
  const monthMap = {
    1: context.t("Jan"),
    2: context.t("Feb"),
    3: context.t("Mar"),
    4: context.t("Apr"),
    5: context.t("May"),
    6: context.t("Jun"),
    7: context.t("Jul"),
    8: context.t("Aug"),
    9: context.t("Sep"),
    10: context.t("Oct"),
    11: context.t("Nov"),
    12: context.t("Dec"),
  };

  const dateConfig = {
    month: {
      format: (value) => monthMap[value.getMonth() + 1],
      caption: "Month",
      step: 1,
    },
    date: {
      format: "DD",
      caption: "Day",
      step: 1,
    },
    year: {
      format: "YYYY",
      caption: "Year",
      step: 1,
    },
  };

  return (
    <DatePicker
      value={props.value}
      isOpen={props.isOpen}
      onSelect={props.onSelect}
      onCancel={props.onCancel}
      theme="ios"
      showHeader={false}
      dateConfig={dateConfig}
      min={new Date(1950, 0, 1)}
      max={new Date(2022, 0, 1)}
      confirmText={context.t("Save")}
      cancelText={context.t("Back")}
    />
  );
};

CustomDatePicker.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default CustomDatePicker;
