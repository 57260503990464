import React from "react";
import { Container, Image } from "react-bootstrap";

const UnderConstruction = () => {
  return (
    <Container className="afterTopNavigationBar pt-4">
      <Image
        src="/images/UnderConstruction.jpg"
        alt="Page is under construction"
        style={{ height: "50vh" }}
      />
    </Container>
  );
};

export default UnderConstruction;
