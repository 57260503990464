import { updateObject } from "../Utility";
import {
  SURVEYS_GET_START,
  SURVEYS_GET_SUCCESS,
  SURVEYS_GET_FAIL,
  SURVEYS_ADMIN_GET_START,
  SURVEYS_ADMIN_GET_SUCCESS,
  SURVEYS_ADMIN_GET_FAIL,
} from "../actions/SurveysActions";

const initialState = {
  items: [],
  loading: false,
  fetched: false,
  error: null,
  participation: {
    loading: false,
    error: false,
  },
};

// GET:
const getSurveysStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getSurveysSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.surveys,
    loading: false,
    fetched: true,
    error: null,
  });
};

const getSurveysFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const postSurveyParticipationStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    participation: {
      loading: true,
      error: null,
    },
  });
};

const postSurveyParticipationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    participation: {
      loading: false,
      error: null,
    },
  });
};

const postSurveyParticipationFail = (state, action) => {
  return updateObject(state, {
    ...state,
    participation: {
      loading: false,
      error: action.error,
    },
  });
};

const SurveysReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case SURVEYS_GET_START:
      return getSurveysStart(state, action);
    case SURVEYS_GET_SUCCESS:
      return getSurveysSuccess(state, action);
    case SURVEYS_GET_FAIL:
      return getSurveysFail(state, action);

    case SURVEYS_ADMIN_GET_START:
      return getSurveysStart(state, action);
    case SURVEYS_ADMIN_GET_SUCCESS:
      return getSurveysSuccess(state, action);
    case SURVEYS_ADMIN_GET_FAIL:
      return getSurveysFail(state, action);

    default:
      return state;
  }
};

export default SurveysReducer;
