import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import {
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import Select from "react-select";

import BaseView from "../BaseView";
import JobListItem from "../../components/JobListItem";

import { getCities } from "../../common/redux/actions/CitiesActions";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getCurrencies } from "../../common/redux/actions/CurrenciesActions";
import { getJobs } from "../../common/redux/actions/JobsActions";
import { getIndustries } from "../../common/redux/actions/IndustriesActions";
import { getSubIndustries } from "../../common/redux/actions/SubIndustriesActions";
import { getEmployers } from "../../common/redux/actions/EmployersActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import {
  setFilter,
  setSort,
  setSearch,
  setScrollToIndex,
} from "../../common/redux/actions/FiltersSortersActions";

import {
  reactSelectDropdownStyleLeft,
  reactSelectDropdownStyleRight,
  groupedStyle,
  groupedBadgeStyle,
} from "../../common/styles/dropdowns";

const fuzzysort = require("fuzzysort");
const Rabbit = require("rabbit-node");

const cache = new CellMeasurerCache({
  minHeight: 112,
  fixedWidth: true,
});

export default function FilteredJobsByEmployerView(props, context) {
  const dispatch = useDispatch();

  const cities = useSelector((state) => state.CitiesState);
  const countries = useSelector((state) => state.CountriesState);
  const currencies = useSelector((state) => state.CurrenciesState);
  const jobs = useSelector((state) => state.JobsState);
  const industries = useSelector((state) => state.IndustriesState);
  const language = useSelector((state) => state.i18nState.lang);
  const subindustries = useSelector((state) => state.SubIndustriesState);
  const employers = useSelector((state) => state.EmployersState);
  const states = useSelector((state) => state.StatesState);

  // Filters and sorters
  const filters = useSelector(
    (state) => state.FiltersSortersState.filters.jobs,
  );
  const sort = useSelector((state) => state.FiltersSortersState.sort.jobs);
  const search = useSelector((state) => state.FiltersSortersState.search.jobs);
  const scrollToIndex = useSelector(
    (state) => state.FiltersSortersState.scrollToIndex.jobs,
  );

  // React select dropdown options
  const [groupedSubIndustriesOptions, setGroupedSubIndustriesOptions] =
    useState([]);

  // references
  const resultsRef = useRef();
  const listRef = useRef();

  const formatGroupLabel = (data) => (
    <div style={groupedStyle}>
      <span>{data.label}</span>
    </div>
  );

  const handleFilter = (data, event) => {
    const name = event.name;
    const value = data.value;
    dispatch(setFilter("jobs", name, value));
  };

  const [jobsFiltered, setJobsFiltered] = useState([]);
  const [filteringResults, setFilteringResults] = useState(false);
  const [delayedSearch, setDelayedSearch] = useState(search);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    !cities.fetched && !cities.loading && dispatch(getCities());
    !countries.fetched && !countries.loading && dispatch(getCountries());
    !currencies.fetched && !currencies.loading && dispatch(getCurrencies());
    !jobs.fetched && !jobs.fetching && dispatch(getJobs());
    !industries.fetched && !industries.loading && dispatch(getIndustries());
    !subindustries.fetched &&
      !subindustries.loading &&
      dispatch(getSubIndustries());
    !employers.fetched && dispatch(getEmployers());
    !states.fetched && !states.loading && dispatch(getStates());
  }, []);

  useEffect(() => {
    cache.clearAll();
    listRef.current && listRef.current.forceUpdateGrid();
  }, [jobsFiltered]);

  const rowRenderer = ({ index, isScrolling, key, parent, style }) => {
    return (
      <CellMeasurer
        cache={cache}
        key={key}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          // 'style' attribute required to position cell (within parent List)
          <div ref={registerChild} style={style}>
            <JobListItem job={jobsFiltered[index]} scrollToIndex={index} link />
          </div>
        )}
      </CellMeasurer>
    );
  };

  const filterByIndustry = (jobs, industry) => {
    return jobs.filter((job) => {
      return job.employer_industry === industry;
    });
  };

  const filterByEmployer = (jobs, employer) => {
    return jobs.filter((job) => {
      return job.employer === employer;
    });
  };

  // filter and sort jobs
  useEffect(() => {
    if (jobs.fetched) {
      filterAndSortJobs(jobs.items);
    }
  }, [jobs.items, sort, filters, search]);

  const filterBySearch = (jobs, search) => {
    if (language === "mm-z") {
      search = Rabbit.zg2uni(search);
    }

    const results = fuzzysort.go(search, jobs, {
      keys: [
        "employer_name",
        ["translation", "title"],
        ["translation", "title_mm_uni"],
      ],
      allowTypo: true,
    });
    return results.map((result) => result.obj);
  };

  const filterAndSortJobs = (jobs) => {
    setFilteringResults(true);

    var result = [...jobs];

    // Filtering:
    if (search) {
      const searchString = search.toLowerCase();
      result = filterBySearch(result, searchString);
    }

    if (props.match.params.id) {
      let employerId = parseInt(props.match.params.id);
      result = filterByEmployer(jobs, employerId);
    }

    if (filters.employer_industry != 0) {
      result = filterByIndustry(jobs, filters.employer_industry);
    }

    // Sorting:
    if (sort === "Posted: Newest") {
      result.sort((a, b) => {
        return new Date(b.posted_at) - new Date(a.posted_at);
      });
    } else if (sort === "Posted: Oldest") {
      result.sort((a, b) => {
        return new Date(a.posted_at) - new Date(b.posted_at);
      });
    }

    if (JSON.stringify(jobsFiltered) !== JSON.stringify(result)) {
      setJobsFiltered(result);
    }

    setFilteringResults(false);
  };

  const handleSort = (data, event) => {
    const value = data.value;
    dispatch(setSort("jobs", value));
  };

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        dispatch(setSearch("jobs", delayedSearch));
      }, 450),
    );
  }, [delayedSearch]);

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.translation.title;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => (
    <div>{suggestion.translation.title}</div>
  );

  // populate react select industries options
  useEffect(() => {
    if (
      industries.fetched &&
      subindustries.fetched &&
      employers.fetched &&
      jobs.fetched
    ) {
      let availableSubIndustries = new Set();
      employers.items.map((employer) => {
        availableSubIndustries = new Set([
          ...availableSubIndustries,
          ...employer.sub_industries,
        ]);
      });

      let groupedSubIndustries = {
        0: { value: 0, label: context.t("All industries") },
      };
      Object.keys(industries.items)
        .sort((a, b) =>
          industries.items[a].name > industries.items[b].name ? 1 : -1,
        )
        .map(
          (key) =>
            (groupedSubIndustries[industries.items[key].industry] = {
              label: industries.items[key].name,
              options: [],
            }),
        );

      Object.keys(subindustries.items)
        .sort((a, b) =>
          subindustries.items[a].name > subindustries.items[b].name ? 1 : -1,
        )
        .map((key) => {
          groupedSubIndustries[subindustries.items[key].industry_id] &&
            [...availableSubIndustries].indexOf(subindustries.items[key].id) >=
              0 &&
            groupedSubIndustries[
              subindustries.items[key].industry_id
            ].options.push({
              value: subindustries.items[key].id,
              label: subindustries.items[key].name,
            });
        });

      let groupedSubIndustriesArray = [];
      for (let key in groupedSubIndustries) {
        groupedSubIndustriesArray.push(groupedSubIndustries[key]);
      }
      setGroupedSubIndustriesOptions(groupedSubIndustriesArray);
    }
  }, [industries.items, subindustries.items, employers.items, jobs.items]);

  return (
    <BaseView title={context.t("Jobs")} backurl="/marketplace">
      <hr className="afterTopNavigationBar"></hr>
      {jobs.fetching ||
      industries.loading ||
      subindustries.loading ||
      currencies.loading ||
      cities.loading ||
      countries.loading ||
      states.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <>
          <Container>
            <Row className="mt-2" style={{ marginBottom: "0.7rem" }}>
              <p className="found-counter">
                {" "}
                {context.t("{number} found", {
                  number: jobsFiltered.length,
                })}{" "}
              </p>
              <Col style={{ textAlign: "left" }}>
                <Select
                  name="employer_industry"
                  placeholder={context.t("Industry")}
                  defaultValue={{
                    value: filters.employer_industry,
                    label:
                      filters.employer_industry === 0
                        ? context.t("All industries")
                        : subindustries.items[filters.employer_industry].name,
                  }}
                  options={groupedSubIndustriesOptions}
                  formatGroupLabel={formatGroupLabel}
                  onChange={handleFilter}
                  isSearchable={false}
                  styles={reactSelectDropdownStyleLeft}
                  maxMenuHeight={500}
                />
              </Col>
              <Col xs={6} style={{ textAlign: "left" }}>
                <Select
                  name="sort"
                  placeholder={context.t("Sort")}
                  defaultValue={{ value: sort, label: context.t(sort) }}
                  options={[
                    {
                      value: "Posted: Newest",
                      label: context.t("Posted: Newest"),
                    },
                    {
                      value: "Posted: Oldest",
                      label: context.t("Posted: Oldest"),
                    },
                    // {value:'Most Popular', label: context.t('Most Popular')},
                    // {value:'Most Applied', label: context.t('Most Applied')},
                  ]}
                  onChange={handleSort}
                  isSearchable={false}
                  styles={reactSelectDropdownStyleRight}
                />
              </Col>
            </Row>
          </Container>

          <AutoSizer disableHeight style={{ marginTop: 15 }}>
            {({ width }) => (
              <List
                className="withoutScrollBar"
                ref={listRef}
                height={560}
                width={width}
                rowCount={jobsFiltered.length}
                overscanRowCount={10}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                scrollToIndex={scrollToIndex}
                scrollToAlignment="start"
                deferredMeasurementCache={cache}
              />
            )}
          </AutoSizer>
        </>
      )}
    </BaseView>
  );
}

FilteredJobsByEmployerView.contextTypes = {
  t: PropTypes.func.isRequired,
};
