import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const RATING_CRITERIES_GET_START = "RATING_CRITERIES_GET_START";
export const RATING_CRITERIES_GET_SUCCESS = "RATING_CRITERIES_GET_SUCCESS";
export const RATING_CRITERIES_GET_FAIL = "RATING_CRITERIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getRatingCriteriesStart = () => {
  return {
    type: RATING_CRITERIES_GET_START,
  };
};

export const getRatingCriteriesSuccess = (ratingCriteries) => {
  return {
    type: RATING_CRITERIES_GET_SUCCESS,
    ratingCriteries: ratingCriteries,
  };
};

export const getRatingCriteriesFail = (error) => {
  return {
    type: RATING_CRITERIES_GET_FAIL,
    error: error,
  };
};

export const getRatingCriteries = () => {
  return (dispatch, getState) => {
    dispatch(getRatingCriteriesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/rating-criteries?lang=${language}`)
      .then((res) => {
        dispatch(getRatingCriteriesSuccess(res.data));
        return getState().RatingCriteriesState.items;
      })
      .catch((err) => {
        dispatch(getRatingCriteriesFail(err));
        throw err;
      });
  };
};
