import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import BaseView from "./BaseView";
import JobseekersProfileView from "./Jobseekers/JobseekersProfileView";
import IssaraStaffProfileView from "./IssaraStaff/IssaraStaffProfileView";
import { authLogout } from "../common/redux/actions/AuthenticationActions";

const ProfileView = (props, context) => {
  const profile = () => {
    if (props.profile.type === "JS") {
      return <JobseekersProfileView {...props} />;
    } else if (props.profile.type === "IS") {
      return <IssaraStaffProfileView {...props} />;
    }
  };

  return (
    <BaseView title={context.t("Profile")} backurl="/">
      <hr className="afterTopNavigationBar"></hr>
      {profile()}
    </BaseView>
  );
};

ProfileView.contextTypes = {
  t: PropTypes.func.isRequired,
};

ProfileView.propTypes = {
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
    isAuthenticated: state.AuthenticationState.token !== null,
    profile: state.ProfileState.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authLogout: () => dispatch(authLogout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
