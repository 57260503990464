import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { langaugeSkills } from "../../common/utilities/utilities";

export default function LanguageSkillSelect(props, context) {
  const [currentSelected, setCurrentSelected] = useState([]);
  const options = langaugeSkills(context, props.languages);

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeLanguage = (option) => {
    setSelectedOption(option);
    if (props.onChange) {
      props.onChange(option.value);
    }
  };

  useEffect(() => {
    if (props.currentSelectedLanguages.length > 0) {
      setCurrentSelected(
        props.currentSelectedLanguages.map((selected) => {
          if (selected.language != "") {
            return selected.language;
          }
        }),
      );
    } else {
      setCurrentSelected([]);
    }
  }, [props.currentSelectedLanguages]);

  useEffect(() => {
    setSelectedOption(props.initialValue);
  }, [props.initialValue]);

  return (
    <div>
      <Select
        rules={{ required: "Please select an option" }}
        name="language"
        onChange={handleChangeLanguage}
        options={options.filter(
          (option) => !currentSelected.includes(option.value),
        )}
        value={options.filter((option) => option.value == selectedOption)}
      />
    </div>
  );
}

LanguageSkillSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};
