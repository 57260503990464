import { updateObject } from "../Utility";
import {
  GUIDE_SUBCATEGORIES_GET_START,
  GUIDE_SUBCATEGORIES_GET_SUCCESS,
  GUIDE_SUBCATEGORIES_GET_FAIL,
} from "../actions/GuideSubcategoriesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getGuideSubcategoriesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getGuideSubcategoriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.GuideSubcategories.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getGuideSubcategoriesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const GuideSubcategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case GUIDE_SUBCATEGORIES_GET_START:
      return getGuideSubcategoriesStart(state, action);
    case GUIDE_SUBCATEGORIES_GET_SUCCESS:
      return getGuideSubcategoriesSuccess(state, action);
    case GUIDE_SUBCATEGORIES_GET_FAIL:
      return getGuideSubcategoriesFail(state, action);

    default:
      return state;
  }
};

export default GuideSubcategoriesReducer;
