import React, { Component } from "react";

import BaseView from "./BaseView";

class LikesView extends Component {
  render() {
    return (
      <BaseView title="Likes" backurl="/" underConstruction>
        <p style={{ marginTop: 52 }}> I'm likes page </p>
      </BaseView>
    );
  }
}

export default LikesView;
