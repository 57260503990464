import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, Col, Image } from "react-bootstrap";
import { Online } from "react-detect-offline";
import moment from "moment";
import CommentFlagModal from "./CommentFlagModal";
import Dropdown from "react-bootstrap/Dropdown";
import { addDefaultImageSrc, urlify } from "../common/utilities/utilities";
import axios from "axios";
import {
  deleteComment,
  hideComment,
} from "../common/redux/actions/CommentHideDeleteAction";
import ReactionIcons from "./ReactionIcons";
import styles from "./CommentReplyStyle";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <sapn className="threedots"></sapn>
  </a>
));

class CommentReply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      reactionSet: null,
      is_hide: props.comment.is_hide,
    };
  }

  handleClickReply = () => {
    const replyPayload = {
      text: this.props.comment.text,
      parent: this.props.comment.id,
      base_parent: this.props.comment.base_parent,
      username: this.props.comment.sender_username,
      isModerator: this.props.comment.sender_is_moderator,
    };
    this.props.onClickReply(replyPayload);
  };

  handleSubmitReply = (payload) => {
    this.props.onSubmitReply(payload);
    this.setState({
      ...this.state,
      showInput: false,
    });
  };

  showRec = () => {
    if (
      this.props.comment.id &&
      document.getElementById(this.props.comment.id.toString()).classList
        .value === "invisible"
    ) {
      document
        .getElementById(this.props.comment.id.toString())
        .classList.remove("invisible");
    }
    document
      .getElementById(this.props.comment.id.toString())
      .classList.add("visible");
  };

  addRec = async (e) => {
    e.preventDefault();
    this.setReaction(e.target.id.split("-"));
    if (e.target.id.split("-")[1] === this.state.reactionSet.reaction_type) {
      document
        .getElementById(
          this.props.comment.id.toString() + "-" + e.target.id.split("-")[1],
        )
        .classList.remove(e.target.id.split("-")[1] + "2");
    }
  };

  deleteHideHandler(id, state) {
    let { dispatch } = this.props;
    if (state == undefined) {
      dispatch(hideComment(id, state));
      dispatch(deleteComment(id));
    } else {
      dispatch(hideComment(id, state));
    }
  }

  getReactions = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/comment-reaction-update?id=${this.props.comment.id}&type=comment`,
      )
      .then((res) => {
        this.setState({
          ...this.state,
          reactionSet: res.data,
        });
      })
      .catch((err) => console.log("viewed recently"));
  };

  handleReaction = async (type, id) => {
    if (
      this.state.reactionSet.reaction_type !== null &&
      this.state.reactionSet.reaction_type !== undefined
    ) {
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/comment-reaction-update?type=comment`,
          { comment: id, reaction_type: type },
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/comment-reaction?type=comment`,
          { comment: id, reaction_type: type },
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.props?.profile?.id && this.getReactions();
  };

  makeReaction = (e, type) => {
    e.preventDefault();
    this.handleReaction(type, this.props.comment.id);
  };

  setReaction = async (reaction_type) => {
    let localauth = JSON.parse(window.localStorage.getItem("auth"));
    if (localauth !== null) {
      if (
        this.state.reactionSet.reaction_type !== null &&
        this.state.reactionSet.reaction_type !== undefined
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/comment-reaction-update?type=comment`,
            { comment: reaction_type[0], reaction_type: reaction_type[1] },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/comment-reaction?type=comment`,
            { comment: reaction_type[0], reaction_type: reaction_type[1] },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      window.location = "/login";
    }
    this.props?.profile?.id && this.getReactions();
  };

  componentDidMount() {
    this.props?.profile?.id && this.getReactions();
  }
  render() {
    return (
      <div>
        {this.props.profile.type == "IS" ? (
          <div style={{ marginLeft: "40px" }}>
            <hr style={{ ...styles.rowdesign }} />
            <Row>
              <Col xs={2}>
                <Image
                  src={
                    this.props.comment.sender_is_moderator
                      ? "/static/media/moderator.png"
                      : this.props.comment.sender_avatar ||
                        "/static/media/astronaut.png"
                  }
                  crossOrigin="anonymous"
                  style={{ ...styles.senderavatar }}
                  onError={addDefaultImageSrc}
                />
              </Col>
              <Col xs={10}>
                <div style={{ ...styles.commentreplay }}>
                  <div>
                    <h5
                      style={{
                        color:
                          this.props.comment.sender_is_moderator && "#198474",
                      }}
                    >
                      {this.props.comment.sender_is_moderator
                        ? this.context.t("moderator")
                        : this.props.comment.sender_username ||
                          this.context.t("Anonymous")}
                      {this.props.comment.sender_is_moderator && (
                        <i
                          className="fas fa-shield-alt fa-sm"
                          style={{ ...styles.iconstyle }}
                        ></i>
                      )}
                      <span style={{ ...styles.sendername }}>
                        {this.context.t("replied to {recipient}", {
                          recipient: this.props.comment.recipient_is_moderator
                            ? this.context.t("moderator")
                            : this.props.comment.recipient_username ||
                              this.context.t("Anonymous"),
                        })}
                      </span>
                    </h5>
                    <p style={{ ...styles.createddate }}>
                      {moment(this.props.comment.created_at).format("LLL")}
                    </p>
                  </div>
                  <div style={{ width: "fit-content" }}>
                    {this.props.profile.type == "IS" ? (
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle
                            as={CustomToggle}
                            direction="right"
                            style={{ curser: "pointer" }}
                          />
                          <Dropdown.Menu size="sm" title="">
                            <Dropdown.Item
                              style={{ width: "fit-content", color: "red" }}
                              onClick={() =>
                                this.props.commentReplyActionsHandler(
                                  this.props.comment,
                                  "delete",
                                )
                              }
                            >
                              {this.context.t("Delete comment")}
                            </Dropdown.Item>
                            <Dropdown.Item
                              style={{ width: "fit-content" }}
                              onClick={() =>
                                this.props.commentReplyActionsHandler(
                                  this.props.comment,
                                  "hide",
                                )
                              }
                            >
                              {this.props.comment.is_hide == false ? (
                                <span>{this.context.t("Hide comment")}</span>
                              ) : (
                                <span>{this.context.t("Unhide comment")}</span>
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={2}></Col>
              <Col xs={10}>
                <p style={{ ...styles.commenttext }}>
                  {this.props.comment.sender_is_moderator ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: urlify(this.props.comment.text),
                      }}
                    />
                  ) : (
                    this.props.comment.text
                  )}
                </p>

                {/* Reaction area */}
                <div style={{ ...styles.commentreplay }}>
                  <ReactionIcons
                    type="comment"
                    makeReaction={this.makeReaction}
                    reactionSet={this.state.reactionSet}
                  />
                  {this.props.isAuthenticated && (
                    <Online>
                      <a
                        onClick={this.handleClickReply}
                        className="reply-button"
                      >
                        <i className="fa fa-reply"></i>&nbsp;
                        {this.context.t("Discuss")}
                      </a>
                      <CommentFlagModal
                        show={this.props.showFlagModal}
                        comment={this.props.comment}
                        user={this.props.profile.id}
                      />
                    </Online>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            {this.props.comment.is_hide == false ? (
              <div style={{ marginLeft: "40px" }}>
                <hr style={{ ...styles.rowdesign }} />
                <Row>
                  <Col xs={2}>
                    <Image
                      src={
                        this.props.comment.sender_is_moderator
                          ? "/static/media/moderator.png"
                          : this.props.comment.sender_avatar ||
                            "/static/media/astronaut.png"
                      }
                      crossOrigin="anonymous"
                      style={{ ...styles.senderavatar }}
                      onError={addDefaultImageSrc}
                    />
                  </Col>
                  <Col xs={10}>
                    <div style={{ ...styles.commentreplay }}>
                      <div>
                        <h5
                          style={{
                            color:
                              this.props.comment.sender_is_moderator &&
                              "#198474",
                          }}
                        >
                          {this.props.comment.sender_is_moderator
                            ? this.context.t("moderator")
                            : this.props.comment.sender_username ||
                              this.context.t("Anonymous")}
                          {this.props.comment.sender_is_moderator && (
                            <i
                              className="fas fa-shield-alt fa-sm"
                              style={{ ...styles.iconstyle }}
                            ></i>
                          )}
                          <span style={{ ...styles.sendername }}>
                            {this.context.t("replied to {recipient}", {
                              recipient: this.props.comment
                                .recipient_is_moderator
                                ? this.context.t("moderator")
                                : this.props.comment.recipient_username ||
                                  this.context.t("Anonymous"),
                            })}
                          </span>
                        </h5>
                        <p style={{ ...styles.createddate }}>
                          {moment(this.props.comment.created_at).format("LLL")}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}></Col>
                  <Col xs={10}>
                    <div style={{ ...styles.commenttext }}>
                      {this.props.comment.sender_is_moderator ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: urlify(this.props.comment.text),
                          }}
                        />
                      ) : (
                        this.props.comment.text
                      )}
                    </div>

                    {/* Reaction area */}
                    <div style={{ ...styles.commentreplay }}>
                      <ReactionIcons
                        type="comment"
                        makeReaction={this.makeReaction}
                        reactionSet={this.state.reactionSet}
                      />
                      {this.props.isAuthenticated && (
                        <Online>
                          <a
                            onClick={this.handleClickReply}
                            className="reply-button"
                          >
                            <i className="fa fa-reply"></i>&nbsp;
                            {this.context.t("Discuss")}
                          </a>
                          <CommentFlagModal
                            show={this.props.showFlagModal}
                            comment={this.props.comment}
                            user={this.props.profile.id}
                          />
                        </Online>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

CommentReply.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.ProfileState.data,
    isAuthenticated: state.AuthenticationState.token != null,
  };
};

export default connect(mapStateToProps)(CommentReply);
