import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const ADDRESSES_GET_START = "ADDRESSES_GET_START";
export const ADDRESSES_GET_SUCCESS = "ADDRESSES_GET_SUCCESS";
export const ADDRESSES_GET_FAIL = "ADDRESSES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getAddressesStart = () => {
  return {
    type: ADDRESSES_GET_START,
  };
};

export const getAddressesSuccess = (addresses) => {
  return {
    type: ADDRESSES_GET_SUCCESS,
    addresses: addresses,
  };
};

export const getAddressesFail = (error) => {
  return {
    type: ADDRESSES_GET_FAIL,
    error: error,
  };
};

export const getAddresses = () => {
  return (dispatch, getState) => {
    dispatch(getAddressesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/addresses?lang=${language}`)
      .then((res) => {
        dispatch(getAddressesSuccess(res.data));
        return getState().AddressesState.items;
      })
      .catch((err) => {
        dispatch(getAddressesFail(err));
        throw err;
      });
  };
};
