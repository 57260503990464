import axios from "axios";
export const COMMENT_Reaction_SUCCESS = "COMMENT_Reaction_SUCCESS";
export const COMMENT_Reaction_PUT_SUCCESS = "COMMENT_Reaction_PUT_SUCCESS";
export const COMMENT_Reaction_POST_SUCCESS = "COMMENT_Reaction_POST_SUCCESS";

export const CommentReactionSuccess = (data) => {
  return {
    type: COMMENT_Reaction_SUCCESS,
    payload: data,
  };
};
export const CommentReactionPutsuccess = (data) => {
  return {
    type: COMMENT_Reaction_PUT_SUCCESS,
    payload: data,
  };
};
export const CommentReactionPostsuccess = (data) => {
  return {
    type: COMMENT_Reaction_POST_SUCCESS,
    payload: data,
  };
};
export const CommentReaction = (id) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/comment-reaction-update?id=${id}&type=question`,
      )
      .then((res) => {
        dispatch(CommentReactionSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const CommentReactionPut = (reactionType, questionId) => {
  let payload = { reaction_type: reactionType, questions: questionId };
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/comment-reaction-update?type=question`,
        payload,
      )
      .then((res) => {
        dispatch(CommentReactionPutsuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const CommentReactionPost = (reactionType, questionId) => {
  let payload = { reaction_type: reactionType, questions: questionId };
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/comment-reaction?type=question`,
        payload,
      )
      .then((res) => {
        dispatch(CommentReactionPostsuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
