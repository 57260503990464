import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Select from "react-select";

import { getEducationLevels } from "../../common/redux/actions/EducationLevelsActions";
import { reactSelectDropdownStyle } from "../../common/styles/dropdowns";

const EducationLevelSelect = (props, context) => {
  const dispatch = useDispatch();

  const educationLevels = useSelector((state) => state.EducationLevelsState);

  const [educationLevelOptions, setEducationLevelOptions] = useState([]);
  const [selectedEducationLevel, setSelectedEducationLevel] = useState(
    props.initialValue,
  );

  useEffect(() => {
    !educationLevels.fetched && dispatch(getEducationLevels());
  }, []);

  useEffect(() => {
    setSelectedEducationLevel(props.initialValue);
  }, [props.initialValue]);

  useEffect(() => {
    const options = Object.keys(educationLevels.items)
      .sort((a, b) =>
        educationLevels.items[a].name > educationLevels.items[b].name ? 1 : -1,
      )
      .map((key) => {
        return {
          value: educationLevels.items[key].education,
          label: educationLevels.items[key].name,
        };
      });
    setEducationLevelOptions(options);
  }, [educationLevels]);

  const handleEducationLevelChange = (data, event) => {
    const value = data ? data.value : null;
    setSelectedEducationLevel(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <Select
      // name='education'
      options={educationLevelOptions}
      value={
        props.isMulti
          ? educationLevelOptions.filter(
              (o) => selectedEducationLevel.indexOf(o.value) >= 0,
            )
          : educationLevelOptions.filter(
              (o) => o.value === selectedEducationLevel,
            )[0]
      }
      placeholder={props.placeholder}
      style={{ textAlign: "center" }}
      onChange={handleEducationLevelChange}
      styles={reactSelectDropdownStyle}
      isSearchable={props.isSearchable}
      isMulti={props.isMulti}
      isClearable={props.isClearable}
    />
  );
};

EducationLevelSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EducationLevelSelect;
