import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const INDUSTRIES_GET_START = "INDUSTRIES_GET_START";
export const INDUSTRIES_GET_SUCCESS = "INDUSTRIES_GET_SUCCESS";
export const INDUSTRIES_GET_FAIL = "INDUSTRIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getIndustriesStart = () => {
  return {
    type: INDUSTRIES_GET_START,
  };
};

export const getIndustriesSuccess = (industries) => {
  return {
    type: INDUSTRIES_GET_SUCCESS,
    industries: industries,
  };
};

export const getIndustriesFail = (error) => {
  return {
    type: INDUSTRIES_GET_FAIL,
    error: error,
  };
};

export const getIndustries = () => {
  return (dispatch, getState) => {
    dispatch(getIndustriesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/industries?lang=${language}`)
      .then((res) => {
        dispatch(getIndustriesSuccess(res.data));
        return getState().IndustriesState.items;
      })
      .catch((err) => {
        dispatch(getIndustriesFail(err));
        throw err;
      });
  };
};
