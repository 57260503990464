import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useParams, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import I18n from "redux-i18n";

import { FormControl, Button, Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

export default function SurveyModal(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.ProfileState.data);
  const surveys = useSelector((state) => state.SurveysState);
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    // update local state show from the parent component props
    setShow(props.show);
  }, [props.show]);

  const handleAcceptSurvey = () => {
    // set modal show false in the parent component
    props.onClose();
    // redirect to the survey detailed view
    history.push(`/surveys/${props.survey.identifier}`);
  };

  const handleRejectSurvey = () => {
    // set modal show false in the parent component
    props.onClose();

    // get muted surveys
    let muted_surveys = JSON.parse(localStorage.getItem("muted_surveys"));

    // add muted survey to localStorage on rejection
    if (muted_surveys) {
      muted_surveys = {
        ...muted_surveys,
        [props.survey.identifier]: moment.now(),
      };
    } else {
      muted_surveys = {
        [props.survey.identifier]: moment.now(),
      };
    }

    // update localStorage with newly added muted survey
    localStorage.setItem("muted_surveys", JSON.stringify(muted_surveys));
  };

  return (
    <>
      {props.survey && (
        <Modal show={show} centered onHide={props.onClose}>
          <Modal.Header style={{ justifyContent: "center" }}>
            <Modal.Title style={{ fontSize: "1rem" }}>
              {" "}
              <b>
                {context.t("Would you like to participate in the survey?")}
              </b>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p style={{ margin: 0 }}>
              {" "}
              {props.survey.translation.description}{" "}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleRejectSurvey}>
              {context.t("I don't want to answer")}
            </Button>
            <Button variant="success" onClick={handleAcceptSurvey}>
              {context.t("I want to answer")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

SurveyModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
