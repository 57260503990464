import { updateObject } from "../Utility";
import {
  SERVICE_CATEGORIES_GET_START,
  SERVICE_CATEGORIES_GET_SUCCESS,
  SERVICE_CATEGORIES_GET_FAIL,
} from "../actions/ServiceCategoriesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getServiceCategoriesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getServiceCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.serviceCategories.reduce((obj, item) => {
      obj[item.service_category] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getServiceCategoriesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const ServiceCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case SERVICE_CATEGORIES_GET_START:
      return getServiceCategoriesStart(state, action);
    case SERVICE_CATEGORIES_GET_SUCCESS:
      return getServiceCategoriesSuccess(state, action);
    case SERVICE_CATEGORIES_GET_FAIL:
      return getServiceCategoriesFail(state, action);

    default:
      return state;
  }
};

export default ServiceCategoriesReducer;
