import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { Link, NavLink } from "react-router-dom";

import { Container, Row, Col, Image, Button } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";

import BaseView from "../BaseView";

// import { verifyQuestion, rejectQuestion } from '../../common/redux/actions/GroupsActions';

export default function ProblemMessage(props, context) {
  const profile = useSelector((state) => state.ProfileState.data);
  const language = useSelector((state) => state.i18nState.lang);

  return (
    <div
      className={`problemMessageBox ${props.message.user === profile.id || props.message.user_type === "IS" ? "myMessage" : "yourMessage"}`}
    >
      {props.message.message}
    </div>
  );
}

ProblemMessage.contextTypes = {
  t: PropTypes.func.isRequired,
};
