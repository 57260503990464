import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Container, Col, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import ReactionIcons from "../../components/ReactionIcons";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import { Online } from "react-detect-offline";
import CommentReply from "./CommentReply";
import "./CommentThread.css";

const CommentThread = (props, context) => {
  const [reaction, setReaction] = useState({});
  const comment = props.comment;
  useEffect(() => {
    getReactions();
  }, []);
  const handleClickReply = () => {
    const replyPayload = {
      text: comment.body,
      parent: comment.id,
      username: comment.user.username,
    };
    props.onClickReply(replyPayload);
  };
  const getReactions = (post_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${props.postId}/list-comment-likes/?comment=${comment.id}&lang=${props.lang}`,
      )
      .then((res) => {
        setReaction(res.data);
      })
      .catch((err) => console.log("viewed recently"));
  };
  const onLikeClick = (e, type) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${props.postId}/react/?lang=${props.lang}`,
        {
          comment_id: comment.id,
          reaction_type: type.toUpperCase(),
        },
      )
      .then((response) => {
        setReaction(response.data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        // Handle any errors that occurred during the request
      });
  };

  return (
    <>
      {comment.parent_comment === null ? (
        <Container className="comment-thread">
          <Row>
            <Col
              xs={2}
              lg={1}
              className="d-flex 
                     align-items-center 
                     justify-content-center"
            >
              <Image
                width={45}
                height={45}
                className="astro-img"
                src={"/static/media/astronaut.png"}
                onError={addDefaultImageSrc}
              />
            </Col>
            <Col xs={10} lg={11}>
              <span style={{ textAlign: "left" }}>
                <h5 className="comment-username">{comment.user.username}</h5>
                <p className="comment-time">{moment(comment.date).fromNow()}</p>
                <p className="comment-body">{comment.body}</p>

                {/* Reaction area */}
                <div className="reaction-area">
                  <ReactionIcons
                    type="comment"
                    makeReaction={onLikeClick}
                    reactionSet={reaction}
                  />
                  <div>
                    <Online>
                      <a onClick={handleClickReply} className="reply-button">
                        <i className="fa fa-reply"></i> {context.t("Reply")}{" "}
                      </a>
                    </Online>
                  </div>
                </div>
              </span>
            </Col>
          </Row>
          {comment.child_comments ? (
            comment.child_comments.map((item, idx) => {
              return (
                <CommentReply
                  onClickReply={props.onClickReply}
                  postId={props.postId}
                  comment={item}
                  key={idx}
                  lang={props.lang}
                />
              );
            })
          ) : (
            <div></div>
          )}
        </Container>
      ) : (
        <div></div>
      )}
    </>
  );
};

CommentThread.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
  };
};

export default connect(mapStateToProps)(CommentThread);
