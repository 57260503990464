import axios from "axios";

import {
  updateProfileUserAvatar,
  updateProfileUserDocuments,
  updateProfileNotificationSettings,
  getProfile,
} from "./ProfileActions";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const USERS_GET_START = "USERS_GET_START";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS";
export const USERS_GET_FAIL = "USERS_GET_FAIL";

export const USERS_ADD_NEW = "USERS_ADD_NEW";

export const USER_AVATAR_UPDATE_START = "USER_AVATAR_UPDATE_START";
export const USER_AVATAR_UPDATE_SUCCESS = "USER_AVATAR_UPDATE_SUCCESS";
export const USER_AVATAR_UPDATE_FAIL = "USER_AVATAR_UPDATE_FAIL";

export const USER_NOTIFICATION_SETTINGS_UPDATE_START =
  "USER_NOTIFICATION_SETTINGS_UPDATE_START";
export const USER_NOTIFICATION_SETTINGS_UPDATE_SUCCESS =
  "USER_NOTIFICATION_SETTINGS_UPDATE_SUCCESS";
export const USER_NOTIFICATION_SETTINGS_UPDATE_FAIL =
  "USER_NOTIFICATION_SETTINGS_UPDATE_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getUsersStart = () => {
  return {
    type: USERS_GET_START,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: USERS_GET_SUCCESS,
    users: users,
  };
};

export const getUsersFail = (error) => {
  return {
    type: USERS_GET_FAIL,
    error: error,
  };
};

export const getUsers = () => {
  return (dispatch, getState) => {
    dispatch(getUsersStart());

    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/users?lang=${language}`)
      .then((res) => {
        dispatch(getUsersSuccess(res.data));
        return getState().UsersState.items;
      })
      .catch((err) => {
        dispatch(getUsersFail(err));
        throw err;
      });
  };
};

export const updateUserAvatarStart = () => {
  return {
    type: USER_AVATAR_UPDATE_START,
  };
};

export const updateUserAvatarSuccess = (user_id, avatar) => {
  return {
    type: USER_AVATAR_UPDATE_SUCCESS,
    avatar: avatar,
    user_id: user_id,
  };
};

export const updateUserAvatarFail = (error) => {
  return {
    type: USER_AVATAR_UPDATE_FAIL,
    error: error,
  };
};

export const updateUserAvatar = (user_id, avatar) => {
  return (dispatch, getState) => {
    dispatch(updateUserAvatarStart());

    const payload = { file: avatar };
    console.log(payload);
    var formData = new FormData();
    formData.append("avatar", avatar);

    // const headers = { 'Content-Type': 'application/x-www-form-urlencoded' }

    axios
      .post(`${process.env.REACT_APP_API_URL}/users/${user_id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        dispatch(updateProfileUserAvatar(res.data));
        dispatch(updateUserAvatarSuccess(user_id, res.data));
      })
      .catch((err) => {
        dispatch(updateUserAvatarFail(err));
      });
  };
};

export const updateUserNotificationSettingsStart = () => {
  return {
    type: USER_NOTIFICATION_SETTINGS_UPDATE_START,
  };
};

export const updateUserNotificationSettingsSuccess = () => {
  return {
    type: USER_NOTIFICATION_SETTINGS_UPDATE_SUCCESS,
  };
};

export const updateUserNotificationSettingsFail = (error) => {
  return {
    type: USER_NOTIFICATION_SETTINGS_UPDATE_FAIL,
    error: error,
  };
};

export const updateUserNotificationSettings = (user_id, payload) => {
  return (dispatch, getState) => {
    dispatch(updateUserNotificationSettingsStart());

    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/users/${user_id}/update_firebase_settings/`,
        payload,
      )
      .then((res) => {
        dispatch(updateProfileNotificationSettings(payload));
        dispatch(updateUserNotificationSettingsSuccess(user_id));
        return Promise.resolve();
      })
      .catch((err) => {
        dispatch(updateUserNotificationSettingsFail(err));
        return Promise.reject(err);
      });
  };
};

// ADD:
export const addUser = (user) => {
  return {
    type: USERS_ADD_NEW,
    user: user,
  };
};
