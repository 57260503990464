import { omit } from "lodash";
import { updateObject } from "../Utility";
import {
  POLLS_QUESTIONS_GET_START,
  POLLS_QUESTIONS_GET_SUCCESS,
  POLLS_QUESTIONS_GET_FAIL,
  POLLS_VOTE_POST_START,
  POLLS_VOTE_POST_SUCCESS,
  POLLS_VOTE_POST_FAIL,
} from "../actions/PollsActions";

const initialState = {
  items: {},
  latest: null,
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getPollsQuestionsStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
};

const getPollsQuestionsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.questions.reduce((obj, question) => {
      obj[question.id] = question;
      obj[question.id].choices = question.choices.reduce((obj, choice) => {
        obj[choice.id] = choice;
        return obj;
      }, {});
      return obj;
    }, {}),
    latest: action.latest,
    loading: false,
    fetched: true,
  });
};

const getPollsQuestionsFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
};

// POST:
const postPollsVoteStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
};

const postPollsVoteSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.vote.question_id]: {
        ...state.items[action.vote.question_id],
        votes_count: state.items[action.vote.question_id].votes_count + 1,
        choices: {
          ...state.items[action.vote.question_id].choices,
          [action.vote.choice_id]: {
            ...state.items[action.vote.question_id].choices[
              action.vote.choice_id
            ],
            votes_count:
              state.items[action.vote.question_id].choices[
                action.vote.choice_id
              ].votes_count + 1,
          },
        },
      },
    },
    loading: false,
  });
};

const postPollsVoteFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
};

const PollsReducer = (state = initialState, action) => {
  switch (action.type) {
    // POLLS QUESTIONS GET
    case POLLS_QUESTIONS_GET_START:
      return getPollsQuestionsStart(state, action);
    case POLLS_QUESTIONS_GET_SUCCESS:
      return getPollsQuestionsSuccess(state, action);
    case POLLS_QUESTIONS_GET_FAIL:
      return getPollsQuestionsFail(state, action);

    // POLLS VOTE POST
    case POLLS_VOTE_POST_START:
      return postPollsVoteStart(state, action);
    case POLLS_VOTE_POST_SUCCESS:
      return postPollsVoteSuccess(state, action);
    case POLLS_VOTE_POST_FAIL:
      return postPollsVoteFail(state, action);

    default:
      return state;
  }
};

export default PollsReducer;
