import { updateObject } from "../Utility";
import {
  SHOW_PUSH_NOTIFICATION_TOAST,
  HIDE_PUSH_NOTIFICATION_TOAST,
} from "../actions/PushNotificationActions";

const initialState = {
  data: {},
  show: false,
};

const showPushNotificationToast = (state, action) => {
  return updateObject(state, {
    data: action.data,
    show: true,
  });
};

const hidePushNotificationToast = (state, action) => {
  return updateObject(state, {
    data: {},
    show: false,
  });
};

const PushNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PUSH_NOTIFICATION_TOAST:
      return showPushNotificationToast(state, action);
    case HIDE_PUSH_NOTIFICATION_TOAST:
      return hidePushNotificationToast(state, action);

    default:
      return state;
  }
};

export default PushNotificationsReducer;
