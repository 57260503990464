import { updateObject } from "../Utility";
import {
  CITIES_GET_START,
  CITIES_GET_SUCCESS,
  CITIES_GET_FAIL,
  CITY_GET_START,
  CITY_GET_SUCCESS,
  CITY_GET_FAIL,
} from "../actions/CitiesActions";

const initialState = {
  items: {},
  details: {
    loading: false,
    error: null,
    fetched: false,
    data: {},
  },
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getCitiesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getCitiesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.cities.reduce((obj, item) => {
      obj[item.city] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getCitiesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const getCityStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    details: {
      loading: true,
      error: null,
      data: {},
    },
  });
};

const getCitySuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    details: {
      loading: false,
      error: null,
      fetched: true,
      data: action.city,
    },
  });
};

const getCityFail = (state, action) => {
  return updateObject(state, {
    ...state,
    details: {
      loading: false,
      error: action.error,
      data: {},
    },
  });
};

const CitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case CITIES_GET_START:
      return getCitiesStart(state, action);
    case CITIES_GET_SUCCESS:
      return getCitiesSuccess(state, action);
    case CITIES_GET_FAIL:
      return getCitiesFail(state, action);

    // DETAILS:
    case CITY_GET_START:
      return getCityStart(state, action);
    case CITY_GET_SUCCESS:
      return getCitySuccess(state, action);
    case CITY_GET_FAIL:
      return getCityFail(state, action);

    default:
      return state;
  }
};

export default CitiesReducer;
