import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

export default function JobsApplicationModal(props, context) {
  return (
    <>
      <Modal show={props.show} centered onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1rem" }}>
            <b> {context.t("Job Application Confirmation !")}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {context.t(
            "Are you sure you want to apply for this {employer} Company for this position?",
            {
              employer:
                props.job.employer_workers_name || props.job.employer_name,
            },
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel-apply-button" onClick={props.handleClose}>
            {context.t("Cancel")}
          </Button>
          <Button
            className="apply-button"
            onClick={props.handleApply.bind(this, props.job.id)}
          >
            {context.t("Yes, Sure")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

JobsApplicationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
