import { updateObject } from "../Utility";
import {
  EMPLOYERS_GET_START,
  EMPLOYERS_GET_SUCCESS,
  EMPLOYERS_GET_FAIL,
  EMPLOYERS_ADD_COMMENT,
  EMPLOYER_ADD_COMMENT,
  EMPLOYERS_ADD_CHILD_COMMENT,
  EMPLOYER_ADD_CHILD_COMMENT,
  EMPLOYERS_RECALCULATE_SCORE,
  EMPLOYERS_VERIFY_NEW,
  EMPLOYERS_REJECT_NEW,
  GET_ADDRESS_START,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAIL,
  GET_EMP_DETAIL_START,
  GET_EMP_DETAIL_FAIL,
  GET_EMP_DETAIL_SUCCESS,
  EMP_COMMENT_REMOVE,
} from "../actions/EmployersActions";

const initialState = {
  detail: {
    item: {},
    loading: false,
    fetched: false,
    error: false,
  },
  items: [],
  loading: false,
  fetched: false,
  error: null,
  address: {
    fetching: false,
    fetched: false,
    error: false,
    item: {},
  },
};

// GET:
const getEmployersStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getEmployersSuccess = (state, action) => {
  return updateObject(state, {
    items: action.employers,
    loading: false,
    fetched: true,
    error: null,
  });
};

const getEmployersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

// INTERNAL:
const addEmployerComment = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: {
        ...state.detail.item,
        comments_count: state.detail.item.comments_count + 1,
        comments: [...state.detail.item.comments, action.comment],
      },
    },
  });
};

const addEmployersComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((employer) =>
      employer.id === action.comment.object_id
        ? // transform the one with a matching id
          {
            ...employer,
            comments_count: employer.comments_count + 1,
            comments: [...employer.comments, action.comment],
          }
        : // otherwise return original employer
          employer,
    ),
    loading: false,
    error: null,
  });
};

const addEmployersChildComment = (state, action) => {
  return updateObject(state, {
    items: state.items.map((employer) =>
      employer.id === action.comment.object_id
        ? // transform the one with a matching id
          {
            ...employer,
            comments_count: employer.comments_count + 1,
            comments: employer.comments.map((comment) =>
              comment.id === action.comment.base_parent
                ? {
                    ...comment,
                    children: [...comment.children, action.comment],
                  }
                : comment,
            ),
          }
        : // otherwise return original employer
          employer,
    ),
    loading: false,
    error: null,
  });
};

const addEmployerChildComment = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: {
        ...state.detail.item,
        comments_count: state.detail.item.comments_count + 1,
        comments: state.detail.item.comments.map((comment) =>
          comment.id === action.comment.base_parent
            ? { ...comment, children: [...comment.children, action.comment] }
            : comment,
        ),
      },
    },
  });
};

const recalculateEmployerScore = (state, action) => {
  return updateObject(state, {
    items: state.items.map((employer) =>
      employer.id === action.employer_id
        ? // transform the one with a matching id
          {
            ...employer,
            rating_count: employer.rating_count + (action.update ? 0 : 1),
            rating_score_count:
              employer.rating_score_count + action.offsetCount,
            rating_score:
              (employer.rating_score * employer.rating_score_count +
                action.offsetScore) /
              (employer.rating_score_count + action.offsetCount),
            scores_for_each_criteria: employer.scores_for_each_criteria.map(
              (item) => {
                if (action.detailed_scores[item.rating_criteria_id]) {
                  const newScore =
                    action.detailed_scores[item.rating_criteria_id];
                  if (newScore.updated) {
                    return {
                      ...item,
                      score:
                        (item.score * item.count + newScore.offset) /
                        item.count,
                    };
                  } else {
                    return {
                      ...item,
                      score:
                        (item.score * item.count + newScore.score) /
                        (item.count + 1),
                      count: item.count + 1,
                    };
                  }
                } else return item;
              },
            ),
          }
        : // otherwise return original todo
          employer,
    ),
    loading: false,
    error: null,
  });
};

const verifiyNewEmployer = (state, action) => {
  return updateObject(state, {
    items: state.items.map((employer) =>
      employer.id === action.id
        ? // transform the one with a matching id
          { ...employer, managed_by: "User" }
        : // otherwise return original employer
          employer,
    ),
  });
};

const rejectNewEmployer = (state, action) => {
  return updateObject(state, {
    items: state.items.filter((employer) => employer.id !== action.id),
  });
};

const mergeNewEmployer = (state, action) => {
  return updateObject(state, {
    items: state.items.filter((employer) => employer.id !== action.secondaryId),
  });
};

const getEmployerAddressStart = (state, action) => {
  return updateObject(state, {
    ...state,
    address: {
      fetched: false,
      fetching: true,
      error: false,
      item: {},
    },
  });
};

const getEmployerAddressSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    address: {
      fetched: true,
      fetching: false,
      error: false,
      item: action.address,
    },
  });
};

const getEmployerAddressFail = (state, action) => {
  return updateObject(state, {
    ...state,
    address: {
      fetched: false,
      fetching: false,
      error: action.error,
      item: {},
    },
  });
};
// GET EMP Detail:
export const getEmployerDetailStart = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: false,
      loading: true,
      error: false,
      item: {},
    },
  });
};

export const getEmployerDetailSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: true,
      loading: false,
      error: false,
      item: action.employer,
    },
  });
};

export const getEmployerDetailFail = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      fetched: false,
      loading: false,
      error: action.error,
      item: {},
    },
  });
};

export const removeComment = (state, action) => {
  return updateObject(state, {
    ...state,
    detail: {
      ...state.detail,
      item: {
        ...state.detail.item,
        comments_count: state.detail.item.comments_count - 1,
        comments: state.detail.item.comments.filter((item) => {
          return (
            item.id !== action.comment.id ||
            (item.id !== action.comment.id &&
              item.base_parent == action.comment.id)
          );
        }),
      },
    },
  });
};

const EmployersReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case EMPLOYERS_GET_START:
      return getEmployersStart(state, action);
    case EMPLOYERS_GET_SUCCESS:
      return getEmployersSuccess(state, action);
    case EMPLOYERS_GET_FAIL:
      return getEmployersFail(state, action);

    // OTHER:
    case EMPLOYERS_ADD_COMMENT:
      return addEmployersComment(state, action);
    case EMPLOYER_ADD_COMMENT:
      return addEmployerComment(state, action);
    case EMPLOYER_ADD_CHILD_COMMENT:
      return addEmployerChildComment(state, action);
    case EMPLOYERS_ADD_CHILD_COMMENT:
      return addEmployersChildComment(state, action);
    case EMPLOYERS_RECALCULATE_SCORE:
      return recalculateEmployerScore(state, action);

    case EMPLOYERS_VERIFY_NEW:
      return verifiyNewEmployer(state, action);
    case EMPLOYERS_REJECT_NEW:
      return rejectNewEmployer(state, action);

    case GET_ADDRESS_START:
      return getEmployerAddressStart(state, action);
    case GET_ADDRESS_SUCCESS:
      return getEmployerAddressSuccess(state, action);
    case GET_ADDRESS_FAIL:
      return getEmployerAddressFail(state, action);

    case GET_EMP_DETAIL_START:
      return getEmployerDetailStart(state, action);
    case GET_EMP_DETAIL_FAIL:
      return getEmployerDetailFail(state, action);
    case GET_EMP_DETAIL_SUCCESS:
      return getEmployerDetailSuccess(state, action);

    // Comment
    case EMP_COMMENT_REMOVE:
      return removeComment(state, action);

    default:
      return state;
  }
};

export default EmployersReducer;
