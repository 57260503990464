import React, { useEffect, useState } from "react";
import Turnstile, { useTurnstile } from "react-turnstile";

const TurnstileTokenStatus = Object.freeze({
  ERROR: "error",
  EXPIRED: "expired",
  NOT_SET: "not set",
  SOLVED: "solved",
});

const TurnstileRetrySetting = Object.freeze({
  AUTO: "auto",
  NEVER: "never",
});

const MAX_RETRY_ATTEMPTS = 10;
const TURNSTILE_ID = "turnstile-widget";

const TurnstileWidget = ({ formError, updateToken, updateTokenStatus }) => {
  const turnstile = useTurnstile();
  const sitekey = process.env.REACT_APP_TURNSTILE_SITE_KEY;

  const [errorCount, setErrorCount] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const turnstileWidget = document.getElementById(TURNSTILE_ID);
    try {
      if (turnstileWidget && loaded) {
        turnstile && turnstile.reset(TURNSTILE_ID);
      }
    } catch (error) {
      console.warn(
        "There was a problem when trying to reset the Turnstile widget.",
        error,
      );
    }
  }, [formError]);

  return (
    <Turnstile
      appearance="always"
      id={TURNSTILE_ID}
      onLoad={() => setLoaded(true)}
      onVerify={(token) => {
        setErrorCount(0);
        updateToken(token);
        updateTokenStatus(TurnstileTokenStatus.SOLVED);
        console.log("Turnstile verified");
      }}
      onError={(error) => {
        setErrorCount(errorCount + 1);
        updateTokenStatus(TurnstileTokenStatus.ERROR);
        console.warn(
          `Turnstile verification error:\nsiteKey: ${sitekey}\nerror: ${error}`,
        );
      }}
      onExpire={() => {
        updateTokenStatus(TurnstileTokenStatus.EXPIRED);
        console.warn("Turnstile token expired.");
      }}
      onTimeout={(token) => {
        updateTokenStatus(TurnstileTokenStatus.EXPIRED);
        console.warn("Turnstile challenge time expired.");
      }}
      refreshExpired="auto"
      retry={
        errorCount < MAX_RETRY_ATTEMPTS
          ? TurnstileRetrySetting.AUTO
          : TurnstileRetrySetting.NEVER
      }
      size="normal"
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "1rem",
        marginBottom: "1rem",
      }}
      sitekey={sitekey}
      theme={"light"}
    />
  );
};

export default TurnstileWidget;
export { TurnstileTokenStatus };
