import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "react-bootstrap";

export default function RemoveDocumentConfirmationModal(props, context) {
  return (
    <>
      <Modal show centered onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "1rem" }}>
            <b> {context.t("Document removal confirmation")}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {context.t("Are you sure you would like to remove this documents?")}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            {context.t("Cancel")}
          </Button>
          <Button variant="danger" onClick={props.handleRemoveDocument}>
            {context.t("Yes, sure")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

RemoveDocumentConfirmationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
