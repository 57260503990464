import { updateObject } from "../Utility";
import {
  CURRENCIES_GET_START,
  CURRENCIES_GET_SUCCESS,
  CURRENCIES_GET_FAIL,
} from "../actions/CurrenciesActions";

const initialState = {
  items: {},
  itemsArray: [],
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getCurrenciesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getCurrenciesSuccess = (state, action) => {
  return updateObject(state, {
    items: action.currencies.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {}),
    itemsArray: action.currencies,
    loading: false,
    fetched: true,
    error: null,
  });
};

const getCurrenciesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const CurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case CURRENCIES_GET_START:
      return getCurrenciesStart(state, action);
    case CURRENCIES_GET_SUCCESS:
      return getCurrenciesSuccess(state, action);
    case CURRENCIES_GET_FAIL:
      return getCurrenciesFail(state, action);
    default:
      return state;
  }
};

export default CurrenciesReducer;
