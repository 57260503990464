import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const SERVICE_CATEGORIES_GET_START = "SERVICE_CATEGORIES_GET_START";
export const SERVICE_CATEGORIES_GET_SUCCESS = "SERVICE_CATEGORIES_GET_SUCCESS";
export const SERVICE_CATEGORIES_GET_FAIL = "SERVICE_CATEGORIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getServiceCategoriesStart = () => {
  return {
    type: SERVICE_CATEGORIES_GET_START,
  };
};

export const getServiceCategoriesSuccess = (serviceCategories) => {
  return {
    type: SERVICE_CATEGORIES_GET_SUCCESS,
    serviceCategories: serviceCategories,
  };
};

export const getServiceCategoriesFail = (error) => {
  return {
    type: SERVICE_CATEGORIES_GET_FAIL,
    error: error,
  };
};

export const getServiceCategories = () => {
  return (dispatch, getState) => {
    dispatch(getServiceCategoriesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/service-categories?lang=${language}`,
      )
      .then((res) => {
        dispatch(getServiceCategoriesSuccess(res.data));
        return getState().ServiceCategoriesState.items;
      })
      .catch((err) => {
        dispatch(getServiceCategoriesFail(err));
        throw err;
      });
  };
};
