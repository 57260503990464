import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

class EmployersRegistrationForm extends Component {
  render() {
    return <div>I am employer registration form</div>;
  }
}

EmployersRegistrationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
  };
};

export default connect(mapStateToProps)(EmployersRegistrationForm);
