import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Row, Col, Form, Button, Container, Alert } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";

import {
  authSignup,
  authGoogle,
  authFacebook,
} from "../../common/redux/actions/AuthenticationActions";
import Divider from "../../components/Divider";
import PrivacyPolicyModal from "../StaticPages/PrivacyPolicyModal";
import MemberAgreementModal from "../StaticPages/MemberAgreementModal";

import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getNationalities } from "../../common/redux/actions/NationalitiesActions";

import {
  getErrorMessage,
  passwordRegex,
} from "../../common/utilities/utilities";

import TurnstileWidget, {
  TurnstileTokenStatus,
} from "../../components/TurnstileWidget";

class JobseekersRegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payload: {},
      errors: {},
      validated: false,
      loading: false,
      showPassword: false,
      error: "",
      modals: {
        privacyPolicy: { show: false },
        memberAgreement: { show: false },
      },
      tokenStatus: TurnstileTokenStatus.NOT_SET,
    };
  }

  /* BASE HANDLERS */

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      payload: {
        ...this.state.payload,
        [name]: value,
      },
    });
  };

  ConfirmPasswordHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      payload: {
        ...this.state.payload,
        [name]: value,
      },
    });
  };

  toggleShowPassword = () => {
    this.setState({
      ...this.state,
      showPassword: !this.state.showPassword,
    });
  };

  handleSelectChange = (data, event) => {
    const name = event.name;
    const value = data.value;

    this.setState({
      payload: {
        ...this.state.payload,
        [name]: value,
      },
    });
  };

  responseGoogle = (access_token) => {
    this.props.authGoogle("JS", access_token);
  };

  responseGoogleFailure = (response) => {
    console.log("Google failed");
  };

  responseFacebook = (response) => {
    this.props.authFacebook("JS", response.accessToken);
  };

  responseFacebookFailure = (response) => {
    console.log("Facebook failed");
  };

  handleTestGoogleSignup = () => {
    this.props.authGoogle("JS", process.env.REACT_APP_TEST_GOOGLE_TOKEN);
  };

  handleTestFacebookSignup = () => {
    this.props.authFacebook("JS", process.env.REACT_APP_TEST_FACEBOOK_TOKEN);
  };

  handleBasicSignup = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const isValid = form.checkValidity(
      this.state.password === this.state.confirm_password,
    );
    if (
      isValid &&
      this.state.payload.password === this.state.payload.confirm_password
    ) {
      if (!passwordRegex.test(this.state.payload.password)) {
        this.setState({
          ...this.state,
          error: this.context.t(
            "Password must be at least 8 characters long and include both letters (a-z) and numbers (0-9).",
          ),
        });
        return;
      }
      this.setState({ ...this.state, loading: true });
      this.props.authSignup("JS", this.state.payload);
      this.setState({ ...this.state, validated: true });
    } else {
      this.setState({
        ...this.state,
        error: this.context.t("Please Confirm your password"),
      });
    }
  };

  hideModal = (modalName) => {
    this.setState({
      ...this.state,
      modals: {
        ...this.state.modals,
        [modalName]: {
          ...this.state.modals[modalName],
          show: false,
        },
      },
    });
  };

  showModal = (modalName) => {
    this.setState({
      ...this.state,
      modals: {
        ...this.state.modals,
        [modalName]: {
          ...this.state.modals[modalName],
          show: true,
        },
      },
    });
  };

  toggleAcceptTermsAndConditions = () => {
    this.setState({
      ...this.state,
      acceptedConditions: !this.state.acceptedConditions,
    });
  };

  componentDidMount() {
    if (!window.gapi) {
      console.error("Google API script is not loaded");
      return;
    }

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!clientId) {
      console.warn(
        "Google Client ID is missing. Google Auth2 will not be initialized.",
      );
      return;
    }

    window.gapi.load("auth2", () => {
      window.gapi.auth2
        .init({
          client_id: clientId,
          plugin_name: "google-app",
        })
        .then(() => {
          console.log("Google Auth2 initialized successfully");
        })
        .catch((error) => {
          console.error("Error initializing Google Auth2:", error);
        });
    });
  }

  handleSignInClick = () => {
    if (window.gapi) {
      window.gapi.auth2
        .getAuthInstance()
        .signIn()
        .then((el) => {
          let access_token = "";
          for (let pr in el) {
            if (el[pr].hasOwnProperty("access_token")) {
              access_token = el[pr]?.access_token;
            }
          }
          this.responseGoogle(access_token);
        });
    }
  };

  updateToken = (token) => {
    this.setState((prevState) => ({
      payload: {
        ...prevState.payload,
        turnstileToken: token,
      },
    }));
  };

  updateTokenStatus = (status) => {
    this.setState((prevState) => ({
      ...prevState,
      tokenStatus: status,
    }));
  };

  render() {
    const privacyPolicyLink = (
      <a href="#privacy-policy" onClick={() => this.showModal("privacyPolicy")}>
        {this.context.t("privacy policy")}
      </a>
    );
    const memberAgreementLink = (
      <a
        href="#member-agreement"
        onClick={() => this.showModal("memberAgreement")}
      >
        {this.context.t("member agreement")}
      </a>
    );

    const username_password_validated =
      this.state.payload.username &&
      this.state.payload.password &&
      !this.state.errors.username &&
      !this.state.errors.password;

    {
      /*const countries = Object.entries(this.props.countriesItems).map(([key, value]) => {
      return {value:key, label:value.name}
    })

    const nationalities = Object.entries(this.props.nationalitiesItems).map(([key, value]) => {
      return {value:key, label:value.name}
    })

    const genders = Genders({}, this.context, true) */
    }

    return (
      <Container>
        <div className="RegistrationForm mt-5">
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleBasicSignup}
          >
            {/*
          <a
            className='btn btn-info'
            onClick={this.handleTestGoogleSignup}
            style={{marginBottom:32}}>{this.context.t('GOOGLE TEST SIGNUP')}
          </a>
          <a
            className='btn btn-info'
            onClick={this.handleTestFacebookSignup}
            style={{marginBottom:32}}>{this.context.t('Facebook TEST SIGNUP')}
          </a>
          */}
            {this.props.signup_error && (
              <p style={{ color: "red" }}>
                {this.context.t(
                  this.props.signup_error
                    ? getErrorMessage(
                        this.props.signup_error.message,
                        this.context,
                      )
                    : "",
                )}
              </p>
            )}
            <Form.Group controlId="username">
              <Form.Label> {this.context.t("Username or email")} </Form.Label>
              <Form.Control
                name="username"
                required
                style={{ borderRadius: "60px", fontSize: "0.8rem" }}
                className={this.state.errors.username && "warning"}
                onChange={this.handleChange}
                placeholder={this.context.t(
                  "Enter username or email you want to use",
                )}
              />
              <Form.Control.Feedback type="invalid">
                {this.context.t(
                  "Please enter username or email you want to use",
                )}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label> {this.context.t("Password")} </Form.Label>
              <Form.Control
                name="password"
                type={this.state.showPassword ? "text" : "password"}
                style={{ borderRadius: "60px", fontSize: "0.8rem" }}
                required
                className={this.state.errors.password && "warning"}
                onChange={this.handleChange}
                placeholder={this.context.t("Enter your password")}
              />
              <Form.Text style={{ color: "#FF6666" }}>
                {this.context.t(
                  "Password must be at least 8 characters long, requiring at least 1 letter (a-z) and 1 number (0-9).",
                )}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {this.context.t("Please enter your password")}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="password_confirm">
              <Form.Label> {this.context.t("Confirm Password")} </Form.Label>
              <Form.Control
                name="confirm_password"
                type={this.state.showPassword ? "text" : "password"}
                required
                style={{ borderRadius: "60px", fontSize: "0.8rem" }}
                className={this.state.errors.password && "warning"}
                onChange={this.ConfirmPasswordHandler}
                placeholder={this.context.t("Please confirm your password")}
              />
              {/* <Form.Control.Feedback type="invalid"> {this.context.t('Please Confirm your password')} </Form.Control.Feedback> */}
              <p
                id="confirm_password"
                style={{ color: "#dc3545", fontSize: "80%", display: "none" }}
              >
                {this.context.t("Please Confirm your password")}
              </p>
            </Form.Group>
            <Form.Group controlId="show_password">
              <Form.Check
                type="checkbox"
                label={this.context.t("Show Passwords")}
                checked={this.state.showPassword}
                onChange={this.toggleShowPassword}
              />
            </Form.Group>
            <PrivacyPolicyModal
              show={this.state.modals.privacyPolicy.show}
              onHide={() => this.hideModal("privacyPolicy")}
            />
            <MemberAgreementModal
              show={this.state.modals.memberAgreement.show}
              onHide={() => this.hideModal("memberAgreement")}
            />
            {this.state.error && (
              <Alert variant="danger">{this.state.error}</Alert>
            )}

            <TurnstileWidget
              formError={this.props.signup_error}
              updateToken={this.updateToken}
              updateTokenStatus={this.updateTokenStatus}
            />

            <Button
              className="mt-2"
              type="submit"
              disabled={
                this.state.loading ||
                this.state.tokenStatus !== TurnstileTokenStatus.SOLVED
              }
              style={{ width: "100%", borderRadius: "60px" }}
            >
              {this.state.loading ||
              this.state.tokenStatus !== TurnstileTokenStatus.SOLVED
                ? this.context.t("Loading..")
                : this.context.t("Register")}
            </Button>
            <div className="mt-2">
              {this.context.t(
                "By clicking register, you agree to our {privacy_policy} and {member_agreement}.",
                {
                  privacy_policy: privacyPolicyLink,
                  member_agreement: memberAgreementLink,
                },
              )}
            </div>
          </Form>
          <Divider text={this.context.t("or")} className="mt-3" />
          <Row>
            <Col className="mt-2">
              <FacebookLogin
                textButton={this.context.t("Continue with Facebook")}
                appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                fields="name, email"
                icon="fa-facebook fa-lg"
                cssClass="facebook-button button-modified"
                callback={this.responseFacebook}
                onFailure={this.responseFacebookFailure}
                isMobile={false}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              {/* <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              buttonText={this.context.t('Continue with Google')}
              onSuccess={this.responseGoogle}
              onFailure={this.responseGoogleFailure}
              cookiePolicy={'single_host_origin'}
              className='google-button'
            /> */}
              <button
                type="button"
                className="login-with-google-btn-new button-modified"
                onClick={this.handleSignInClick}
              >
                {this.context.t("Continue with Google")}
              </button>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

JobseekersRegistrationForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
    signup_error: state.AuthenticationState.errors.signup,
    countriesItems: state.CountriesState.items,
    countriesFetched: state.CountriesState.fetched,
    nationalitiesItems: state.NationalitiesState.items,
    nationalitiesFetched: state.NationalitiesState.fetched,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authSignup: (type, payload) => dispatch(authSignup(type, payload)),
    authGoogle: (type, access_token) =>
      dispatch(authGoogle(type, access_token)),
    authFacebook: (type, access_token) =>
      dispatch(authFacebook(type, access_token)),
    getCountries: () => dispatch(getCountries()),
    getNationalities: () => dispatch(getNationalities()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobseekersRegistrationForm);
