import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyImage = (props) => (
  <div>
    <LazyLoadImage {...props} />
  </div>
);

export default LazyImage;
