import React, { Component } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Col, Row, Button } from "react-bootstrap";
import FacebookLogin from "react-facebook-login";

import BaseView from "./BaseView";
import {
  authGoogle,
  authFacebook,
} from "../common/redux/actions/AuthenticationActions";
import EmailUsernameLoginForm from "./EmailUsernameLoginForm";
import Divider from "../components/Divider";
import HeroImage from "../components/HeroImage";
import {
  getErrorMessage,
  getHeroImage,
  getTitleImage,
} from "../common/utilities/utilities";

class LoginView extends Component {
  componentDidMount() {
    if (!window.gapi) {
      console.error("Google API script is not loaded");
      return;
    }

    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    if (!clientId) {
      console.warn(
        "Google Client ID is missing. Google Auth2 will not be initialized.",
      );
      return;
    }

    window.gapi.load("auth2", () => {
      window.gapi.auth2
        .init({
          client_id: clientId,
          plugin_name: "google-app",
        })
        .then(() => {
          console.log("Google Auth2 initialized successfully");
        })
        .catch((error) => {
          console.error("Error initializing Google Auth2:", error);
        });
    });
  }

  onSignInFailure = (error) => {
    console.error(error);
  };

  handleGoogleLogin = () => {
    window.gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((el) => {
        let access_token = "";
        for (let pr in el) {
          if (el[pr].hasOwnProperty("access_token")) {
            access_token = el[pr]?.access_token;
          }
        }
        this.responseGoogle(access_token);
      });
  };

  handleTestGoogle = () => {
    this.props.authGoogle("JS", process.env.REACT_APP_TEST_GOOGLE_TOKEN);
  };

  handleTestFacebook = () => {
    this.props.authFacebook("JS", process.env.REACT_APP_TEST_FACEBOOK_TOKEN);
  };

  responseGoogle = (response) => {
    this.props.authGoogle("JS", response);
  };

  responseFacebook = (response) => {
    this.props.authFacebook("JS", response.accessToken);
  };

  render() {
    return (
      <BaseView title={this.context.t("Login")} backurl="/">
        <Container fluid={true} style={{ padding: 0 }}>
          <HeroImage height="180px" imageURL={getHeroImage()}>
            <img
              src={getTitleImage()}
              alt=""
              style={{ width: 200, marginTop: "-12px" }}
            />
          </HeroImage>
          <Container style={{ marginTop: "-130px" }}>
            <Row className="mt-2">
              <Col>
                <FacebookLogin
                  textButton={this.context.t("Continue with Facebook")}
                  appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                  fields="name, email"
                  icon="fa-facebook fa-lg"
                  cssClass="facebook-button button-modified"
                  callback={this.responseFacebook}
                  onFailure={this.responseFacebookFailure}
                  isMobile={false}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <button
                  type="button"
                  className="login-with-google-btn-new button-modified"
                  onClick={this.handleGoogleLogin}
                >
                  {this.context.t("Continue with Google")}
                </button>
              </Col>
            </Row>
            <Divider text={this.context.t("or")} className="mt-4" />
            <Row className="mt-3">
              <Col>
                <EmailUsernameLoginForm />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {this.props.login_error && (
                  <p style={{ color: "red" }}>
                    {getErrorMessage(
                      this.props.login_error.message,
                      this.context,
                    )}
                  </p>
                )}
                {this.props.signup_error && (
                  <p style={{ color: "red" }}>
                    {getErrorMessage(
                      this.props.signup_error.message,
                      this.context,
                    )}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Link to="/" style={{ color: "white" }}>
                  {this.context.t("Continue browsing as guest")}
                </Link>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Link to="/forgot-password">
                  {this.context.t("Forgot Password?")}
                </Link>
              </Col>
            </Row> */}
            <Divider text={this.context.t("or")} className="mt-4" />
            <Row>
              <Link to="/registration/jobseeker">
                <p>{this.context.t("New to golden dreams?")}</p>
                <Button className="btn btn-primary gd-button button-modified">
                  {this.context.t("Click here to register")}
                </Button>
              </Link>
            </Row>
          </Container>
        </Container>
      </BaseView>
    );
  }
}

LoginView.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
    login_error: state.AuthenticationState.errors.login,
    signup_error: state.AuthenticationState.errors.signup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authGoogle: (type, access_token) =>
      dispatch(authGoogle(type, access_token)),
    authFacebook: (type, access_token) =>
      dispatch(authFacebook(type, access_token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
