import React, { useState, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Col, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import styles from "../../components/CommentReplyStyle";
import ReactionIcons from "../../components/ReactionIcons";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import { Online } from "react-detect-offline";

const CommentReply = (props, context) => {
  const comment = props.comment;
  const [reaction, setReaction] = useState({});
  useEffect(() => {
    getReactions();
  }, []);
  const handleClickReply = () => {
    const replyPayload = {
      text: comment.body,
      parent: comment.parent_comment,
      username: comment.user.username,
    };
    props.onClickReply(replyPayload);
  };
  const getReactions = (post_id) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${props.postId}/list-comment-likes/?comment=${comment.id}&lang=${props.lang}`,
      )
      .then((res) => {
        setReaction(res.data);
      })
      .catch((err) => console.log("viewed recently"));
  };
  const onLikeClick = (e, type) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/discussion-forum/posts/${props.postId}/react/?lang=${props.lang}`,
        {
          comment_id: comment.id,
          reaction_type: type.toUpperCase(),
        },
      )
      .then((response) => {
        setReaction(response.data);
      })
      .catch((error) => {
        console.error("Error:", error.message);
        // Handle any errors that occurred during the request
      });
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <Row>
        <Col xs={2} lg={1}></Col>
        <Col
          xs={2}
          lg={1}
          className="d-flex 
                        align-items-center 
                        justify-content-center"
        >
          <Image
            src={"/static/media/astronaut.png"}
            crossOrigin="anonymous"
            style={{ ...styles.senderavatar }}
            onError={addDefaultImageSrc}
          />
        </Col>
        <Col xs={8} lg={10}>
          <span style={{ textAlign: "left" }}>
            <h5
              style={{
                color: "#198474",
              }}
            >
              {comment.user.username}
            </h5>
            <p
              style={{
                color: "grey",
                fontSize: "0.8rem",
                marginTop: "-0.5rem",
              }}
            >
              {moment(comment.date).fromNow()}
            </p>
            <p style={{ ...styles.commenttext }}>{comment.body}</p>

            {/* Reaction area */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <ReactionIcons
                type="comment"
                makeReaction={onLikeClick}
                reactionSet={reaction}
              />
              <div>
                <Online>
                  <a onClick={handleClickReply} className="reply-button">
                    <i className="fa fa-reply"></i> {context.t("Reply")}{" "}
                  </a>
                </Online>
              </div>
            </div>
          </span>
        </Col>
      </Row>
    </div>
  );
};

CommentReply.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.i18nState.lang,
  };
};

export default connect(mapStateToProps)(CommentReply);
