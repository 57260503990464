import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { Button, Modal, Spinner } from "react-bootstrap";

import { updateUserNotificationSettings } from "../../common/redux/actions/UsersActions";
import { messaging } from "../../firebase";

export default function NotificationsModal(props, context) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.ProfileState.data);
  const [show, setShow] = useState(props.show || false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const handleNotificationConsent = async (consent) => {
    if (consent) {
      setLoading(true);
      try {
        await messaging.requestPermission();
        const token = await messaging.getToken();
        const payload = { firebase_token: token, firebase_enabled: true };
        await dispatch(updateUserNotificationSettings(profile.id, payload));
        setShow(false);
      } catch (err) {
        console.log("Unable to get permission to notify.", err);
      } finally {
        setLoading(false);
      }
    } else {
      setShow(false);
      const notifications = { consent, date: moment() };
      localStorage.setItem("notifications", JSON.stringify(notifications));
    }
  };

  return (
    <Modal show={show} centered onHide={() => handleNotificationConsent(false)}>
      <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>
          {context.t(
            "{appName} would like to send you notifications for the latest news and updates. Notifications may include alerts, sounds and icon badges.",
            { appName: <strong>{context.t("Golden Dreams")}</strong> }
          )}
        </p>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none", paddingTop: 0 }}>
        <Button
          variant="outline-secondary"
          onClick={() => handleNotificationConsent(false)}
        >
          {context.t("Block")}
        </Button>
        <Button
          variant="success"
          onClick={() => handleNotificationConsent(true)}
          disabled={loading}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" />
              &nbsp;{context.t("Allowing")}
            </>
          ) : (
            context.t("Allow")
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

NotificationsModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
