import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const JOB_BENEFITS_GET_START = "JOB_BENEFITS_GET_START";
export const JOB_BENEFITS_GET_SUCCESS = "JOB_BENEFITS_GET_SUCCESS";
export const JOB_BENEFITS_GET_FAIL = "JOB_BENEFITS_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getJobBenefitsStart = () => {
  return {
    type: JOB_BENEFITS_GET_START,
  };
};

export const getJobBenefitsSuccess = (jobBenefits) => {
  return {
    type: JOB_BENEFITS_GET_SUCCESS,
    jobBenefits: jobBenefits,
  };
};

export const getJobBenefitsFail = (error) => {
  return {
    type: JOB_BENEFITS_GET_FAIL,
    error: error,
  };
};

export const getJobBenefits = () => {
  return (dispatch, getState) => {
    dispatch(getJobBenefitsStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/benefits?lang=${language}`)
      .then((res) => {
        dispatch(getJobBenefitsSuccess(res.data));
        return getState().JobBenefitsState.items;
      })
      .catch((err) => {
        dispatch(getJobBenefitsFail(err));
        throw err;
      });
  };
};
