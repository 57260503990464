import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";

import { getCities } from "../../common/redux/actions/CitiesActions";
import { reactSelectDropdownStyle } from "../../common/styles/dropdowns";

const CitySelect = ({
  initialValue,
  placeholder,
  state,
  dependant,
  onChange,
  isSearchable,
  isMulti,
  isClearable,
}) => {
  const dispatch = useDispatch();
  const { items, fetched } = useSelector((state) => state.CitiesState);

  const [selectedCity, setSelectedCity] = useState(initialValue);

  useEffect(() => {
    if (!fetched) {
      dispatch(getCities());
    }
  }, [dispatch, fetched]);

  const cityOptions = useMemo(() => {
    return Object.values(items || {})
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ city, name, state }) => ({
        value: city,
        label: name,
        state,
      }));
  }, [items]);

  const cityFilteredOptions = useMemo(() => {
    if (dependant && state) {
      return cityOptions.filter((option) => option.state === state);
    }
    return cityOptions;
  }, [dependant, state, cityOptions]);

  useEffect(() => {
    setSelectedCity(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const currentCity = cityOptions.find(
      (option) => option.value === selectedCity,
    );

    if (currentCity && currentCity.state !== state) {
      setSelectedCity(null);
      if (onChange) onChange(null);
    }
  }, [state, cityOptions, selectedCity, onChange]);

  const handleCityChange = (selectedOption) => {
    const value = selectedOption?.value || null;
    setSelectedCity(value);
    if (onChange) onChange(value);
  };

  return (
    <Select
      name="city"
      options={cityFilteredOptions}
      value={
        cityOptions.find((option) => option.value === selectedCity) || null
      }
      placeholder={placeholder}
      onChange={handleCityChange}
      styles={reactSelectDropdownStyle}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isClearable={isClearable}
    />
  );
};

CitySelect.propTypes = {
  initialValue: PropTypes.any,
  placeholder: PropTypes.string,
  state: PropTypes.any,
  dependant: PropTypes.bool,
  onChange: PropTypes.func,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
};

export default CitySelect;
