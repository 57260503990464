import React from "react";
import "./StepIndicator.css";

const StepIndicator = ({ steps, currentStep }) => {
  return (
    <div className="step-indicator">
      {[...Array(steps).keys()].map((step) => (
        <div
          key={step}
          className={`step-circle ${step + 1 <= currentStep ? "active" : ""}`}
        >
          {step + 1}
        </div>
      ))}
    </div>
  );
};

export default StepIndicator;
