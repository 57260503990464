import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const GUIDE_CATEGORIES_GET_START = "GUIDE_CATEGORIES_GET_START";
export const GUIDE_CATEGORIES_GET_SUCCESS = "GUIDE_CATEGORIES_GET_SUCCESS";
export const GUIDE_CATEGORIES_GET_FAIL = "GUIDE_CATEGORIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getGuideCategoriesStart = () => {
  return {
    type: GUIDE_CATEGORIES_GET_START,
  };
};

export const getGuideCategoriesSuccess = (guideCategories) => {
  return {
    type: GUIDE_CATEGORIES_GET_SUCCESS,
    guideCategories: guideCategories,
  };
};

export const getGuideCategoriesFail = (error) => {
  return {
    type: GUIDE_CATEGORIES_GET_FAIL,
    error: error,
  };
};

export const getGuideCategories = () => {
  return (dispatch, getState) => {
    dispatch(getGuideCategoriesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/guide-categories?lang=${language}`)
      .then((res) => {
        dispatch(getGuideCategoriesSuccess(res.data));
        return getState().GuideCategoriesState.items;
      })
      .catch((err) => {
        dispatch(getGuideCategoriesFail(err));
        throw err;
      });
  };
};
