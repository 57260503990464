import {
  COMMENT_DELETE_SUCCESS,
  COMMENT_HIDE_SUCCESS,
} from "../actions/CommentHideDeleteAction";

const intialState = {
  comments: [],
  items: [],
};
const Deletereducer = (state = intialState, action) => {
  switch (action.type) {
    case COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    case COMMENT_HIDE_SUCCESS:
      return {
        ...state,
        items: action.payload,
      };
    default:
      return state;
  }
};

export default Deletereducer;
