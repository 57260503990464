import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Genders } from "../../common/utilities/choices";

const GenderSelect = (props, context) => {
  const genderOptions = Genders({}, context, true);

  const handleGenderChange = (event) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <Form.Group onChange={handleGenderChange} style={{ marginBottom: 0 }}>
      {genderOptions.map((gender) => (
        <Form.Check
          type="radio"
          name="gender"
          label={gender.label}
          id={`gender-${gender.value}`}
          value={gender.value}
          defaultChecked={props.initialValue === gender.value}
          inline
          key={gender.value}
        />
      ))}
    </Form.Group>
  );
};

GenderSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

GenderSelect.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
};

export default GenderSelect;
