import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

export default function useJobSearch(query, cursor) {
  const language = useSelector((state) => state.i18nState.lang);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const next = useRef(
    `${process.env.REACT_APP_API_V2_URL}/jobs?lang=${language}`,
  );

  useEffect(() => {
    setJobs([]);
    if (query && query != "") {
      next.current = `${process.env.REACT_APP_API_V2_URL}/jobs?lang=${language}&ordering=${query.sort}&industry=${query.industry}&search=${query.search}`;
    } else {
      next.current = `${process.env.REACT_APP_API_V2_URL}/jobs?lang=${language}`;
    }
  }, [query]);

  useEffect(() => {
    let cancel;
    setError(false);
    setLoading(true);
    axios({
      method: "GET",
      url: next.current,
      // params: params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setError(false);
        setLoading(false);
        let jobs = [];
        Object.keys(res.data.results).map((key) => {
          jobs.push(res.data.results[key]);
        });

        setJobs((prevJobs) => [...prevJobs, ...jobs]);

        if (res.data.next) {
          setHasMore(true);
          next.current = res.data.next;
        } else {
          setHasMore(false);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });

    return () => cancel();
  }, [query, cursor]);

  return { next, loading, error, jobs, hasMore };
}
