import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";
import { Space } from "antd";

import BaseView from "../BaseView";
import { getSurveys } from "../../common/redux/actions/SurveysActions";

import "antd/lib/select/style/css";
import FilterResultsNotFound from "../../components/FilterResultsNotFound";

export default function SurveysListView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();

  const profile = useSelector((state) => state.ProfileState);
  const surveys = useSelector((state) => state.SurveysState);

  useEffect(() => {
    !surveys.fetched && !surveys.loading && dispatch(getSurveys());
  }, []);

  return (
    <BaseView title={context.t("Surveys")} backurl="/">
      {surveys.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
          <Container
            className="pb-6"
            style={{ textAlign: "left", overflow: "hidden" }}
          >
            {surveys && surveys.items.length > 0 ? (
              surveys.items.map((survey) => (
                <Space direction="vertical">
                  <Button
                    variant="link"
                    onClick={() =>
                      history.push(`/surveys/${survey.identifier}`)
                    }
                  >
                    {survey.identifier}
                  </Button>
                </Space>
              ))
            ) : (
              <Container>
                <center>
                  <FilterResultsNotFound
                    text={context.t("Sorry, There are no surveys!")}
                  />
                </center>
              </Container>
            )}
          </Container>
        </Container>
      )}
    </BaseView>
  );
}

SurveysListView.contextTypes = {
  t: PropTypes.func.isRequired,
};
