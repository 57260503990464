import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Popover,
  Image as BootstrapImage,
} from "react-bootstrap";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import ProgressBar from "react-bootstrap/ProgressBar";
import MemberAgreementModal from "../StaticPages/MemberAgreementModal";
import PrivacyPolicyModal from "../StaticPages/PrivacyPolicyModal";
import { Genders } from "../../common/utilities/choices";
import { validateInput } from "../../common/utilities/validation";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getNationalities } from "../../common/redux/actions/NationalitiesActions";
import { getGuides } from "../../common/redux/actions/GuidesActions";
import { getNews } from "../../common/redux/actions/NewsActions";

import {
  updateProfile,
  deleteProfile,
} from "../../common/redux/actions/ProfileActions";
import { updateUserNotificationSettings } from "../../common/redux/actions/UsersActions";
import { authLogout } from "../../common/redux/actions/AuthenticationActions";
import { addDefaultImageSrc } from "../../common/utilities/utilities";
import { messaging, removeFCMToken } from "../../firebase";

import { reactSelectDropdownStyle } from "../../common/styles/dropdowns";
import { toast } from "react-toastify";
import CountryCodesSelect from "../../components/selects/CountryCodesSelect";

export default function JobseekersProfileView(props, context) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.ProfileState);
  const countries = useSelector((state) => state.CountriesState);
  const nationalities = useSelector((state) => state.NationalitiesState);
  const guides = useSelector((state) => state.GuidesState);
  const news = useSelector((state) => state.NewsState);
  const genders = Genders({}, context, true);
  const [nationality, setNationality] = useState(false);
  const updating = useSelector((state) => state.ProfileState.updating);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  let phone_updated = localStorage.getItem("phone_updated");
  phone_updated = JSON.parse(phone_updated);

  const [payload, setPayload] = useState({
    full_name: "",
    age: "",
    phone_number: "",
    country_code: undefined,
  });
  const [errors, setErrors] = useState({});

  const [borderColor, setBorderColor] = useState("blue");

  const calculateBorderColor = (score) => {
    if (score <= 30) {
      return "red";
    } else if (score > 30 && score <= 50) {
      return "orange";
    } else {
      return "green";
    }
  };

  const [notificationSettings, setNotificationSettings] = useState({});
  const [mediaPreload, setMediaPreload] = useState({
    value: localStorage.getItem("preload-media") || 0,
    loading: false,
  });

  const [modals, setModals] = useState({
    privacyPolicy: false,
    memberAgreement: false,
  });

  const [countryOptions, setCountryOptions] = useState([]);
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [progressBar, setProgressBar] = useState({ color: "danger", score: 0 });
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const ageRef = useRef();
  const genderRef = useRef();
  const countryRef = useRef();

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
    !nationalities.fetched && dispatch(getNationalities());
    !guides.fetched && dispatch(getGuides());
    !news.fetched && dispatch(getNews());
  }, []);

  useEffect(() => {
    if (progressBar.score) {
      let color = calculateBorderColor(progressBar.score);
      setBorderColor(color);
    }
  }, [progressBar]);

  useEffect(() => {
    setPayload({
      ...payload,
      full_name: profile.data.full_name || "",
      nationality: profile.data.nationality,
      country: profile.data.country,
      gender: profile.data.gender,
      age: profile.data.age || "",
      avatarURL: profile.data.avatar || "",
      phone_number: profile.data.phone_number || "",
      country_code: profile.data.country_code || "",
    });
    setNotificationSettings({
      ...notificationSettings,
      value: profile.data.firebase_enabled,
      loading: false, // Stop loading when the value is fetched
    });
  }, [profile.data]);

  useEffect(() => {
    const countryOptions = Object.keys(countries.items)
      .sort((a, b) =>
        countries.items[a].name > countries.items[b].name ? 1 : -1
      )
      .map((key) => {
        return {
          value: countries.items[key].country,
          label: countries.items[key].name,
        };
      });
    setCountryOptions(countryOptions);
  }, [countries]);

  useEffect(() => {
    const nationalityOptions = Object.keys(nationalities.items)
      .sort((a, b) =>
        nationalities.items[a].name > nationalities.items[b].name ? 1 : -1
      )
      .map((key) => {
        return {
          value: nationalities.items[key].nationality,
          label: nationalities.items[key].name,
        };
      });
    setNationalityOptions(nationalityOptions);
  }, [nationalities]);

  useEffect(() => {}, [nationalityOptions, countryOptions]);

  useEffect(() => {
    let score = 0;
    const normal_fields = [
      payload.full_name,
      payload.nationality,
      payload.country,
      payload.gender !== "A",
      payload.age,
      payload.phone_number,
      payload.country_code,
    ];
    const extra_fields = [payload.avatarURL];
    normal_fields.map((field) => {
      if (field) {
        score += 10;
      }
    });
    extra_fields.map((field) => {
      if (field) {
        score += 30;
      }
    });

    if (score <= 30) {
      setProgressBar({ score: score, color: "danger" });
    } else if (30 < score && score <= 50) {
      setProgressBar({ score: score, color: "warning" });
    } else {
      setProgressBar({ score: score, color: "success" });
    }
  }, [payload]);

  const handleChange = (event) => {
    setNationality(false);
    setPhoneNumberError(false);
    const name = event.target.name;
    const value = event.target.value;
    const country_code = payload.country_code;
    const error = validateInput(name, value, country_code);
    if (error && name === "phone_number") {
      return;
    }

    setPayload({ ...payload, [name]: value });
    setErrors({ ...errors, [name]: error });
  };

  const handleSelectChange = (data, event) => {
    setNationality(false);
    setPhoneNumberError(false);
    const name = event.name;
    const value = data.value;

    setPayload({ ...payload, [name]: value });
  };

  const photoHandler = (event) => {
    setPayload({
      ...payload,
      avatarURL: URL.createObjectURL(event.target.files[0]),
      avatar: event.target.files[0],
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (
        payload.phone_number === profile.data.phone_number &&
        payload.country_code === profile.data.country_code
      ) {
        let phone_updated = {
          user: profile.id,
          phone_updated: false,
          phone_number: payload.phone_number,
          country_code: payload.country_code,
        };
        await dispatch(
          updateProfile("JS", profile.data.id, {
            full_name: payload.full_name,
            gender: payload.gender,
            nationality: payload.nationality,
            country: payload.country,
            age: payload.age ? payload.age : null,
            avatar: payload.avatar,
          })
        );
        localStorage.setItem("phone_updated", JSON.stringify(phone_updated));
      } else {
        let phone_updated = {
          user: profile.id,
          phone_updated: true,
          phone_number: payload.phone_number,
          country_code: payload.country_code,
        };
        await dispatch(
          updateProfile("JS", profile.data.id, {
            full_name: payload.full_name,
            gender: payload.gender,
            nationality: payload.nationality,
            country: payload.country,
            age: payload.age ? payload.age : null,
            avatar: payload.avatar,
            phone_number: payload.phone_number,
            country_code: payload.country_code,
          })
        );
        localStorage.setItem("phone_updated", JSON.stringify(phone_updated));
      }
      if (payload.nationality === "" && payload.nationality === null) {
        setNationality(true);
        return;
      }

      if (payload.phone_number == "") {
        setPhoneNumberError(true);
        return;
      }
      localStorage.setItem("preload-media", mediaPreload.value);
      preloadMedia(parseInt(mediaPreload.value));
    } catch (error) {
      toast.error(
        context.t(
          "Verification Failed! The number you entered has already been verified with another user account. Check the number again."
        )
      );
    }
  };

  const handleLogout = () => {
    removeFCMToken();
    dispatch(authLogout());
    props.history.push("/");
    window.location.reload();
  };

  const profileDeleteConfirmation = (
    <Popover>
      <Popover.Title as="h6" style={{ color: "brown" }}>
        <strong>{context.t("Confirm Delete Your Profile?")}</strong>
      </Popover.Title>
      <Popover.Content>
        <p>
          {context.t(
            "By selecting the delete profile option, you will be deleting all of your information on Golden Dreams. Once you confirm, you will not be able to recover any of this information. Are you sure you want to delete your profile?"
          )}
        </p>
        <Row>
          <Col>
            <Button
              variant="outline-secondary"
              size="sm"
              className="button-modified"
              style={{ width: "100%" }}
              onClick={() => {
                setIsPopupOpen(false);
                document.body.click();
              }}
            >
              {context.t("Cancel")}
            </Button>
          </Col>
          <Col>
            <Button
              variant="danger"
              size="sm"
              style={{ width: "100%" }}
              onClick={() => confirmDeleteProfile()}
            >
              {context.t("Yes, Delete my profile")}
            </Button>
          </Col>
        </Row>
      </Popover.Content>
    </Popover>
  );

  const handleDeleteProfile = () => {
    setIsPopupOpen(true);
  };

  const confirmDeleteProfile = async () => {
    await dispatch(deleteProfile(profile.data.id));
    handleLogout();
  };

  const toggleNotifications = async (value) => {
    setNotificationSettings({
      ...notificationSettings,
      value: value,
      loading: true,
    });

    if (value) {
      try {
        await messaging.requestPermission();
        const token = await messaging.getToken();
        const payload = { firebase_token: token, firebase_enabled: true };
        await dispatch(
          updateUserNotificationSettings(profile.data.id, payload)
        );
        setNotificationSettings({ ...notificationSettings, loading: false });
      } catch (err) {
        console.log("Unable to get permission to notify.", err);
        setNotificationSettings({ ...notificationSettings, loading: false });
      }
    } else {
      const payload = { firebase_enabled: false };
      await dispatch(updateUserNotificationSettings(profile.data.id, payload));
      setNotificationSettings({ ...notificationSettings, loading: false });
    }
  };

  const preloadMedia = (level) => {
    setMediaPreload({ ...mediaPreload, loading: true });

    const imagePromise = (src) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.setAttribute("crossOrigin", "anonymous");
        image.onload = resolve();
        image.onerror = reject();
        image.src = src;
      });
    };

    const audioPromise = (src) => {
      return new Promise((resolve, reject) => {
        const audio = new Audio();
        audio.setAttribute("preload", "auto");
        audio.onload = resolve();
        audio.onerror = reject();
        audio.src = src;
      });
    };

    const videoPromise = (src) => {
      return new Promise((resolve, reject) => {
        const video = document.createElement("video");
        video.setAttribute("preload", "auto");
        video.onload = resolve();
        video.onerror = reject();
        video.src = src;
      });
    };

    const preloadGuidesImages = () => {
      return new Promise((resolve) => {
        return Promise.all(
          guides.items.map((guide) => {
            if (guide.image) {
              return imagePromise(guide.image);
            }
          })
        ).then(() => resolve());
      });
    };

    const preloadGuidesAudios = () => {
      return new Promise((resolve) => {
        return Promise.all(
          guides.items.map((guide) => {
            if (guide.audio) {
              return audioPromise(guide.audio);
            }
          })
        ).then(() => resolve());
      });
    };

    const preloadGuidesVideos = () => {
      return new Promise((resolve) => {
        return Promise.all(
          guides.items.map((guide) => {
            if (guide.video) {
              return videoPromise(guide.video);
            }
          })
        ).then(() => resolve());
      });
    };

    const preloadNewsImages = () => {
      return new Promise((resolve) => {
        return Promise.all(
          news.items.map((news) => {
            if (news.image) {
              return imagePromise(news.image);
            }
          })
        ).then(() => resolve());
      });
    };

    const preloadNewsAudios = () => {
      return new Promise((resolve) => {
        return Promise.all(
          news.items.map((news) => {
            if (news.audio) {
              return audioPromise(news.audio);
            }
          })
        ).then(() => resolve());
      });
    };

    const preloadNewsVideos = () => {
      return new Promise((resolve) => {
        return Promise.all(
          news.items.map((news) => {
            if (news.video) {
              return videoPromise(news.video);
            }
          })
        ).then(() => resolve());
      });
    };

    return new Promise((resolve) => {
      return Promise.all([
        preloadGuidesImages(),
        preloadNewsImages(),
        [1, 2].indexOf(level) >= 0 &&
          (preloadGuidesAudios(), preloadNewsAudios()),
        level === 2 && (preloadGuidesVideos(), preloadNewsVideos()),
      ]).then(() => resolve());
    }).finally(() => {
      setMediaPreload({ ...mediaPreload, loading: false });
      const toastId = toast.success("saved !");
      toast.dismiss(toastId);
    });
  };

  const unfocusInput = (e, ref) => {
    e.preventDefault();
    ref.current.focus();
  };

  const handleCountryCodeChange = (countryCode) => {
    setPayload({
      ...payload,
      phone_number: "",
      country_code: "+" + countryCode,
    });
  };

  return (
    <div style={{ height: "100%" }}>
      {countries.loading ||
      nationalities.loading ||
      profile.loading ||
      guides.loading ||
      news.loading ||
      mediaPreload.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container style={{ textAlign: "left" }} className="mt-3">
          <Col style={{ textAlign: "center" }}>
            <label htmlFor="avatar-input">
              <BootstrapImage
                src={
                  payload.avatarURL ||
                  profile.data.avatar ||
                  "/static/media/ImagePlaceholder.png"
                }
                crossOrigin="anonymous"
                style={{
                  width: "120px",
                  height: "120px",
                  borderRadius: "100%",
                  objectFit: "cover",
                  marginTop: "7px",
                  border: "2px solid",
                  borderColor: borderColor,
                }}
                onError={addDefaultImageSrc}
              />
              <div>
                <i className="fa-solid fa-circle-check verified"></i>&nbsp;
                <strong>{context.t("Verified")}</strong>
              </div>
            </label>
            <input
              id="avatar-input"
              type="file"
              name="avatar"
              onChange={photoHandler}
              style={{ display: "none" }}
            />
          </Col>
          <div className="mt-1">
            <p style={{ margin: 0 }}> {context.t("Profile strength:")} </p>
            <ProgressBar
              now={progressBar.score}
              label={`${progressBar.score}%`}
              variant={progressBar.color}
              className="custom-progress-bar"
            />
          </div>
          <Form onSubmit={(e) => unfocusInput(e, ageRef)} className="mt-3">
            <Form.Group controlId="formBasicName">
              <Form.Label> {context.t("Name")} </Form.Label>
              <Form.Control
                className="button-modified"
                name="full_name"
                value={payload.full_name}
                placeholder={context.t("Enter your full name")}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
          <Row>
            <Col style={{ paddingRight: 0 }}>
              <Form onSubmit={(e) => unfocusInput(e, genderRef)}>
                <Form.Group controlId="age">
                  <Form.Label> {context.t("Age")} </Form.Label>
                  <Form.Control
                    name="age"
                    value={payload.age}
                    onChange={handleChange}
                    as="input"
                    type="number"
                    className="button-modified"
                    ref={ageRef}
                    style={{ height: 30 }}
                    placeholder={context.t("Enter your age")}
                  ></Form.Control>
                </Form.Group>
              </Form>
            </Col>
            <Col>
              <Form.Group controlId="gender">
                <Form.Label> {context.t("Gender")} </Form.Label>
                <Select
                  name="gender"
                  ref={genderRef}
                  options={genders}
                  value={genders.filter((o) => o.value === payload.gender)[0]}
                  placeholder={context.t("Gender")}
                  onChange={handleSelectChange}
                  styles={reactSelectDropdownStyle}
                  isSearchable={false}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={6} lg={6}>
              <Form.Group controlId="nationality">
                {nationality ? (
                  <Form.Label style={{ color: "brown" }}>
                    {context.t("Please select nationality")}
                  </Form.Label>
                ) : (
                  <Form.Label> {context.t("Nationality")}</Form.Label>
                )}
                <Select
                  name="nationality"
                  options={nationalityOptions}
                  value={
                    nationalityOptions.filter(
                      (o) => o.value === payload.nationality
                    )[0]
                  }
                  placeholder={context.t("Nationality")}
                  style={{ textAlign: "center" }}
                  onChange={handleSelectChange}
                  styles={reactSelectDropdownStyle}
                  isSearchable={false}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <Form.Group controlId="country">
                <Form.Label> {context.t("Country of residence")} </Form.Label>
                <Select
                  name="country"
                  ref={countryRef}
                  options={countryOptions}
                  value={
                    countryOptions.filter((o) => o.value === payload.country)[0]
                  }
                  placeholder={context.t("Country")}
                  style={{ textAlign: "center" }}
                  onChange={handleSelectChange}
                  styles={reactSelectDropdownStyle}
                  isSearchable={false}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="phone-section ">
            <Col xs={12} sm={6} lg={6}>
              <Form.Label> {context.t("Select the country code")} </Form.Label>
              <CountryCodesSelect
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                placeholder={context.t("Select")}
                initialValue={payload.country_code}
                onChange={(country_code) => {
                  handleCountryCodeChange(country_code);
                }}
              />
            </Col>
            <Col xs={12} sm={6} lg={6}>
              <Form.Group>
                {phoneNumberError ? (
                  <Form.Label style={{ color: "brown" }}>
                    {context.t("Please enter valid phone number!")}
                  </Form.Label>
                ) : (
                  <Form.Label> {context.t("Phone Number")} </Form.Label>
                )}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-disabled">
                      {context.t(
                        "Please remove any leading zeros when entering your phone number. For example, if your phone number is 0827474736, enter it as 827474736."
                      )}
                    </Tooltip>
                  }
                >
                  <div className="input-with-icon">
                    <Form.Control
                      id="phone_number"
                      name="phone_number"
                      value={payload.phone_number}
                      className="button-modified"
                      placeholder={context.t("Enter your phone number")}
                      onChange={handleChange}
                    />
                    <i className="fa-solid fa-circle-check input-icon verified"></i>
                  </div>
                </OverlayTrigger>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group controlId="cache">
            <Form.Label>
              {context.t("Preload media for offline use")}
            </Form.Label>
            <div>
              <ToggleButtonGroup
                type="radio"
                name="cache-settings"
                size="xs"
                value={parseInt(mediaPreload.value)}
                onChange={(value) =>
                  setMediaPreload({ ...mediaPreload, value: value })
                }
                style={{ zIndex: 0 }}
              >
                <ToggleButton variant="outline-primary" value={0}>
                  {context.t("No preload")}
                </ToggleButton>
                <ToggleButton variant="outline-primary" value={1}>
                  {context.t("Audio")}
                </ToggleButton>
                <ToggleButton variant="outline-primary" value={2}>
                  {context.t("Audio + Video")}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Form.Group>

          {!messaging ||
          ("serviceWorker" in navigator && !"PushManager" in window) ? (
            <div>
              Notification feature is supported only in:
              <br />
              Chrome Desktop and Mobile (version 50+)
              <br />
              Firefox Desktop and Mobile (version 44+)
              <br />
              Opera on Mobile (version 37+)
            </div>
          ) : (
            <Form.Group controlId="notifications">
              <Form.Label>
                {context.t("Enable notifications:")}
                {notificationSettings.loading && (
                  <Spinner animation="border" size="sm" className="ml-2" />
                )}
              </Form.Label>
              <ToggleButtonGroup
                type="radio"
                name="notifications-settings"
                size="xs"
                style={{ display: "block", zIndex: 0 }}
                value={notificationSettings.value}
                onChange={(value) => toggleNotifications(value)}
              >
                <ToggleButton variant="outline-primary" value={false}>
                  {context.t("Off")}
                </ToggleButton>
                <ToggleButton variant="outline-primary" value={true}>
                  {context.t("On")}
                </ToggleButton>
                &nbsp;&nbsp;
                <i className="fas fa-bell notification-circle"></i>
              </ToggleButtonGroup>
            </Form.Group>
          )}

          <Row>
            <Col>
              {isPopupOpen}
              <OverlayTrigger
                trigger="click"
                placement="top"
                overlay={profileDeleteConfirmation}
                rootClose={true}
              >
                <Button
                  className="button-width button-outlined"
                  variant="outline-danger"
                  onClick={handleDeleteProfile}
                >
                  {context.t("Delete Profile")}
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={12}>
              <a
                href="#privacy-policy"
                onClick={() => setModals({ ...modals, privacyPolicy: true })}
              >
                {context.t("Privacy policy")}
              </a>
            </Col>
            <Col xs={12}>
              <a
                href="#member-agreement"
                onClick={() => setModals({ ...modals, memberAgreement: true })}
              >
                {context.t("Member agreement")}
              </a>
            </Col>
          </Row>

          <Row className="mt-4" style={{ paddingBottom: "4em" }}>
            <Col xs={6}>
              <Button
                variant="danger"
                className="button-width button-modified"
                onClick={handleLogout}
              >
                {context.t("Logout")}
              </Button>
            </Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                className="button-width button-modified"
                onClick={handleSubmit}
              >
                {updating ? (
                  <>
                    <Spinner
                      size="sm"
                      animation="border"
                      role="status"
                    ></Spinner>
                    &nbsp;{context.t("Saving")}
                  </>
                ) : (
                  context.t("Save")
                )}
              </Button>
            </Col>
          </Row>

          <PrivacyPolicyModal
            show={modals.privacyPolicy}
            onHide={() => setModals({ ...modals, privacyPolicy: false })}
          />
          <MemberAgreementModal
            show={modals.memberAgreement}
            onHide={() => setModals({ ...modals, memberAgreement: false })}
          />
        </Container>
      )}
    </div>
  );
}

JobseekersProfileView.contextTypes = {
  t: PropTypes.func.isRequired,
};
