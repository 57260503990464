import { updateObject } from "../Utility";
import {
  MY_ROSTER_APPLICATIONS_GET_START,
  MY_ROSTER_APPLICATIONS_GET_SUCCESS,
  MY_ROSTER_APPLICATIONS_GET_FAIL,
  MY_ROSTER_APPLICATIONS_POST_START,
  MY_ROSTER_APPLICATIONS_POST_SUCCESS,
  MY_ROSTER_APPLICATIONS_POST_FAIL,
} from "../actions/SubmitResumeActions";

const initialState = {
  items: {},
  itemsArray: [],
  rosterApplications: {
    fetched: false,
    error: false,
    fetching: false,
    itemsArray: [],
  },
  fetching: false,
  posting: false,
  fetched: false,
  error: null,
};

// GET:
const getMyRosterApplicationsStart = (state, action) => {
  return updateObject(state, {
    ...state,
    rosterApplications: {
      fetched: false,
      fetching: true,
      error: false,
    },
  });
};

const getMyRosterApplicationsSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    rosterApplications: {
      fetched: true,
      fetching: false,
      error: false,
      itemsArray: action.myRosterApplications,
      items: action.myRosterApplications.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {}),
    },
  });
};

const getMyRosterApplicationsFail = (state, action) => {
  return updateObject(state, {
    ...state,
    rosterApplications: {
      fetching: false,
      error: true,
      error: action.error,
    },
  });
};

// POST:
const postMyRosterApplicationStart = (state, action) => {
  return updateObject(state, {
    ...state,
    posting: true,
  });
};

const postMyRosterApplicationSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: {
      ...state.items,
      [action.myRosterApplication.id]: action.myRosterApplication,
    },
    posting: false,
  });
};

const postMyRosterApplicationFail = (state, action) => {
  return updateObject(state, {
    posting: false,
  });
};

const SubmitResumeReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case MY_ROSTER_APPLICATIONS_GET_START:
      return getMyRosterApplicationsStart(state, action);
    case MY_ROSTER_APPLICATIONS_GET_SUCCESS:
      return getMyRosterApplicationsSuccess(state, action);
    case MY_ROSTER_APPLICATIONS_GET_FAIL:
      return getMyRosterApplicationsFail(state, action);

    // POST:
    case MY_ROSTER_APPLICATIONS_POST_START:
      return postMyRosterApplicationStart(state, action);
    case MY_ROSTER_APPLICATIONS_POST_SUCCESS:
      return postMyRosterApplicationSuccess(state, action);
    case MY_ROSTER_APPLICATIONS_POST_FAIL:
      return postMyRosterApplicationFail(state, action);

    default:
      return state;
  }
};

export default SubmitResumeReducer;
