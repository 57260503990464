import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BsClock } from "react-icons/bs";
import moment from "moment";
import { Link } from "react-router-dom";
import { Row, Col, Image, Badge } from "react-bootstrap";
import "react-rater/lib/react-rater.css";
import {
  addDefaultImageSrc,
  getRosterStatusMessage,
  ROSTER_STATUS_VARIANTS,
} from "../common/utilities/utilities";
import Rater from "react-rater";

export default function RecruiterRosterItem(props, context) {
  const profile = props.profile?.data;
  const [status, setStatus] = useState("");
  const application = props.application;
  const [expiredAt, setExpiredAt] = useState(
    application?.expired_at
      ? moment(application.expired_at).format("LL")
      : null,
  );
  const recruiter = props.agency;
  const recruiterCountry = props.agency?.address?.country_id;

  const border = {
    border: "1px solid gray",
    borderRadius: "5px",
    boxShadow: "0 5px 9px rgba(0, 0, 0, 0.2)",
    padding: "10px",
  };

  const variant = (application, recruiterCountry, profile) => {
    if (application?.id) return ROSTER_STATUS_VARIANTS.GREEN;
    return profile?.resume_country === recruiterCountry
      ? ROSTER_STATUS_VARIANTS.PURPLE
      : ROSTER_STATUS_VARIANTS.RED;
  };

  useEffect(() => {
    if (application?.id) {
      setStatus(getRosterStatusMessage("APPLIED", context));
      setExpiredAt(moment(application.expired_at).format("LL"));
    } else {
      const statusMessage =
        profile?.resume_country === recruiterCountry
          ? getRosterStatusMessage("APPLICABLE", context)
          : getRosterStatusMessage("NOT_APPLICABLE", context);

      setStatus(statusMessage);
    }
  }, [application]);

  return (
    <Link
      to={
        props.fromApplication
          ? `/jobs/${recruiter.id}?prev=/job-applications`
          : `/recruiter-roster/${recruiter.id}`
      }
    >
      <div className="Card mt-3" style={border}>
        <Row>
          <Col xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
            <Badge
              style={{
                fontSize: "12px",
                padding: "5px",
                backgroundColor: variant(
                  application,
                  recruiterCountry,
                  profile,
                ),
              }}
            >
              {status}
            </Badge>
            <hr style={{ borderTop: "1px solid #999" }} />
          </Col>
          <Col
            xs={12}
            lg={6}
            md={6}
            className="mt-3"
            style={{ textAlign: "center" }}
          >
            <Image
              src={
                recruiter.logo
                  ? recruiter.logo
                  : "/static/media/ImagePlaceholder.png"
              }
              crossOrigin="anonymous"
              style={{
                width: 100,
                height: 100,
                objectFit: "cover",
                border: "solid 1px gray",
                boxShadow: "0 5px 9px rgba(0, 0, 0, 0.2)",
              }}
              onError={addDefaultImageSrc}
              roundedCircle
            />
            <div>
              <Rater
                total={5}
                rating={recruiter.rating_score}
                interactive={false}
              />
              <span>
                {recruiter.rating_score && recruiter.rating_score.toFixed(1)}
              </span>
              <span
                style={{
                  position: "relative",
                  bottom: "7px",
                  fontSize: "0.7em",
                }}
              >
                ({recruiter.rating_count})
              </span>
            </div>
            <h3 style={{ marginBottom: 0 }}>{recruiter.name} </h3>
          </Col>
          <Col xs={12} lg={6} md={6}>
            <div className="mt-3">
              {recruiter.status === "Operational" && (
                <p style={{ marginBottom: 0, fontWeight: 600, color: "green" }}>
                  <i className="fa-regular fa-circle-check"></i>&nbsp;
                  {context.t("Operational")}
                </p>
              )}
              {recruiter.status === "Temporarily Suspended" && (
                <div
                  style={{ marginBottom: 0, fontWeight: 600, color: "orange" }}
                >
                  <i className="far fa-pause-circle"></i>
                  {context.t("Temporarily Suspended")}
                  {recruiter.status_from_date && (
                    <p
                      style={{
                        color: "black",
                        fontWeight: 600,
                        marginBottom: 0,
                      }}
                    >
                      {context.t("from: {date}", {
                        date: moment(recruiter.status_from_date).format("LL"),
                      })}
                    </p>
                  )}
                  {recruiter.status_to_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("to: {date}", {
                        date: moment(recruiter.status_to_date).format("LL"),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruiter.status === "Permanently Blacklisted" && (
                <div style={{ marginBottom: 0, fontWeight: 600, color: "red" }}>
                  <i className="fa-sharp fa-regular fa-circle-xmark"></i>
                  {context.t("Permanently Blacklisted")}
                  {recruiter.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruiter.status_from_date).format("LL"),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruiter.status === "License is not extended" && (
                <div
                  style={{
                    marginBottom: 0,
                    fontWeight: 600,
                    color: "darkorange",
                  }}
                >
                  <i className="fas fa-id-badge"></i>
                  {context.t("License is not extended")}
                  {recruiter.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(recruiter.status_from_date).format("LL"),
                      })}
                    </p>
                  )}
                </div>
              )}
              {recruiter.thai_license_number && (
                <p style={{ marginBottom: 0 }}>
                  {context.t("Thai License Number")} :
                  {recruiter.thai_license_number}
                </p>
              )}
              <p style={{ marginBottom: 0 }}>
                {context.t("License number")}:
                {recruiter.license_number ? recruiter.license_number : "N/A"}
              </p>
              <p style={{ marginBottom: 0 }}>
                {context.t("Email")}:{" "}
                {recruiter.email ? recruiter.email : "N/A"}
              </p>
            </div>
          </Col>
          {application?.id && expiredAt && (
            <Col xs={12} lg={12} md={12} style={{ textAlign: "center" }}>
              <hr style={{ borderTop: "1px solid #999" }} />
              <p
                style={{
                  color: "brown",
                  display: "inline-block",
                  marginBottom: "0",
                  paddingBottom: "0",
                }}
              >
                {context.t("Your application will expire at")}
              </p>
              : <BsClock /> {expiredAt}
            </Col>
          )}
        </Row>
      </div>
    </Link>
  );
}

RecruiterRosterItem.contextTypes = {
  t: PropTypes.func.isRequired,
};
