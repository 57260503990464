import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const CURRENCIES_GET_START = "CURRENCIES_GET_START";
export const CURRENCIES_GET_SUCCESS = "CURRENCIES_GET_SUCCESS";
export const CURRENCIES_GET_FAIL = "CURRENCIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getCurrenciesStart = () => {
  return {
    type: CURRENCIES_GET_START,
  };
};

export const getCurrenciesSuccess = (currencies) => {
  return {
    type: CURRENCIES_GET_SUCCESS,
    currencies: currencies,
  };
};

export const getCurrenciesFail = (error) => {
  return {
    type: CURRENCIES_GET_FAIL,
    error: error,
  };
};

export const getCurrencies = () => {
  return (dispatch, getState) => {
    dispatch(getCurrenciesStart());

    return axios
      .get(`${process.env.REACT_APP_API_URL}/localization/currencies`)
      .then((res) => {
        dispatch(getCurrenciesSuccess(res.data));
        return getState().CurrenciesState.items;
      })
      .catch((err) => {
        dispatch(getCurrenciesFail(err));
        throw err;
      });
  };
};
