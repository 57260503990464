import { updateObject } from "../Utility";
import {
  VERIFIED_EMPLOYER_START,
  VERIFIED_EMPLOYER_FAIL,
  VERIFIED_EMPLOYER_SUCCESS,
} from "../actions/EmployersActions";

const initialState = {
  next: null,
  previous: null,
  count: 0,
  page_size: 0,
  items: [],
  loading: false,
  fetched: false,
  error: null,
};

// verified employers
export const getVerifiedEmployerSuccess = (state, action) => {
  return updateObject(state, {
    next: action.emp.next,
    previous: action.emp.previous,
    count: action.emp.count,
    page_size: action.emp.page_size,
    items: action.emp.results,
    loading: false,
    fetched: true,
    error: null,
  });
};

export const getVerifiedEmployerFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

export const getVerifiedEmployerStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const VerifiedEmployerReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFIED_EMPLOYER_START:
      return getVerifiedEmployerStart(state, action);
    case VERIFIED_EMPLOYER_SUCCESS:
      return getVerifiedEmployerSuccess(state, action);
    case VERIFIED_EMPLOYER_FAIL:
      return getVerifiedEmployerFail(state, action);

    default:
      return state;
  }
};

export default VerifiedEmployerReducer;
