import React, { useState } from "react";
import PropTypes from "prop-types";
import BaseView from "../BaseView";
import { Form, Alert, Container, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { passwordRegex } from "../../common/utilities/utilities";

export default function ResetPasswordForm(props, context) {
  const params = useParams();
  let token = Object.keys(params).length > 0 ? params[0] : "";
  const [formData, setFormData] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      formData.new_password !== formData.confirm_password
    ) {
      e.stopPropagation();
      if (formData.new_password !== formData.confirm_password) {
        setError(context.t("Passwords do not match."));
      } else {
        setError("");
      }
      setValidated(true);
      return;
    }

    if (!passwordRegex.test(formData.new_password)) {
      setError(
        context.t(
          "Password must be at least 8 characters long and include both letters (a-z) and numbers (0-9).",
        ),
      );
      setValidated(true);
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_V2_URL}/reset-password/${token}`,
        {
          new_password: formData.new_password,
          confirm_password: formData.confirm_password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Response:", response.data);
      toast.success(context.t("Password reset successfully!"));
      history.push("/login");
      setSuccess(true);
      setError("");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(context.t("Token expired / Bad Request!"));
      } else if (error.response && error.response.status === 404) {
        setError(context.t("No users found to reset password!"));
      } else {
        setError(context.t("There was an error resetting your password."));
      }
      toast.error(context.t("Failed to reset password!"));
      console.error("There was an error!", error);
      setSuccess(false);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BaseView title={context.t("Reset Password")} backurl="/login">
      <hr className="afterTopNavigationBar"></hr>
      <h3>{context.t("Reset Password")}</h3>
      <Container style={{ textAlign: "left" }}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="new_password">
            <Form.Label>{context.t("New Password")}</Form.Label>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder={context.t("Enter new password")}
              name="new_password"
              className="button-modified"
              value={formData.new_password}
              onChange={handleChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              {context.t("Please enter a new password.")}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="confirm_password">
            <Form.Label>{context.t("Confirm Password")}</Form.Label>
            <Form.Control
              type={showPassword ? "text" : "password"}
              className="button-modified"
              placeholder={context.t("Confirm new password")}
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              required
            />
            <Form.Text style={{ color: "#FF6666" }}>
              {context.t(
                "Password must be at least 8 characters long, requiring at least 1 letter (a-z) and 1 number (0-9).",
              )}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {context.t("Please confirm your new password.")}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="show_password">
            <Form.Check
              type="checkbox"
              label={context.t("Show Passwords")}
              checked={showPassword}
              onChange={toggleShowPassword}
            />
          </Form.Group>

          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}

          <Button
            style={{ width: "100%" }}
            variant="primary button-modified"
            type="submit"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading
              ? context.t("Processing...")
              : context.t("Confirm new password")}
          </Button>
        </Form>
      </Container>
    </BaseView>
  );
}

ResetPasswordForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
