import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { Container, Image, Button } from "react-bootstrap";
import { Checkbox, Col, Option, Radio, Row, Select, Space } from "antd";
import { toast } from "react-toastify";

import { Online } from "react-detect-offline";

import BaseView from "../BaseView";
import { getCountries } from "../../common/redux/actions/CountriesActions";
import { getStates } from "../../common/redux/actions/StatesActions";
import {
  getSurveys,
  postSurveyParticipation,
} from "../../common/redux/actions/SurveysActions";

import { addDefaultImageSrc } from "../../common/utilities/utilities";

import "antd/lib/select/style/css";

export default function SurveysDetailedView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();

  const surveyIdentifier = props.match.params.identifier;

  const countries = useSelector((state) => state.CountriesState);
  const profile = useSelector((state) => state.ProfileState);
  const states = useSelector((state) => state.StatesState);
  const surveys = useSelector((state) => state.SurveysState);

  const [survey, setSurvey] = useState(null);
  const [surveyAnswers, setSurveyAnswers] = useState({
    survey: null,
    answers: {},
  });
  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [requiredQuestions, setRequiredQuestions] = useState([]);
  const [skippedQuestions, setSkippedQuestions] = useState([]);

  useEffect(() => {
    !countries.fetched && dispatch(getCountries());
    !states.fetched && dispatch(getStates());
    !surveys.fetched && dispatch(getSurveys());
  }, []);

  useEffect(() => {
    if (!survey && surveys.fetched) {
      const matchedSurvey = surveys.items.filter(
        (survey) => survey.identifier == surveyIdentifier,
      )[0];
      if (matchedSurvey) {
        setSurvey(matchedSurvey);
        // if already participated redirect to the home page
        profile.data.surveys &&
          profile.data.surveys.includes(surveyIdentifier) &&
          props.history.push("/");
      } else {
        props.history.push("/not-found");
      }
    }
  }, [surveys.items]);

  // set the required questions to be used later in a validation
  useEffect(() => {
    if (survey) {
      setSurveyAnswers({ ...surveyAnswers, survey: survey.id });
      let requiredQuestionsIds = survey.questions
        .filter((q) => q.is_required)
        .map((q) => q.id);
      setRequiredQuestions(requiredQuestionsIds);
    }
  }, [survey]);

  // run validation each time answers change
  useEffect(() => {
    validate();
  }, [surveyAnswers, skippedQuestions]);

  // update which questions should be skipped when answers change
  useEffect(() => {
    skipQuestions();
  }, [surveyAnswers]);

  const skipQuestions = () => {
    let questionsToBeSkipped = [];

    Object.keys(surveyAnswers.answers).map((key) => {
      let questionId = parseInt(key);
      let answers = surveyAnswers.answers[questionId]; // user answers to this question

      if (answers) {
        let question = survey.questions.find(
          (question) => question.id == questionId,
        );
        // choices that have jump_to_question property
        let skipChoices = question.choices.filter(
          (choice) => choice.jump_to_question_order_number,
        );

        skipChoices.map((choice) => {
          // if user answers include the choice with "jump_to_question"
          // mark questions in between as "to be skipped"
          if (answers.includes(choice.id)) {
            for (
              let i = questionId + 1;
              i <= choice.jump_to_question_order_number - 1;
              i++
            ) {
              questionsToBeSkipped.push(i);
            }
          }
        });
      }
    });

    setSkippedQuestions(questionsToBeSkipped);
  };

  // values, because array is passed
  const handleCheckbox = (questionId, values) => {
    skipQuestions(questionId, values);

    setSurveyAnswers({
      ...surveyAnswers,
      answers: {
        ...surveyAnswers.answers,
        [questionId]: values,
      },
    });
  };

  // values, because array is passed
  const handleDropdown = (questionId, values) => {
    skipQuestions(questionId, values);

    setSurveyAnswers({
      ...surveyAnswers,
      answers: {
        ...surveyAnswers.answers,
        [questionId]: values,
      },
    });
  };

  // value, because single value is passed
  const handleRadio = (questionId, value) => {
    skipQuestions(questionId, [value]);

    setSurveyAnswers({
      ...surveyAnswers,
      answers: {
        ...surveyAnswers.answers,
        [questionId]: [value],
      },
    });
  };

  const submitSurvey = () => {
    // TODO: clear skipped question answers
    setSubmitted(true);

    if (validated) {
      dispatch(postSurveyParticipation(surveyAnswers, surveyIdentifier))
        .then((res) => {
          toast.success(
            context.t("Thank you for participating in the survey."),
          );
          history.push("/");
        })
        .catch((err) =>
          toast.error(
            context.t("The form couldn't be submitted due to the error"),
          ),
        );
    }
  };

  // check that all required questions were answered
  const validate = () => {
    let allAnswered = requiredQuestions
      .filter(
        (questionId) =>
          // filter out skipped questions
          !skippedQuestions.includes(questionId),
      )
      .every((questionId) =>
        surveyAnswers.answers[questionId]
          ? surveyAnswers.answers[questionId].length > 0
          : false,
      );
    setValidated(allAnswered);
  };

  // check if the question was answered
  const isNotAnswered = (questionId) => {
    return surveyAnswers.answers[questionId]
      ? surveyAnswers.answers[questionId].length < 1
      : true;
  };

  return (
    <BaseView title={context.t("Surveys")} backurl="/">
      {countries.loading || states.loading || surveys.loading ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <img
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        survey && (
          <Container className="afterTopNavigationBar" style={{ padding: 0 }}>
            <Container
              className="pb-6"
              style={{ textAlign: "left", overflow: "hidden" }}
            >
              <h5 className="mt-3" style={{ textAlign: "center" }}>
                {" "}
                {survey.translation.name}{" "}
              </h5>
              <p> {survey.translation.description} </p>

              {survey.questions
                .sort((a, b) => a.order_number - b.order_number)
                .map((question, i) => {
                  let options = [];

                  if (question.dynamic_choices_content_type_name) {
                    if (question.dynamic_choices_content_type_name == "state") {
                      options = Object.keys(states.items).map((key) => {
                        return {
                          value: states.items[key].state,
                          label: states.items[key].name,
                          country: states.items[key].country,
                        };
                      });
                    } else if (
                      question.dynamic_choices_content_type_name == "country"
                    ) {
                      options = Object.keys(countries.items).map((key) => {
                        return {
                          value: countries.items[key].country,
                          label: countries.items[key].name,
                        };
                      });
                    }
                  } else {
                    options = question.choices.map((choice) => {
                      return {
                        value: choice.id,
                        label: choice.translation.name,
                      };
                    });
                  }

                  // Filter dynamic choices based on the parent (.e.g states under specific country)
                  if (question.dynamic_choices_parent_content_type_name) {
                    options = options.filter(
                      (option) =>
                        option[
                          question.dynamic_choices_parent_content_type_name
                        ] == question.dynamic_choices_parent_id,
                    );
                  }

                  let answersRepresentation;

                  if (question.answers_representation == "CHECKBOX") {
                    answersRepresentation = (
                      <Checkbox.Group
                        onChange={(values) =>
                          handleCheckbox(question.id, values)
                        }
                      >
                        <Space direction="vertical">
                          {options.map((option, i) => (
                            <Checkbox key={i} value={option.value}>
                              {" "}
                              {option.label}{" "}
                            </Checkbox>
                          ))}
                        </Space>
                      </Checkbox.Group>
                    );
                  } else if (question.answers_representation == "DROPDOWN") {
                    answersRepresentation = (
                      <Select
                        showSearch={options.length > 10}
                        mode={question.multiple_answers_possible && "multiple"}
                        style={{ width: "100%" }}
                        onChange={(values) =>
                          handleDropdown(
                            question.id,
                            // convert value to array if not multiple select
                            question.multiple_answers_possible
                              ? values
                              : [values],
                          )
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {options.map((option, i) => (
                          <Select.Option key={i} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    );
                  } else if (question.answers_representation == "RADIO") {
                    answersRepresentation = (
                      <Radio.Group
                        onChange={(e) =>
                          handleRadio(question.id, e.target.value)
                        }
                      >
                        <Space direction="vertical">
                          {options.map((option, i) => (
                            <Radio key={i} value={option.value}>
                              {" "}
                              {option.label}{" "}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    );
                  }

                  return (
                    <div key={i} style={{ marginBottom: 16 }}>
                      <h5
                        style={{
                          color:
                            skippedQuestions.includes(question.id) && "grey",
                        }}
                      >
                        {question.order_number}. {question.translation.name} (
                        {context.t(
                          skippedQuestions.includes(question.id)
                            ? "skipped"
                            : question.is_required
                              ? "required"
                              : "optional",
                        )}
                        )
                      </h5>
                      {!skippedQuestions.includes(question.id) && (
                        <div>
                          {submitted &&
                            question.is_required &&
                            isNotAnswered(question.id) && (
                              <span style={{ color: "red" }}>
                                {" "}
                                {context.t(
                                  "The answer to this question is required.",
                                )}{" "}
                              </span>
                            )}
                          <div className="mt-3"> {answersRepresentation} </div>
                        </div>
                      )}
                    </div>
                  );
                })}

              {submitted && !validated && (
                <span style={{ color: "red" }}>
                  {" "}
                  {context.t(
                    "Please answer all the questions that require at least one answer",
                  )}{" "}
                </span>
              )}

              <Button
                style={{ width: "100%" }}
                onClick={submitSurvey}
                disabled={profile.data.type != "JS"}
              >
                {context.t("Submit answers")}
              </Button>
            </Container>
          </Container>
        )
      )}
    </BaseView>
  );
}

SurveysDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
