import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const JOB_POSITION_CATEGORIES_GET_START =
  "JOB_POSITION_CATEGORIES_GET_START";
export const JOB_POSITION_CATEGORIES_GET_SUCCESS =
  "JOB_POSITION_CATEGORIES_GET_SUCCESS";
export const JOB_POSITION_CATEGORIES_GET_FAIL =
  "JOB_POSITION_CATEGORIES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getJobPositionCategoriesStart = () => {
  return {
    type: JOB_POSITION_CATEGORIES_GET_START,
  };
};

export const getJobPositionCategoriesSuccess = (jobPositionCategories) => {
  return {
    type: JOB_POSITION_CATEGORIES_GET_SUCCESS,
    jobPositionCategories: jobPositionCategories,
  };
};

export const getJobPositionCategoriesFail = (error) => {
  return {
    type: JOB_POSITION_CATEGORIES_GET_FAIL,
    error: error,
  };
};

export const getJobPositionCategories = () => {
  return (dispatch, getState) => {
    dispatch(getJobPositionCategoriesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/job-position-categories?lang=${language}`,
      )
      .then((res) => {
        dispatch(getJobPositionCategoriesSuccess(res.data));
        return getState().JobPositionCategoriesState.items;
      })
      .catch((err) => {
        dispatch(getJobPositionCategoriesFail(err));
        throw err;
      });
  };
};
