import { updateObject } from "../Utility";
import {
  ADDRESSES_GET_START,
  ADDRESSES_GET_SUCCESS,
  ADDRESSES_GET_FAIL,
} from "../actions/AddressesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getAddressesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getAddressesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.addresses.reduce((obj, item) => {
      obj[item.id] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getAddressesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const AddressesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case ADDRESSES_GET_START:
      return getAddressesStart(state, action);
    case ADDRESSES_GET_SUCCESS:
      return getAddressesSuccess(state, action);
    case ADDRESSES_GET_FAIL:
      return getAddressesFail(state, action);

    default:
      return state;
  }
};

export default AddressesReducer;
