import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const CONTACTS_GET_START = "CONTACTS_GET_START";
export const CONTACTS_GET_SUCCESS = "CONTACTS_GET_SUCCESS";
export const CONTACTS_GET_FAIL = "CONTACTS_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getContactsStart = () => {
  return {
    type: CONTACTS_GET_START,
  };
};

export const getContactsSuccess = (contacts) => {
  return {
    type: CONTACTS_GET_SUCCESS,
    contacts: contacts,
  };
};

export const getContactsFail = (error) => {
  return {
    type: CONTACTS_GET_FAIL,
    error: error,
  };
};

export const getContacts = () => {
  return (dispatch, getState) => {
    dispatch(getContactsStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/contacts?lang=${language}`)
      .then((res) => {
        dispatch(getContactsSuccess(res.data));
        return getState().ContactsState.items;
      })
      .catch((err) => {
        dispatch(getContactsFail(err));
        throw err;
      });
  };
};
