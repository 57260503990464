import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { BsClock } from "react-icons/bs";
import moment from "moment";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Image,
  Carousel,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  getMyRosterApplications,
  postMyRosterApplication,
} from "../../common/redux/actions/SubmitResumeActions";
import { getLanguages } from "../../common/redux/actions/LanguagesActions";

import {
  withdrawRosterApplication,
  extendRosterApplication,
} from "../../common/redux/actions/WithdrawExtendActions";

import Address from "../../components/Address";
import CompanyContacts from "../../components/CompanyContacts";
import BaseView from "../BaseView";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import Rating from "../../components/Rating";
import DetailedRating from "../../components/DetailedRatings";
import { toast } from "react-toastify";
import "./EmployerRosterDetailedView.css";
import { useQuery } from "react-query";
import RosterApplicationModal from "../../components/Modal/RosterApplicationModal";
import RosterWithdrawConfirmationModal from "../../components/Modal/RosterWithdrawConfirmationModal";
import axios from "axios";

export default function EmployerRosterDetailedView(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [withdrawing, setWithdrawing] = useState(false);
  const [extending, setExtending] = useState(false);
  const [withdrew, setWithdrew] = useState(false);
  const [application, setApplication] = useState(null);
  const [showRateForm, setShowRateForm] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const profile = useSelector((state) => state.ProfileState.data);
  const rosterApplications = useSelector(
    (state) => state.SubmitResumeState.rosterApplications,
  );
  const { employer_id } = useParams();
  const [businessImages, setBusinessImages] = useState([]);
  const [employerName, setEmployerName] = useState("");
  const applicationStatus = [
    "APPLICANT_NA",
    "VIEWED",
    "APPLIED",
    "SHORTLISTED",
    "DOWNLOADED",
    "MOVED_GENERAL_WAITLIST",
  ];

  const language = useSelector((state) => state.i18nState.lang);
  const languages = useSelector((state) => state.LanguagesState);
  const [languageId, setLanguageId] = useState();
  const [description, setDescrption] = useState("");
  const [userResume, setUserResume] = useState(null);
  const [submittingResume, setSubmittingResume] = useState(false);
  const [cover, setCover] = useState(null);
  const [payload, setPayload] = useState({
    allowed_to_view_documents: false,
    employer: null,
    jobseeker: null,
    resume: null,
    remark: "",
  });

  const handleCloseWithdrawModal = () => {
    setShowWithdrawModal(!showWithdrawModal);
  };

  useEffect(() => {
    !rosterApplications.fetching &&
      !rosterApplications.fetched &&
      !languages.fetched &&
      languages.loading &&
      dispatch(getLanguages());
  }, []);

  useEffect(() => {
    if (profile?.id) {
      dispatch(getMyRosterApplications(profile.id));
    }
  }, [profile]);

  async function fetchEmployer(language, employer_id) {
    const response = await axios.get(
      `${process.env.REACT_APP_API_V2_URL}/employers/${employer_id}?lang=${language}`,
    );
    return response.data;
  }

  const { data: employerData, isLoading: isLoadingEmployer } = useQuery(
    ["employerDetail", employer_id],
    () => fetchEmployer(language, employer_id),
  );

  useEffect(() => {
    if (Object.keys(languages.items)?.length !== 0 && language) {
      let langObj = languages.itemsArray.find((lang) => lang.code === language);
      setLanguageId(langObj.id);
    }
  }, [languages, language]);

  useEffect(() => {
    if (
      languageId &&
      employerData &&
      Object.keys(employerData).length !== 0 &&
      employerData.translations &&
      employerData.translations.length > 0
    ) {
      let translatedObj = employerData.translations.find(
        (tran) => tran.language === languageId,
      );
      setDescrption(translatedObj ? translatedObj.description : "");
    }
  }, [languageId, employerData]);

  useEffect(() => {
    if (
      rosterApplications.fetched &&
      rosterApplications?.itemsArray?.length > 0 &&
      employer_id
    ) {
      rosterApplications.itemsArray.filter((application) => {
        if (application.employer == employer_id) {
          setApplication(application);
        }
      });
    }

    if (employerData?.bussiness_images?.length > 0) {
      let cover = employerData.bussiness_images.filter((image) => image.cover);
      setCover(cover[0]);
    }

    if (employerData?.bussiness_images?.length > 0) {
      let business_images = employerData.bussiness_images.filter(
        (image) => !image.cover,
      );
      setBusinessImages(business_images);
    }

    if (
      employerData &&
      Array.isArray(employerData.translations) &&
      employerData.translations.length > 0
    ) {
      setEmployerName(employerData.translations[0]?.name);
    }
  }, [rosterApplications, employer_id]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_V2_URL}/jobseekers/${profile.id}/resume`,
      )
      .then((res) => {
        if (res.data.resume) {
          setUserResume(res.data.resume);
          setPayload({
            ...payload,
            employer: employer_id,
            jobseeker: profile?.id,
            resume: res.data.resume.id,
          });
        }
      })
      .catch((err) => {
        console.error("Failed to fetch resume", err);
      });
  }, [employerData, profile]);

  const submitResume = (e) => {
    e.preventDefault();
    setSubmittingResume(true);
    dispatch(postMyRosterApplication(payload))
      .then((res) => {
        dispatch(getMyRosterApplications(profile?.id));
        setWithdrew(false);
        toast.success(context.t("Successfully sent the resume"));
      })
      .catch((err) => {
        toast.error(context.t("Couldn't sent the resume due to server error"));
      })
      .finally(() => setSubmittingResume(false));
  };

  const extendSpecificRosterApplication = (applicationId) => {
    setExtending(true);
    if (applicationId) {
      if (dispatch(extendRosterApplication(applicationId))) {
        setExtending(false);
        dispatch(getMyRosterApplications(profile?.id));
        toast.success(context.t("Successfully extended the application !"));
      }
    }
  };

  const checkStatus = () => {
    if (application && application.status) {
      if (application.status === "APPLIED") {
        return (
          <div style={{ marginBottom: "10px" }}>
            <Alert className="hover-effect" variant="info">
              {context.t("You already sent your cv to this employer")}
            </Alert>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowWithdrawModal(true);
              }}
              className="button-modified roster-extend-withdraw-buttons gd-red"
            >
              {context.t("Withdraw application")}
            </Button>
            <Button
              className="button-modified roster-extend-withdraw-buttons gd-green"
              onClick={extendSpecificRosterApplication.bind(
                this,
                application.id,
              )}
            >
              {extending
                ? context.t("Extending...")
                : context.t("Extend application")}
            </Button>
          </div>
        );
      } else if (application.status === "VIEWED") {
        return (
          <div style={{ marginBottom: "10px" }}>
            <Alert className="hover-effect" variant="success">
              {context.t("Employer viewed your application")}
            </Alert>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowWithdrawModal(true);
              }}
              className="button-modified roster-extend-withdraw-buttons gd-red"
            >
              {context.t("Withdraw application")}
            </Button>
            <Button
              className="button-modified roster-extend-withdraw-buttons gd-green"
              onClick={extendSpecificRosterApplication.bind(
                this,
                application.id,
              )}
            >
              {extending
                ? context.t("Extending...")
                : context.t("Extend application")}
            </Button>
          </div>
        );
      } else if (application.status === "DOWNLOAED") {
        return (
          <Alert className="hover-effect" variant="success">
            {context.t("Employer Downloaded your resume")}
          </Alert>
        );
      } else if (application.status === "MOVED_GENERAL_WAITLIST") {
        return (
          <div style={{ marginBottom: "10px" }}>
            <Alert className="hover-effect" variant="success">
              {context.t(
                "Employer Moved your application to General Waitlist. Please wait Until he/she will shortlist you again for future job post.",
              )}
            </Alert>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowWithdrawModal(true);
              }}
              className="button-modified roster-extend-withdraw-buttons gd-red"
            >
              {context.t("Withdraw application")}
            </Button>
            <Button
              className="button-modified roster-extend-withdraw-buttons gd-green"
              onClick={extendSpecificRosterApplication.bind(
                this,
                application.id,
              )}
            >
              {extending
                ? context.t("Extending...")
                : context.t("Extend application")}
            </Button>
          </div>
        );
      } else if (application.status === "SHORTLISTED") {
        return (
          <Alert className="hover-effect" variant="success">
            {context.t("Employer Shortlisted your application")}
          </Alert>
        );
      } else if (application.status === "APPLICANT_NA") {
        return (
          <Alert className="hover-effect" variant="danger">
            {context.t(
              "You cannot send your resume to this employer as you are not residing in same country with this employer",
            )}
          </Alert>
        );
      }
    }
  };

  const withdrawApplication = () => {
    setWithdrawing(true);
    setPayload({
      ...payload,
      allowed_to_view_documents: false,
    });
    setApplication({
      ...application,
      status: application && application.status,
    });
    dispatch(withdrawRosterApplication(application.id))
      .then((res) => {
        toast.success(context.t("Successfully withdrew the application !", 5));
        setWithdrawing(false);
        setWithdrew(true);
        dispatch(getMyRosterApplications(profile?.id));
        setShowWithdrawModal(false);
      })
      .catch((err) => {
        toast.error(
          context.t("Couldn't withdraw the application due to server error"),
        );
      })
      .finally(() => setSubmittingResume(false));
  };

  const checkApplicableCountry = (employer, profile) => {
    return (
      (employer?.addresses?.[0]?.country_id &&
        userResume?.country &&
        employer.addresses[0].country_id === userResume.country) ||
      false
    );
  };

  useEffect(() => {
    if (withdrew) {
      dispatch(getMyRosterApplications(profile?.id));
    }
  }, [withdrew, dispatch, profile?.id]);

  return (
    <BaseView title={context.t("Employer Detail")} backurl="/employer-roster">
      <hr className="afterTopNavigationBar"></hr>
      {isLoadingEmployer || !employerData ? (
        <div style={{ display: "flex", height: "calc(100% - 118px)" }}>
          <Image
            src={"/static/media/spinner.png"}
            alt=""
            className="LoadingSpinner"
          />
        </div>
      ) : (
        <Container>
          <Card style={{ textAlign: "left" }}>
            <Card.Header>
              {application && !withdrew && application.expired_at && (
                <Card.Title>
                  <center>
                    <p
                      style={{
                        color: "brown",
                        display: "inline-block",
                        marginBottom: "0",
                        paddingBottom: "0",
                      }}
                    >
                      {context.t("Your application will expire at")}&nbsp;
                    </p>
                    : <BsClock />
                    &nbsp;
                    {application && moment(application.expired_at).format("LL")}
                  </center>
                </Card.Title>
              )}
              <div className="profile-cover-container mb-2">
                {cover?.image && (
                  <Image
                    className="cover-image"
                    variant="top"
                    src={cover.image}
                  />
                )}
                {!cover && <h3>{employerName}</h3>}
                <div className="profile-container">
                  <Image
                    className="profile-img"
                    src={
                      employerData.logo
                        ? employerData.logo
                        : "/static/media/ImagePlaceholder.png"
                    }
                  />
                </div>
              </div>
            </Card.Header>
            <Card.Body className="mt-5">
              <h3>{employerName}</h3>
              {employerData.status === "Operational" && (
                <p className="operational">
                  <i className="fa-regular fa-circle-check"></i>&nbsp;
                  {context.t("Operational")}
                </p>
              )}
              {employerData.status === "Temporarily Suspended" && (
                <div className="temporarily-suspended">
                  <i className="far fa-pause-circle"></i>
                  {context.t("Temporarily Suspended")}
                  {employerData.status_from_date && (
                    <p
                      style={{
                        color: "black",
                        fontWeight: 600,
                        marginBottom: 0,
                      }}
                    >
                      {context.t("from: {date}", {
                        date: moment(employerData.status_from_date).format(
                          "LL",
                        ),
                      })}
                    </p>
                  )}
                  {employerData.status_to_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("to: {date}", {
                        date: moment(employerData.status_to_date).format("LL"),
                      })}
                    </p>
                  )}
                </div>
              )}
              {employerData.status === "Permanently Blacklisted" && (
                <div className="permanently-blacklisted">
                  <i className="fa-sharp fa-regular fa-circle-xmark"></i>
                  {context.t("Permanently Blacklisted")}
                  {employerData.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(employerData.status_from_date).format(
                          "LL",
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {employerData.status === "License is not extended" && (
                <div className="license-is-not-extended">
                  <i className="fas fa-id-badge"></i>
                  {context.t("License is not extended")}
                  {employerData.status_from_date && (
                    <p style={{ color: "black", fontWeight: 600 }}>
                      {context.t("from: {date}", {
                        date: moment(employerData.status_from_date).format(
                          "LL",
                        ),
                      })}
                    </p>
                  )}
                </div>
              )}
              {description && (
                <p style={{ margin: 0 }}>
                  <strong>{context.t("Description")}:</strong> {description}
                </p>
              )}
              {employerData.date_of_registration && (
                <p style={{ marginBottom: 0 }}>
                  <span style={{ fontWeight: 600 }}>
                    {context.t("Registered:")}
                  </span>
                  {moment(employerData.date_of_registration).format("LL")}
                </p>
              )}
              {employerData.license_number && (
                <p style={{ marginBottom: 0 }}>
                  <span style={{ fontWeight: 600 }}>
                    {context.t("License number:")}
                  </span>
                  {employerData.license_number}
                </p>
              )}
              {employerData.license_valid_date && (
                <p>
                  <span style={{ fontWeight: 600 }}>
                    {context.t("Valid to:")}
                  </span>
                  {moment(employerData.license_valid_date).format("LL")}
                </p>
              )}
              <div className="mt-3">
                <h5>{context.t("Contact Information")} </h5>
                <CompanyContacts
                  website={employerData.website}
                  email={employerData.email}
                  phone_numbers={employerData.phone_numbers}
                  facebook={employerData.facebook}
                  viber={employerData.viber}
                  line={employerData.line}
                />
              </div>
              {employerData.rating_score && (
                <>
                  <hr />
                  <Rater
                    total={5}
                    rating={employerData.rating_score}
                    interactive={false}
                  />
                  <span>
                    {employerData.rating_score &&
                      employerData.rating_score.toFixed(1)}
                  </span>
                  <span
                    style={{
                      position: "relative",
                      bottom: "7px",
                      fontSize: "0.7em",
                    }}
                  >
                    ({employerData.rating_count})
                  </span>
                </>
              )}
              {employerData.rating_count && (
                <p className="rated-by-text" style={{ textAlign: "center" }}>
                  {context.t("Rated by ({number}) viewers", {
                    number: employerData.rating_count,
                  })}
                  &nbsp;&nbsp;<i className="fa-regular fa-circle-check"></i>
                </p>
              )}
              {showRateForm ? (
                <Rating
                  group="Employer"
                  object_id={employer_id}
                  content_type="employer profile"
                  userRating={employerData.user_ratings}
                  onSubmit={() => setShowRateForm(false)}
                />
              ) : (
                <DetailedRating
                  overall_score={employerData.rating_score}
                  scores_for_each_criteria={
                    employerData.scores_for_each_criteria
                  }
                />
              )}
              {Array.isArray(employerData.addresses) &&
              employerData.addresses.length > 0 ? (
                <div>
                  <hr />
                  <h3>{context.t("Address")}</h3>
                  {employerData.addresses.map((address, i) => (
                    <Address
                      key={i}
                      name_en={employerData.name_en}
                      street={address.street}
                      city={address.city_id}
                      state={address.state_id}
                      country={address.country_id}
                      zip={address.zip}
                      addressContacts={address.contacts}
                    />
                  ))}
                </div>
              ) : (
                <p className="mt-3">
                  {context.t("No Focal Person contact provided")}
                </p>
              )}
            </Card.Body>
            {businessImages.length > 0 && (
              <Card.Footer>
                <>
                  <Carousel>
                    {businessImages.map((employerImage) => {
                      return (
                        <Carousel.Item key={employerImage.id}>
                          <Image
                            className="d-block w-100"
                            src={employerImage.image}
                            alt={employerData.name}
                          />
                          <Carousel.Caption>
                            <strong>
                              <p>{employerName}</p>
                            </strong>
                          </Carousel.Caption>
                        </Carousel.Item>
                      );
                    })}
                  </Carousel>
                </>
              </Card.Footer>
            )}
            <Container style={{ textAlign: "center" }}>
              <Row>
                <Col>
                  {userResume ? (
                    checkApplicableCountry(employerData, profile) ? (
                      !withdrew &&
                      application &&
                      applicationStatus.includes(application.status) ? (
                        checkStatus()
                      ) : (
                        <RosterApplicationModal
                          type="employer"
                          payload={payload}
                          setPayload={setPayload}
                          submitResume={submitResume}
                          submittingResume={submittingResume}
                        />
                      )
                    ) : (
                      <Alert className="hover-effect" variant="danger">
                        {context.t(
                          "You can not sent your resume for this employer",
                        )}
                      </Alert>
                    )
                  ) : (
                    <Alert
                      className="hover-effect"
                      variant="warning"
                      onClick={() => history.push("/resume")}
                      style={{ cursor: "pointer" }}
                    >
                      {context.t("Click here to create a resume")}
                    </Alert>
                  )}
                </Col>
              </Row>
            </Container>
          </Card>
          <RosterWithdrawConfirmationModal
            withdrawing={withdrawing}
            show={showWithdrawModal}
            businessName={employerName}
            handleClose={handleCloseWithdrawModal.bind(this)}
            handleWithdraw={withdrawApplication.bind(this)}
          />
        </Container>
      )}
    </BaseView>
  );
}

EmployerRosterDetailedView.contextTypes = {
  t: PropTypes.func.isRequired,
};
