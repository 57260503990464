import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, FormControl, Button } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

export default function MessageInput(props, context) {
  const inputRef = useRef();

  const handleSendMessage = (event) => {
    event.preventDefault();
    props.onSendMessage();
    inputRef.current.innerHTML = ""; // clear innerHTML text to display placeholder
  };

  return (
    <div>
      <div className="userInput">
        <span
          ref={inputRef}
          data-content={props.placeholder}
          className="commentInput align-items-center"
          role="textbox"
          contentEditable
          onInput={props.onChangeMessage}
          suppressContentEditableWarning={true}
          style={{
            padding: "8px 42px 8px 8px",
            borderRadius: "16px",
            margin: "12px 0",
          }}
        ></span>
        <Button
          variant="link"
          style={{
            position: "absolute",
            bottom: 15,
            right: 14,
            fontSize: 13,
            fontWeight: 500,
          }}
          disabled={!props.value}
          onClick={handleSendMessage}
        >
          {context.t("Send")}
        </Button>
      </div>
    </div>
  );
}

MessageInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

MessageInput.defaultProps = {
  isReply: false,
};
