import axios from "axios";

// zawgyi-unicode converter
const Rabbit = require("rabbit-node");

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const GROUPS_TOPICS_GET_START = "GROUPS_TOPICS_GET_START";
export const GROUPS_TOPICS_GET_SUCCESS = "GROUPS_TOPICS_GET_SUCCESS";
export const GROUPS_TOPICS_GET_FAIL = "GROUPS_TOPICS_GET_FAIL";

export const GROUPS_QUESTION_POST_START = "GROUPS_QUESTION_POST_START";
export const GROUPS_QUESTION_POST_SUCCESS = "GROUPS_QUESTION_POST_SUCCESS";
export const GROUPS_QUESTION_POST_FAIL = "GROUPS_QUESTION_POST_FAIL";

export const GROUPS_QUESTION_ADD_COMMENT = "GROUPS_QUESTION_ADD_COMMENT";
export const GROUPS_QUESTION_ADD_CHILD_COMMENT =
  "GROUPS_QUESTION_ADD_CHILD_COMMENT";
export const GROUPS_QUESTION_DELETE_COMMENT = "GROUPS_QUESTION_DELETE_COMMENT";

export const GROUPS_QUESTION_VERIFICATION_START =
  "GROUPS_QUESTION_VERIFICATION_START";
export const GROUPS_QUESTION_VERIFICATION_SUCCESS =
  "GROUPS_QUESTION_VERIFICATION_SUCCESS";
export const GROUPS_QUESTION_VERIFICATION_FAIL =
  "GROUPS_QUESTION_VERIFICATION_FAIL";

export const GROUPS_QUESTION_REJECTION_START =
  "GROUPS_QUESTION_REJECTION_START";
export const GROUPS_QUESTION_REJECTION_SUCCESS =
  "GROUPS_QUESTION_REJECTION_SUCCESS";
export const GROUPS_QUESTION_REJECTION_FAIL = "GROUPS_QUESTION_REJECTION_FAIL";
// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getGroupsTopicsStart = () => {
  return {
    type: GROUPS_TOPICS_GET_START,
  };
};

export const getGroupsTopicsSuccess = (topics) => {
  return {
    type: GROUPS_TOPICS_GET_SUCCESS,
    topics: topics,
  };
};

export const getGroupsTopicsFail = (error) => {
  return {
    type: GROUPS_TOPICS_GET_FAIL,
    error: error,
  };
};

export const getGroupsTopics = () => {
  return (dispatch, getState) => {
    dispatch(getGroupsTopicsStart());
    const language = getState().i18nState.lang;
    return axios
      .get(`${process.env.REACT_APP_API_URL}/groups/topics?lang=${language}`)
      .then((res) => {
        let groups = res.data;

        // add unicode names to be used in the search later
        if (language === "mm-z") {
          groups = groups.map((group) => {
            return {
              ...group,
              name_mm_uni: Rabbit.zg2uni(group.name),
              description_mm_uni: Rabbit.zg2uni(group.description),
            };
          });
        }

        dispatch(getGroupsTopicsSuccess(groups));
        return getState().GroupsState.items;
      })
      .catch((err) => {
        dispatch(getGroupsTopicsFail(err));
        throw err;
      });
  };
};

// POST:
export const postGroupsQuestionStart = () => {
  return {
    type: GROUPS_QUESTION_POST_START,
  };
};

export const postGroupsQuestionSuccess = (question) => {
  return {
    type: GROUPS_QUESTION_POST_SUCCESS,
    question: question,
  };
};

export const postGroupsQuestionFail = (error) => {
  return {
    type: GROUPS_QUESTION_POST_FAIL,
    error: error,
  };
};

export const postGroupsQuestion = (payload) => {
  return (dispatch) => {
    console.log(payload);
    dispatch(postGroupsQuestionStart());

    axios
      .post(`${process.env.REACT_APP_API_URL}/groups/questions`, payload)
      .then((res) => {
        dispatch(postGroupsQuestionSuccess(res.data));
      })
      .catch((err) => {
        dispatch(postGroupsQuestionFail(err));
      });
  };
};

// ADD:
export const addTopicQuestionsComment = (comment) => {
  return {
    type: GROUPS_QUESTION_ADD_COMMENT,
    comment: comment,
  };
};

export const addTopicQuestionsChildComment = (comment) => {
  return {
    type: GROUPS_QUESTION_ADD_CHILD_COMMENT,
    comment: comment,
  };
};

// QUESTION VERIFICATION-REJECTION PROCESS

export const verifyQuestionStart = () => {
  return {
    type: GROUPS_QUESTION_VERIFICATION_START,
  };
};

export const verifyQuestionSuccess = (question) => {
  return {
    type: GROUPS_QUESTION_VERIFICATION_SUCCESS,
    question: question,
  };
};

export const verifyQuestionFail = (error) => {
  return {
    type: GROUPS_QUESTION_VERIFICATION_FAIL,
    error: error,
  };
};

export const verifyQuestion = (question_id) => {
  return (dispatch) => {
    dispatch(verifyQuestionStart());

    axios
      .put(`${process.env.REACT_APP_API_URL}/groups/questions/${question_id}`, {
        verified: true,
      })
      .then((res) => {
        console.log(res.data);
        dispatch(verifyQuestionSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(verifyQuestionFail(err));
      });
  };
};

export const rejectQuestionSuccess = (question_id, topic_id) => {
  return {
    type: GROUPS_QUESTION_REJECTION_SUCCESS,
    question_id: question_id,
    topic_id: topic_id,
  };
};

export const rejectQuestion = (question_id, topic_id) => {
  return (dispatch) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/groups/questions/${question_id}`,
      )
      .then((res) => {
        console.log(res.data);
        dispatch(rejectQuestionSuccess(question_id, topic_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
