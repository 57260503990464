import { updateObject } from "../Utility";
import {
  STATIC_PAGES_GET_START,
  STATIC_PAGES_GET_SUCCESS,
  STATIC_PAGES_GET_FAIL,
} from "../actions/StaticPagesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getStaticPagesStart = (state, actions) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getStaticPagesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.staticPages.reduce((obj, item) => {
      obj[item.name] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getStaticPagesFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const StaticPagesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case STATIC_PAGES_GET_START:
      return getStaticPagesStart(state, action);
    case STATIC_PAGES_GET_SUCCESS:
      return getStaticPagesSuccess(state, action);
    case STATIC_PAGES_GET_FAIL:
      return getStaticPagesFail(state, action);

    default:
      return state;
  }
};

export default StaticPagesReducer;
