import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Image } from "react-bootstrap";
import { Online } from "react-detect-offline";

import moment from "moment";

import CommentReply from "./CommentReply";
import CommentFlagModal from "./CommentFlagModal";
import Dropdown from "react-bootstrap/Dropdown";
import { addDefaultImageSrc, urlify } from "../common/utilities/utilities";
import axios from "axios";
import ReactionIcons from "./ReactionIcons";
import styles from "./CommentStyles";
import {
  hideComment,
  deleteComment,
} from "../common/redux/actions/CommentHideDeleteAction";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <sapn className="threedots"></sapn>
  </a>
));

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      showFlagModal: false,
      reactionSet: null,
      comment: this.props.comment.id,
      is_hide: props.comment.is_hide,
      reply_comments: this.props.comment.children,
    };
  }

  makeReaction = (e, type) => {
    e.preventDefault();
    this.handleReaction(type, this.props.comment.id);
  };

  handleClickReply = () => {
    const replyPayload = {
      text: this.props.comment.text,
      parent: this.props.comment.id,
      base_parent: this.props.comment.id,
      username: this.props.comment.sender_username,
      isModerator: this.props.comment.sender_is_moderator,
    };
    this.props.onClickReply(replyPayload);
  };

  handleSubmitReply = (payload) => {
    this.props.onSubmitReply(payload);

    this.setState({
      ...this.state,
      showInput: false,
    });
  };

  handleReaction = async (type, id) => {
    if (
      this.state.reactionSet.reaction_type !== null &&
      this.state.reactionSet.reaction_type !== undefined
    ) {
      await axios
        .put(
          `${process.env.REACT_APP_API_URL}/comment-reaction-update?type=comment`,
          { comment: id, reaction_type: type },
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/comment-reaction?type=comment`,
          { comment: id, reaction_type: type },
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    this.props?.profile?.id && this.getReactions();
  };

  handleClickFlag = () => {
    this.setState({
      ...this.state,
      showFlagModal: true,
    });
  };

  getReactions = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/comment-reaction-update?id=${this.state.comment}&type=comment`,
      )
      .then((res) => {
        this.setState({
          ...this.state,
          reactionSet: res.data,
        });
      })
      .catch((err) => console.log("viewed recently"));
  };

  setReaction = async (reaction_type) => {
    let localauth = JSON.parse(window.localStorage.getItem("auth"));
    if (localauth !== null) {
      if (
        this.state.reactionSet.reaction_type !== null &&
        this.state.reactionSet.reaction_type !== undefined
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_API_URL}/comment-reaction-update?type=comment`,
            { comment: reaction_type[0], reaction_type: reaction_type[1] },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/comment-reaction?type=comment`,
            { comment: reaction_type[0], reaction_type: reaction_type[1] },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      window.location = "/login";
    }
    this.props?.profile?.id && this.getReactions();
  };

  addRec = async (e) => {
    e.preventDefault();
    this.setReaction(e.target.id.split("-"));
    if (e.target.id.split("-")[1] === this.state.reactionSet.reaction_type) {
      document
        .getElementById(
          this.props.comment.id.toString() + "-" + e.target.id.split("-")[1],
        )
        .classList.remove(e.target.id.split("-")[1] + "2");
    }
  };

  componentDidMount() {
    this.props?.profile?.id && this.getReactions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.comment !== prevProps.comment) {
      this.setState({
        comment: this.props.comment.id,
        is_hide: this.props.comment.is_hide,
        reply_comments: this.props.comment.children,
      });
    }
  }

  removeReplyComment = (comment) => {
    const filteredArray = this.state.reply_comments.filter((item) => {
      return (
        item.id !== comment.id ||
        (item.id !== comment.id && item.parent == comment.id)
      );
    });

    this.setState({
      ...this.state,
      reply_comments: filteredArray,
    });
  };

  commentReplyActionsHandler = (comment, type) => {
    let { dispatch } = this.props;
    if (type == "delete") {
      this.removeReplyComment(comment);
      dispatch(deleteComment(comment.id));
    } else if (type == "hide") {
      this.removeReplyComment(comment);
      dispatch(hideComment(comment.id, true));
      dispatch(deleteComment(comment.id));
    }
  };

  render() {
    return (
      <div key={this.props.key}>
        {this.props.profile.type == "IS" ? (
          <div>
            {/* <hr style={{ ...styles.rowdesign }}/> */}
            {this.props.comment && (
              <div style={{ ...styles.commentContainer }}>
                <Row>
                  <Col xs={2}>
                    <Image
                      src={
                        this.props.comment.sender_is_moderator
                          ? "/static/media/moderator.png"
                          : this.props.comment.sender_avatar ||
                            "/static/media/astronaut.png"
                      }
                      crossOrigin="anonymous"
                      style={{ ...styles.senderavatar }}
                      onError={addDefaultImageSrc}
                    />
                  </Col>
                  <Col xs={10}>
                    <div style={{ ...styles.commentreply }}>
                      <div>
                        <h5
                          style={{
                            color:
                              this.props.comment.sender_is_moderator &&
                              "#198474",
                          }}
                        >
                          {this.props.comment.sender_is_moderator
                            ? this.context.t("moderator")
                            : this.props.comment.sender_username ||
                              this.context.t("Anonymous")}
                          {this.props.comment.sender_is_moderator && (
                            <i
                              className="fas fa-shield-alt fa-sm"
                              style={{ ...styles.iconstyle }}
                            ></i>
                          )}
                        </h5>
                        <p
                          style={{
                            color: "grey",
                            fontSize: "0.8rem",
                            marginTop: "-0.5rem",
                          }}
                        >
                          {" "}
                          {moment(this.props.comment.created_at).format(
                            "LLL",
                          )}{" "}
                        </p>
                      </div>
                      <div style={{ width: "fit-content" }}>
                        {this.props.profile.type == "IS" ? (
                          <div>
                            <Dropdown>
                              <Dropdown.Toggle
                                as={CustomToggle}
                                direction="right"
                                style={{ curser: "pointer" }}
                              />
                              <Dropdown.Menu size="sm" title="">
                                <Dropdown.Item
                                  style={{ width: "fit-content", color: "red" }}
                                  onClick={() =>
                                    this.props.commentActionsHandler(
                                      this.props.comment,
                                      "delete",
                                    )
                                  }
                                >
                                  {this.context.t("Delete comment")}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{ width: "fit-content" }}
                                  onClick={() =>
                                    this.props.commentActionsHandler(
                                      this.props.comment,
                                      "hide",
                                    )
                                  }
                                >
                                  {this.props.comment.is_hide == true ? (
                                    <span>
                                      {this.context.t("Unhide comment")}
                                    </span>
                                  ) : (
                                    <span>
                                      {this.context.t("Hide comment")}
                                    </span>
                                  )}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}></Col>
                  <Col xs={10}>
                    <p style={{ ...styles.commenttext }}>
                      {this.props.comment.sender_is_moderator ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: urlify(this.props.comment.text),
                          }}
                        />
                      ) : (
                        this.props.comment.text
                      )}
                    </p>

                    {/* Reaction area */}
                    <div style={{ ...styles.commentreply }}>
                      <ReactionIcons
                        type="comment"
                        makeReaction={this.makeReaction}
                        reactionSet={this.state.reactionSet}
                      />
                      <div>
                        {this.props.isAuthenticated && (
                          <Online>
                            <a
                              onClick={this.handleClickReply}
                              className="reply-button"
                            >
                              <i className="fa fa-reply"></i>{" "}
                              {this.context.t("Reply")}{" "}
                            </a>
                            <CommentFlagModal
                              show={this.props.showFlagModal}
                              comment={this.props.comment}
                              user={this.props.profile.id}
                            />
                          </Online>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                {this.state.reply_comments.map((comment) => {
                  return (
                    <CommentReply
                      key={comment?.text + comment.id}
                      commentReplyActionsHandler={
                        this.commentReplyActionsHandler
                      }
                      comment={comment}
                      onClickReply={this.props.onClickReply}
                      onSubmitReply={this.props.onSubmitReply}
                    />
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <>
            {this.props.comment && this.props.comment.is_hide == false ? (
              <>
                {this.props.comment && (
                  <div
                    key={this.props.comment.id}
                    style={{ ...styles.commentContainer }}
                  >
                    <Row>
                      <Col xs={2}>
                        <Image
                          src={
                            this.props.comment.sender_is_moderator
                              ? "/static/media/moderator.png"
                              : this.props.comment.sender_avatar ||
                                "/static/media/astronaut.png"
                          }
                          crossOrigin="anonymous"
                          style={{ ...styles.senderavatar }}
                          onError={addDefaultImageSrc}
                        />
                      </Col>
                      <Col xs={10}>
                        <div style={{ ...styles.commentreply }}>
                          <div>
                            <h5
                              style={{
                                color:
                                  this.props.comment.sender_is_moderator &&
                                  "#198474",
                              }}
                            >
                              {this.props.comment.sender_is_moderator
                                ? this.context.t("moderator")
                                : this.props.comment.sender_username ||
                                  this.context.t("Anonymous")}
                              {this.props.comment.sender_is_moderator && (
                                <i
                                  className="fas fa-shield-alt fa-sm"
                                  style={{ ...styles.iconstyle }}
                                ></i>
                              )}
                            </h5>
                            <p
                              style={{
                                color: "grey",
                                fontSize: "0.8rem",
                                marginTop: "-0.5rem",
                              }}
                            >
                              {" "}
                              {moment(this.props.comment.created_at).format(
                                "LLL",
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={2}></Col>
                      <Col xs={10}>
                        <p style={{ ...styles.commenttext }}>
                          {this.props.comment.sender_is_moderator ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: urlify(this.props.comment.text),
                              }}
                            />
                          ) : (
                            this.props.comment.text
                          )}
                        </p>

                        {/* Reaction area */}
                        <div style={{ ...styles.commentreply }}>
                          <ReactionIcons
                            type="comment"
                            makeReaction={this.makeReaction}
                            reactionSet={this.state.reactionSet}
                          />
                          <div>
                            {this.props.isAuthenticated && (
                              <Online>
                                <a
                                  onClick={this.handleClickReply}
                                  className="reply-button"
                                >
                                  <i className="fa fa-reply"></i>{" "}
                                  {this.context.t("Reply")}{" "}
                                </a>
                                <CommentFlagModal
                                  show={this.props.showFlagModal}
                                  comment={this.props.comment}
                                  user={this.props.profile.id}
                                />
                              </Online>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {this.props.comment.children.map((comment, index) => {
                      return (
                        <>
                          <CommentReply
                            key={index}
                            comment={comment}
                            onClickReply={this.props.onClickReply}
                            onSubmitReply={this.props.onSubmitReply}
                          />
                        </>
                      );
                    })}
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  }
}

Comment.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    profile: state.ProfileState.data,
    isAuthenticated: state.AuthenticationState.token != null,
    isHideOrDelete: state.commentHideDeleteState.isDeleted,
  };
};

export default connect(mapStateToProps)(Comment);
