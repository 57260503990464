import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const SERVICE_WORKER_UPDATE = "SERVICE_WORKER_UPDATE";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------
export const updateServiceWorker = (registration) => {
  return {
    type: SERVICE_WORKER_UPDATE,
    registration: registration,
  };
};
