import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const LANGUAGES_GET_START = "LANGUAGES_GET_START";
export const LANGUAGES_GET_SUCCESS = "LANGUAGES_GET_SUCCESS";
export const LANGUAGES_GET_FAIL = "LANGUAGES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getLanguagesStart = () => {
  return {
    type: LANGUAGES_GET_START,
  };
};

export const getLanguagesSuccess = (languages) => {
  return {
    type: LANGUAGES_GET_SUCCESS,
    languages: languages,
  };
};

export const getLanguagesFail = (error) => {
  return {
    type: LANGUAGES_GET_FAIL,
    error: error,
  };
};

export const getLanguages = () => {
  return (dispatch, getState) => {
    dispatch(getLanguagesStart());

    return axios
      .get(`${process.env.REACT_APP_API_URL}/localization/languages`)
      .then((res) => {
        dispatch(getLanguagesSuccess(res.data));
        return getState().LanguagesState.items;
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err));
        throw err;
      });
  };
};
