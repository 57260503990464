import { updateObject } from "../Utility";
import {
  STATES_GET_START,
  STATES_GET_SUCCESS,
  STATES_GET_FAIL,
} from "../actions/StatesActions";

const initialState = {
  items: {},
  loading: false,
  fetched: false,
  error: null,
};

// GET:
const getStatesStart = (state, actions) => {
  return updateObject(state, {
    ...state,
    loading: true,
    error: null,
  });
};

const getStatesSuccess = (state, action) => {
  return updateObject(state, {
    ...state,
    items: action.states.reduce((obj, item) => {
      obj[item.state] = item;
      return obj;
    }, {}),
    loading: false,
    fetched: true,
    error: null,
  });
};

const getStatesFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loading: false,
    error: action.error,
  });
};

const StatesReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET:
    case STATES_GET_START:
      return getStatesStart(state, action);
    case STATES_GET_SUCCESS:
      return getStatesSuccess(state, action);
    case STATES_GET_FAIL:
      return getStatesFail(state, action);

    default:
      return state;
  }
};

export default StatesReducer;
