import axios from "axios";

// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const STATES_GET_START = "STATES_GET_START";
export const STATES_GET_SUCCESS = "STATES_GET_SUCCESS";
export const STATES_GET_FAIL = "STATES_GET_FAIL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------

// GET:
export const getStatesStart = () => {
  return {
    type: STATES_GET_START,
  };
};

export const getStatesSuccess = (states) => {
  return {
    type: STATES_GET_SUCCESS,
    states: states,
  };
};

export const getStatesFail = (error) => {
  return {
    type: STATES_GET_FAIL,
    error: error,
  };
};

export const getStates = () => {
  return (dispatch, getState) => {
    dispatch(getStatesStart());
    const language = getState().i18nState.lang;
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/localization/states?lang=${language}`,
      )
      .then((res) => {
        dispatch(getStatesSuccess(res.data));
        return getState().StatesState.items;
      })
      .catch((err) => {
        dispatch(getStatesFail(err));
        throw err;
      });
  };
};
