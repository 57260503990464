import React, { Component } from "react";

import BaseView from "./BaseView";

class AppliedView extends Component {
  render() {
    return (
      <BaseView title="Applied" backurl="/" underConstruction>
        <p style={{ marginTop: 52 }}> I'm applied page </p>
      </BaseView>
    );
  }
}

export default AppliedView;
