import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { FormControl, Button, Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";

import { postCommentFlag } from "../common/redux/actions/CommentFlagsActions";

class CommentFlagModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        reason: "",
        user: this.props.user,
        comment: this.props.comment.id,
      },
      show: false,
    };
  }

  handleClickFlag = () => {
    this.setState({
      ...this.state,
      show: true,
    });
  };

  handleChangeReason = (event) => {
    this.setState({
      payload: {
        ...this.state.payload,
        reason: event.target.value,
      },
    });
  };

  handleCloseFlag = () => {
    this.setState({
      ...this.state,
      show: false,
    });
  };

  handleSubmitFlag = () => {
    this.props.postCommentFlag(this.state.payload);

    this.setState({
      payload: {
        ...this.state.payload,
        reason: "",
      },
      show: false,
    });
  };

  render() {
    return (
      <>
        <a onClick={this.handleClickFlag} className="flag-button">
          <i className="fa fa-flag"></i> {this.context.t("Report")}{" "}
        </a>
        <Modal show={this.state.show} onHide={this.handleCloseFlag} centered>
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "1rem" }}>
              {" "}
              <b> {this.context.t("Report inappropriate comment")}</b>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {" "}
              <b> {this.context.t("Text of a comment:")}</b>{" "}
              {this.props.comment.text}{" "}
            </p>
            <InputGroup className="mt-2">
              <FormControl
                name="reason"
                placeholder={this.context.t("Please write a reason...")}
                onChange={this.handleChangeReason}
                value={this.state.payload.text}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFlag}>
              {this.context.t("Close")}
            </Button>
            <Button
              variant="primary"
              onClick={this.handleSubmitFlag}
              disabled={this.state.payload.reason === ""}
            >
              {this.context.t("Report")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

CommentFlagModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    postCommentFlag: (payload) => dispatch(postCommentFlag(payload)),
  };
};

export default connect(null, mapDispatchToProps)(CommentFlagModal);
