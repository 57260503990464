// --------------------------------------
//              ACTION TYPES
// --------------------------------------
export const FILTERS_SORTERS_SET_FILTER = "FILTERS_SORTERS_SET_FILTER";
export const FILTERS_SORTERS_SET_FILTER_BY_STATIC_VALUE =
  "FILTERS_SORTERS_SET_FILTER_BY_STATIC_VALUE";
export const FILTERS_SORTERS_SET_SEARCH = "FILTERS_SORTERS_SET_SEARCH";
export const FILTERS_SORTERS_SET_SORT = "FILTERS_SORTERS_SET_SORT";
export const FILTERS_SORTERS_SET_SCROLL_TO_INDEX =
  "FILTERS_SORTERS_SET_SCROLL_TO_INDEX";

export const FILTERS_SORTERS_RESET_ALL = "FILTERS_SORTERS_RESET_ALL";

// --------------------------------------
//            ACTION CREATORS
// --------------------------------------
export const setFilter = (contentType, filterType, filter) => {
  return {
    type: FILTERS_SORTERS_SET_FILTER,
    contentType: contentType,
    filterType: filterType,
    filter: filter,
  };
};

export const setFilterByStaticValue = (contentType, filterType, filter) => {
  return {
    type: FILTERS_SORTERS_SET_FILTER_BY_STATIC_VALUE,
    contentType: contentType,
    filterType: filterType,
    filter: filter,
  };
};

export const setSort = (contentType, sort) => {
  return {
    type: FILTERS_SORTERS_SET_SORT,
    contentType: contentType,
    sort: sort,
  };
};

export const setSearch = (contentType, search) => {
  return {
    type: FILTERS_SORTERS_SET_SEARCH,
    contentType: contentType,
    search: search,
  };
};

export const setScrollToIndex = (contentType, scrollToIndex) => {
  return {
    type: FILTERS_SORTERS_SET_SCROLL_TO_INDEX,
    contentType: contentType,
    scrollToIndex: scrollToIndex,
  };
};

export const resetAll = () => {
  return {
    type: FILTERS_SORTERS_RESET_ALL,
  };
};
