import { updateObject } from "../Utility";
import {
  VERIFIED_RA_START,
  VERIFIED_RA_FAIL,
  VERIFIED_RA_SUCCESS,
} from "../actions/RecruitmentAgenciesActions";

const initialState = {
  next: null,
  previous: null,
  count: 0,
  page_size: 0,
  items: [],
  loading: false,
  fetched: false,
  error: null,
};

// pending RAS
export const getVerifiedRaSuccess = (state, action) => {
  return updateObject(state, {
    next: action.ras.next,
    previous: action.ras.previous,
    count: action.ras.count,
    page_size: action.ras.page_size,
    items: action.ras.results,
    loading: false,
    fetched: true,
    error: null,
  });
};

export const getVerifiedRaFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

export const getVerifiedRaStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const VerifiedRaReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFIED_RA_START:
      return getVerifiedRaStart(state, action);
    case VERIFIED_RA_SUCCESS:
      return getVerifiedRaSuccess(state, action);
    case VERIFIED_RA_FAIL:
      return getVerifiedRaFail(state, action);

    default:
      return state;
  }
};

export default VerifiedRaReducer;
